/**
 *  Search form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormControl, Validators,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class SearchForm extends BaseForm {
    constructor() {
        super();
        this.init();
    }

    /**
   * Initialize form Controls
   * @return {void}
   */
    protected init(): void {
        super.init();
        this.controls.searchFormControl = new FormControl('', [
            Validators.required,
            Validators.minLength(3),
        ]);
        this.controls.sportFormControl = new FormControl(0);
    }

    get searchFormControl(): AbstractControl | null {
        return this.controls.searchFormControl;
    }

    get sportFormControl(): AbstractControl | null {
        return this.controls.sportFormControl;
    }
}
