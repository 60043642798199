/**
 *  Service for Sports
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { CommonService } from '../shared/common.service';
import { SportInterface } from '../interfaces/sport.interface';
import URL_CONFIG from '../config/url.config';
import { TennisRankingInterface } from '../interfaces/tennis-rankings.interface';

import { TimeService } from './time.service';
import { LangService } from './lang.service';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class SportService {
    public onLoad = new EventEmitter<Record<string, SportInterface[]>>();
    public onLoadRaw = new EventEmitter<SportInterface[] | undefined>();

    public onBetStatsSwipe = new EventEmitter<boolean>();

    private dataVal: Record<string, SportInterface[]>;
    public dataValRaw: SportInterface[];

    public constructor(private http: HttpClient, private common: CommonService, private lang: LangService) {}

    /**
     * Get all actual sports
     * @param {string} act  - if set some period of time
     * @return Observable<SportInterface[]>
     */
    public getSports(act: string = 'today', update: boolean = false): Observable<SportInterface[] | undefined> {
        let params = null;
        if (!_.isUndefined(this.dataVal) && !_.isUndefined(this.dataVal[act]) && !update) {
            return of(this.dataVal[act]);
        }
        if (act === 'today') {
            params = new HttpParams()
                .set('from', TimeService.toUtc(TimeService.todayStartLocal()).format(TimeService.dateTimeFormat))
                .set('to', TimeService.toUtc(TimeService.todayEndLocal()).format(TimeService.dateTimeFormat));
        }

        const options = params !== null ? { params } : {};
        return this.http.get<SportInterface[]>(URL_CONFIG.api.getSports, options).pipe(
            tap((val): void => {
                this.dataVal = { ...this.dataVal, [act]: val };
                this.dataValRaw = val;
                this.onLoad.emit(this.dataVal);
                this.onLoadRaw.emit(val);
            }),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Get tennis rankings ATP/WTA
     * @param  {string} type  - atp or wta
     * @return  {Promise<Observable<TennisRankingInterface[]>>}
     */
    public getTennisRankings(type: string = 'atp'): Promise<Observable<TennisRankingInterface[]>> {
        return this.lang.getLang().then(
            (iso): Observable<TennisRankingInterface[]> => {
                const url = UtilsService.replace('type', type as string, URL_CONFIG.api.tennisRanking);
                const params = new HttpParams().set('lang', iso);
                const options = params !== null ? { params } : {};
                return this.http.get<TennisRankingInterface[]>(url, options).pipe(
                    catchError(this.common.errorCallback), // then handle the error
                );
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    public get data(): SportInterface[] | null {
        if (_.isUndefined(this.dataVal) || _.isUndefined(this.dataVal.today)) {
            return null;
        }

        return this.dataVal.today;
    }
}
