import { Component, Output, QueryList, ContentChildren, AfterViewInit, Input } from '@angular/core';

import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { DisplayModeInterface, MediaMode } from '@interfaces/mode.interface';

import { LangService } from '@services/lang.service';

import { AdsInterface, AdsPlatforms, AdsPositions, AdsType } from '@interfaces/ads.interface';

import { AdsService } from '@services/ads.service';


import { PageState } from '@/store/states/page.state';

@Component({
    selector: 'app-ads-container',
    templateUrl: './ads.component.html',
    providers: [AdsService],
})
export class AdsComponent implements AfterViewInit {
    @ContentChildren('ads') private contentChildren!: QueryList<AdsType>;

    @Select(PageState.getMedia) public mediaOb$: Observable<DisplayModeInterface>;

    @Input()
        position:AdsPositions;

    @Input()
        platforms:AdsPlatforms;

    @Output()
    public ads: AdsInterface[];


    /**
     * Should display banners in some case
     */
    private stopAds: boolean = false;

    public constructor(private adsService: AdsService, private lang: LangService) {}

    public ngAfterViewInit(): void {
        this.lang.onLangLoaded.subscribe(() => {
            this.adsService.load(true, this.lang.getLangSnapshot(), this.position, this.platforms).subscribe(() => {
                if (this.adsService.isLoaded) {
                    this.pickAds();
                } else {
                    this.adsService.onLoad.subscribe((): any => {
                        this.pickAds();
                    });
                }

                this.mediaOb$.subscribe((media): void => {
                    if (media && media.displayMode && media.displayMode === MediaMode.KIOSK) {
                        this.stopAds = true;
                        this.pickAds();
                    }
                });
            });
        });
    }

    public pickAds(): void {
        this.contentChildren.forEach((elm): void => {
            if (this.adsService.ads !== null) {
                const validate = Array.isArray(this.adsService.ads) && this.adsService.ads.length > 0;
                if (this.stopAds || !validate) {
                    elm.setAds(null);
                    return;
                }


                elm.setAds(this.adsService.ads);
            }
        });
    }
}
