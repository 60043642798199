<div
    class="dropdown-menu dropdown-menu--megamenu"
    [style.top.px]="topPx"
    aria-labelledby="navbar-dropdown-competitions"
>
    <div class="container px-0 bg-light navbar-dropdown-competitions-container" [class.pt-3]="!isMobile">
        <ul class="nav nav-tabs text-uppercase font-weight-bold d-none d-sm-flex" id="tabs-competitions" role="tablist">
            <li class="nav-item d-none d-lg-block">
                <a
                    class="nav-link active px-4 text-reset"
                    [class.active]="tab == 'all'"
                    id="competition-all-tab"
                    data-toggle="tab"
                    (click)="active('all')"
                    href="#competition-all"
                    role="tab"
                    aria-controls="competition-all"
                    aria-selected="true"
                    >{{ 'web.all' | translate }}</a
                >
            </li>
            <li class="nav-item d-none d-lg-block">
                <a
                    class="nav-link px-4 text-reset"
                    [class.active]="tab == 'top'"
                    id="competition-actual-tab"
                    data-toggle="tab"
                    (click)="active('top')"
                    href="#competition-favorite"
                    role="tab"
                    aria-controls="competition-actual"
                    aria-selected="false"
                    >{{ 'web.navi_favorite' | translate }}</a
                >
            </li>
            <li class="nav-item ml-auto ml-lg-3 mt-2 mr-2 d-none d-lg-block">
                <div class="form-group mb-0">
                    <div class="input-group">
                        <label for="competition-search" class="sr-only">{{ 'web.search' | translate }}</label>
                        <input
                            type="text"
                            class="form-control form-control-sm border-0"
                            id="competition-search"
                            [(ngModel)]="naviSearch"
                            autofocus="false"
                            placeholder="{{ 'web.search' | translate }}"
                            size="30"
                        />
                        <div class="input-group-append bg-white">
                            <button class="btn btn-sm" type="button" id="button-addon2">
                                <svg class="icon"><use href="#icon-search" /></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </li>
            <li
                *ngIf="(isStage() || sportName === 'golf') && years && !isMobile"
                class="nav-item ml-auto ml-lg-3 mt-2 mr-2"
            >
                <div class="form-group mb-0">
                    <div class="input-group">
                        <label class="mt-1 mr-2" for="competition-search">{{ 'web.year' | translate }}</label>
                        <select
                            class="form-control form-control-sm border-0"
                            id="stageYear"
                            autofocus
                            [(ngModel)]="year"
                        >
                            <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                        </select>
                    </div>
                </div>
            </li>
        </ul>
        <div class="tab-content">
            <div
                class="tab-pane active"
                [class.active]="tab == 'all'"
                id="competition-all"
                role="tabpanel"
                aria-labelledby="competition-all-tab"
            >
                <ng-container *ngIf="isMobile">
                    <div
                        *ngIf="showFavorite && !isStage()"
                        class="d-flex mobile-event-nav-header mobile-event-nav-header--favorite justify-content-between"
                    >
                        <a
                            class="text-reset w-100 text-decoration-none"
                            href="#nav-mob-favorite-parent"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseExample"
                        >
                            {{ 'web.navi_favorite' | translate }}
                        </a>
                    </div>
                    <div
                        *ngIf="isMobile && isStage()"
                        class="d-flex mobile-event-nav-header mobile-event-nav-header--favorite justify-content-between"
                    >
                        <a class="text-reset w-100 text-decoration-none">
                            {{ 'web.year' | translate }}
                        </a>
                        <div class="mt-n1 mb-n1" style="width: 100px">
                            <select
                                class="form-control form-control-sm border-0"
                                id="stageYear"
                                autofocus
                                [(ngModel)]="year"
                            >
                                <option *ngFor="let y of years" [value]="y">{{ y }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="collapse" [class.show]="showFavorite" id="nav-mob-favorite-parent">
                        <app-navigation
                            [type]="'top'"
                            [sportId]="sportId"
                            view="eventNav"
                            [alphaFilter]="alphaFilter"
                            [level]="2"
                            [sportName]="sportName"
                            [fulltext]="naviSearch"
                            (onload)="onFavoriteLoaded($event)"
                        ></app-navigation>
                    </div>
                    <div class="d-flex mobile-event-nav-header">{{ 'web.nav_categories' | translate }}</div>
                </ng-container>

                <app-navigation
                    [type]="'all'"
                    [sportId]="sportId"
                    [alphaFilter]="alphaFilter"
                    [level]="1"
                    [sportName]="sportName"
                    [fulltext]="naviSearch"
                    [year]="year"
                ></app-navigation>

                <app-alpha-filter (onDataChange)="alphaFilter = $event" [showStar]="false"></app-alpha-filter>
            </div>
            <div
                class="tab-pane"
                [class.active]="tab == 'top'"
                id="competition-favorite"
                role="tabpanel"
                *ngIf="tab == 'top'"
                aria-labelledby="competition-all-tab"
            >
                <app-navigation
                    [type]="'top'"
                    [sportId]="sportId"
                    [alphaFilter]="alphaFilter"
                    [level]="1"
                    [sportName]="sportName"
                    [fulltext]="naviSearch"
                ></app-navigation>

                <app-alpha-filter (onDataChange)="alphaFilter = $event" [showStar]="false"></app-alpha-filter>
            </div>
        </div>
    </div>
</div>
