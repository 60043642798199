/**
 *  Service for match detail
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { CommonService } from '../shared/common.service';
import { LangService } from '../services/lang.service';
import { MatchData } from '../interfaces/match-data.interface';
import URL_CONFIG from '../config/url.config';
import SPORT_CONFIG from '../config/sport.config';
import { Score } from '../interfaces/score.interface';

import { environment } from '../../environments/environment';

import { UtilsService } from './utils.service';
import { SoundService } from './sound.service';

@Injectable({
    providedIn: 'root',
})
export class MatchService {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _sportName: string;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _state: string;

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
        private sound: SoundService,
    ) {}

    /**
     * Handle state change actions
     * @param {string} currentState
     * @param {string} previousState
     * @param {object} options
     * @param {boolean} options.makeNoise =  if true animation and sound will be triggered
     */
    public handleStateChange(currentState: string, previousState: string, { makeNoise }: { makeNoise: boolean }): void {
        if (makeNoise && !this.sound.isSoundOff()) {
            this.setSound(currentState, previousState);
        }
    }

    /**
     * Handle score change actions
     * @param {Score} currentScore
     * @param {Score} previousScore
     * @param {object} options
     * @param {boolean} options.makeNoise =  if true animation and sound will be triggered
     */
    public handleScoreChange(currentScore: Score, previousScore: Score, { makeNoise }: { makeNoise: boolean }): void {
        if (makeNoise && !this.sound.isSoundOff()) {
            this.scoreSound(currentScore, previousScore);
        }
    }

    /**
     * Play sounds if score change
     * @param {Score} currentScore
     * @param {Score} previousScore
     */
    private scoreSound(currentScore: Score, previousScore: Score): void {
        if (_.indexOf(SPORT_CONFIG.sounds.score, this._sportName) === -1 || _.isUndefined(currentScore.home_team)) {
            return;
        }
        if (currentScore.home_team !== previousScore.home_team || currentScore.away_team !== previousScore.away_team) {
            this.sound.score();
        }
    }

    /**
     * Play sounds if state change
     * @param {string} currentState
     * @param {string} previousState
     */
    private setSound(currentState: string, previousState: string): void {
        if (
            _.indexOf(SPORT_CONFIG.sounds.set, this._sportName) === -1 ||
            this.specialSetBlockRule(currentState, previousState)
        ) {
            return;
        }

        if (currentState !== previousState && currentState === SPORT_CONFIG.states.ended) {
            this.sound.matchEnd();
        } else if (currentState !== previousState) {
            this.sound.set();
        }
    }

    /**
     * Special rules for blocking soubds
     * @param {string} currentState
     * @param {string} previousState
     */
    private specialSetBlockRule(currentState: string, previousState: string): boolean {
        if (previousState === SPORT_CONFIG.states.notStarted) {
            return true;
        }
        if (previousState === SPORT_CONFIG.states.warmUp) {
            return true;
        }

        return false;
    }

    public set sportName(name) {
        this._sportName = name;
    }

    public get sportName(): string {
        return this._sportName;
    }

    public set state(name) {
        this._state = name;
    }

    public get state(): string {
        return this._state;
    }

    /**
     * Get Match list categories
     * @return {Promise<Observable<MatchData>>}
     */
    public getMatch(id: any, full: boolean = true,
        widgetUrl: boolean = false, h2hLimit = 5, backupUrl: boolean = true): Promise<Observable<MatchData>> {
        if (id === null) {
            return Promise.reject();
        }

        const iso : string = this.lang.getLangSnapshot()!;

        let url = UtilsService.replace('id', id as string, URL_CONFIG.api.getMatch);

        if (!backupUrl && environment.matchDetailCache) {
            url = UtilsService.replace('id', id as string, URL_CONFIG.api.getMatchCache);
        } else if (widgetUrl) {
            url = UtilsService.replace('id', id as string, URL_CONFIG.api.getMatchWidget);
        }

        return new Promise(
            (resolve): void => {
                const params = new HttpParams().set('lang', iso)
                    .set('short', String(full ? 0 : 1)).set('h2hlimit', String(h2hLimit));
                const options = params ? { params } : {};
                resolve(this.http.get<MatchData[]>(url, options).pipe(
                    distinctUntilChanged(),
                    switchMap(this.map),
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline())),
                );
            },

        );
    }

    /**
     * Get Match list categories
     * @return {Observable<MatchData>}
     */
    public getMatchNew(id: any, iso: string, full: boolean = true): Observable<MatchData> {
        const url = UtilsService.replace('id', id as string, URL_CONFIG.api.getMatch);

        const params = new HttpParams().set('lang', iso).set('short', String(full ? 0 : 1));
        const options = params ? { params } : {};
        return this.http.get<MatchData[]>(url, options).pipe(
            distinctUntilChanged(),
            switchMap(this.map),
            catchError(this.common.errorCallback2()), // then handle the error
            tap(this.common.networkOnline()),
        );
    }

    private map(data: any): Observable<MatchData> {
        return of(data);
    }
}
