<div [class.col-right__top]="!mobile" [class.pt-3]="!mobile" class="row small text-center">
    <div *ngIf="!mobile" class="col-4">
        <div [class.active]="stickyVal.checked" class="custom-control custom-switch my-2" data-cy="switch2">
            <input
                type="checkbox"
                class="custom-control-input"
                id="switch2"
                [checked]="sticky"
                (change)="stickyEvent(stickyVal.checked)"
                #stickyVal
            />
            <label class="custom-control-label app-word-nowrap" for="switch2">{{
                'web.sticky_match_detail' | translate | transinit
            }}</label>
        </div>
    </div>

    <div *ngIf="!mobile" class="col-4">
        <div [class.mt-3]="mobile" [class.my-2]="!mobile" class="custom-control custom-switch" data-cy="switch">
            <app-sound-switch></app-sound-switch>
        </div>
    </div>
    <ng-container *ngIf="mobile">
        <a *ngIf="isWakeLock" class="col">
            <div>
                <div [class.active]="stickyVal4.checked" class="custom-control custom-switch my-2">
                    <input
                        #stickyVal4
                        (change)="onWakeLockChange(stickyVal4.checked)"
                        [checked]="wakeLockMode"
                        class="custom-control-input"
                        id="switch8"
                        type="checkbox"
                    />
                    <label class="custom-control-label text-less-emph2 app-word-nowrap" data-cy="switch8" for="switch8">
                        <i class="fa fa-unlock"></i>
                        &nbsp;{{ 'web.sticky_wake_lock' | translate | transinit }}</label
                    >
                </div>
            </div>
        </a>

        <a class="col">
            <div *ngIf="client.use('pageDarkMode')">
                <div [class.active]="stickyVal3.checked" class="custom-control custom-switch my-2">
                    <input
                        #stickyVal3
                        (change)="onModeChange(stickyVal3.checked)"
                        [checked]="stickyPageMode"
                        class="custom-control-input"
                        id="switch4"
                        type="checkbox"
                    />
                    <label class="custom-control-label text-less-emph2 app-word-nowrap" data-cy="switch4" for="switch4">
                        <i class="fas fa-moon"></i>
                        {{ 'web.sticky_dark_mode' | translate | transinit }}</label
                    >
                </div>
            </div>
        </a>

        <a *ngIf="shouldOpen || (options.showOpenAll && envDev)" class="col">
            <div [class.active]="stickyVal2.checked">
                <div class="custom-control custom-switch my-2">
                    <input
                        #stickyVal2
                        (change)="onOpenEvent(stickyVal2.checked)"
                        [checked]="false"
                        class="custom-control-input"
                        id="switch3"
                        type="checkbox"
                    />
                    <label class="custom-control-label text-less-emph2 app-word-nowrap" for="switch3">{{
                        'web.sticky_open_all' | translate
                    }}</label>
                </div>
            </div>
        </a>

        <a class="col">
            <div [class.active]="matchNotification">
                <div class="custom-control custom-switch my-2">
                    <input
                        (ngModelChange)="onMatchNotificationChange($event)"
                        [ngModel]="matchNotification"
                        class="custom-control-input"
                        id="switch6"
                        type="checkbox"
                    />
                    <label class="custom-control-label text-less-emph2" for="switch6">{{
                        'web.sticky_match_notification' | translate | transinit
                    }}</label>
                </div>
            </div>
        </a>
    </ng-container>
    <div *ngIf="!mobile" class="col-4 text-right">
        <div class="btn-group dropleft">
            <button
                aria-expanded="false"
                class="btn btn-secondary btn-sm dropdown-toggle dropdown-toggle-switcher d-none d-md-block"
                aria-label="settings"
                data-toggle="dropdown"
                data-cy="settings-switch"
                type="button"
            >
                <svg class="icon icon--white-per ml-1"><use href="#icon-settings"></use></svg>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item spec-link" *ngIf="true || isWakeLock">
                    <div class="col-4">
                        <div [class.active]="stickyVal4.checked" class="custom-control custom-switch my-2">
                            <input
                                #stickyVal4
                                (change)="onWakeLockChange(stickyVal4.checked)"
                                [checked]="wakeLockMode"
                                class="custom-control-input"
                                id="switch5"
                                type="checkbox"
                            />
                            <label
                                class="custom-control-label app-word-nowrap app-cursor"
                                data-cy="switch5"
                                for="switch5"
                            >
                                <i class="fa fa-unlock"></i>
                                &nbsp;{{ 'web.sticky_wake_lock' | translate | transinit }}</label
                            >
                        </div>
                    </div>
                </a>
                <a class="dropdown-item spec-link" *ngIf="shouldOpen || (options.showOpenAll && envDev)">
                    <div [class.active]="stickyVal2.checked" class="col-4">
                        <div class="custom-control custom-switch my-2">
                            <input
                                #stickyVal2
                                (change)="onOpenEvent(stickyVal2.checked)"
                                [checked]="false"
                                class="custom-control-input"
                                id="switch3"
                                type="checkbox"
                            />
                            <label class="custom-control-label app-word-nowrap app-cursor" for="switch3">{{
                                'web.sticky_open_all' | translate
                            }}</label>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item spec-link" *ngIf="client.use('pageDarkMode')">
                    <div class="col-4">
                        <div [class.active]="stickyVal3.checked" class="custom-control custom-switch my-2">
                            <input
                                #stickyVal3
                                (change)="onModeChange(stickyVal3.checked)"
                                [checked]="stickyPageMode"
                                class="custom-control-input"
                                id="switch4"
                                type="checkbox"
                            />
                            <label
                                class="custom-control-label app-word-nowrap app-cursor"
                                data-cy="switch4"
                                for="switch4"
                            >
                                <i class="fas fa-moon"></i>
                                {{ 'web.sticky_dark_mode' | translate | transinit }}</label
                            >
                        </div>
                    </div>
                </a>
                <a class="dropdown-item spec-link">
                    <div class="col-4">
                        <div [class.active]="matchNotification" class="custom-control custom-switch my-2">
                            <input
                                (ngModelChange)="onMatchNotificationChange($event)"
                                [ngModel]="matchNotification"
                                class="custom-control-input"
                                id="switch6"
                                type="checkbox"
                            />
                            <label
                                class="custom-control-label app-word-nowrapapp-cursor"
                                data-cy="switch6"
                                for="switch6"
                            >
                                <i class="fas fa-comment-dots"></i>
                                {{ 'web.sticky_match_notification' | translate | transinit }}</label
                            >
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
