import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Directive } from '@angular/core';
import { Store } from '@ngxs/store';
import { AddMissingTrans } from 'src/app/store/actions/translation.action';
import { TranslationService } from 'src/app/services/translation.service';

import { environment } from '../../../environments/environment';

@Directive()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
    public constructor(private store: Store) {}

    public handle(params: MissingTranslationHandlerParams): string {
        if (TranslationService.isLoaded) {
            this.store.dispatch(new AddMissingTrans(params.key, window.location.pathname));
        }
        if (environment.production) {
            return `${params.key}`;
        }

        return `[[${params.key}]]`;
    }
}
