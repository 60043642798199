import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { from, merge, of, Subscription } from 'rxjs';
import { delay, filter, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { LangService } from '@services/lang.service';
import { LangInterface } from '@interfaces/lang.interface';
import { MetaService } from '@services/meta.service';
import { ClientService } from '@services/client.service';

import APP_CONFIG from '../../config/app.config';

import { InfoService } from '@/services/info.service';

// eslint-disable-next-line semi, no-unused-labels
declare const location: any;

@Component({
    selector: 'app-lang',
    templateUrl: './lang.component.html',
})
export class LangComponent implements OnInit, OnDestroy {
    @Input()
    public only: string[];

    @Input()
    public flags: boolean = true;

    @Input('mobile')
    public isMobile: boolean = false;

    public langObj: LangInterface[];

    public loaded: boolean = false;

    /**
     * Active iso code
     * @param lang [description]
     */
    public activeIso: string;
    public activeImg: string;

    private subscriber: Subscription;

    public constructor(
        private lang: LangService,
        private trans: TranslateService,
        private metaService: MetaService,
        private router: Router,
        private route: ActivatedRoute,
        public client: ClientService,
        private info: InfoService,
    ) {
        const userLang: string = window.navigator.language.substring(0, 2).toLowerCase();

        this.trans.setDefaultLang(this.lang.isStored() === null ? userLang : this.lang.getLangSnapshot() as string);
    }

    get pathname(): string {
        return location.pathname;
    }

    public ngOnInit(): void {
        const isStored2 = this.lang.isStored();


        this.lang.getLangs(false).pipe(delay(100)).subscribe(
            (langs): void => {
                this.langObj = langs;
                this.info.onLoad2.subscribe(() => {
                    if (this.client.hasOneLang()) {
                        const findLang = this.langObj.find(
                            (val4): boolean => val4.iso === this.client.hasOneLang()) as LangInterface;
                        const { image = APP_CONFIG.defaultLangImg } = findLang ?? {};
                        this.activeLang(this.client.hasOneLang() as string, image);
                    } else if (this.client.hasDefaultLang() && !isStored2) {
                        const findLang = this.langObj.find(
                            (val4): boolean => val4.iso === this.client.hasDefaultLang()) as LangInterface;
                        const { image = APP_CONFIG.defaultLangImg } = findLang ?? {};
                        this.activeLang(this.client.hasDefaultLang() as string, image);
                    }
                });
            });


        const savedLang: string | null = this.lang.getLangSnapshot();


        let userLang: string = window.navigator.language.substring(0, 2).toLowerCase();
        let isStored = this.lang.isStored();
        if (this.route.snapshot.queryParamMap.get('lang')) {
            userLang = this.route.snapshot.queryParamMap.get('lang') || userLang;
        }
        // eslint-disable-next-line operator-linebreak
        const qP =
            this.route.snapshot.queryParamMap.get('lang') === null
                ? this.lang.getLangSnapshot()
                : this.route.snapshot.queryParamMap.get('lang');


        const isNotInOnly = this.only && this.only.length > 0 && !this.only.includes(qP as string);

        this.subscriber = this.lang.getLangs(false).subscribe(
            (langs): void => {
                this.langObj = langs;

                this.loaded = true;

                isStored = this.lang.isStored();


                from(this.langObj)
                    .pipe(first((val): boolean => savedLang === val.iso, null))
                    .subscribe((val): void => {
                        if (qP !== null && isNotInOnly) {
                            this.activeLang(APP_CONFIG.defaultLang, APP_CONFIG.defaultLangImg, true);
                        } else if (qP !== null && isStored) {
                            const findLang = langs.find((val4): boolean => val4.iso === qP) as LangInterface;
                            const { image = APP_CONFIG.defaultLangImg } = findLang ?? {};

                            this.activeLang(_.isUndefined(findLang) ? APP_CONFIG.defaultLang : qP, image);
                            this.trans.setDefaultLang(_.isUndefined(findLang) ? APP_CONFIG.defaultLang : qP);
                        } else if (val === null) {
                            const findUserLang = _.findWhere(this.langObj, {
                                iso: userLang,
                            });
                            const { image = APP_CONFIG.defaultLangImg } = langs.find(
                                (val4): boolean => val4.iso === userLang,
                            ) as LangInterface;
                            this.activeLang(_.isUndefined(findUserLang) ? APP_CONFIG.defaultLang : userLang, image);
                        } else {
                            const findUserLang = _.findWhere(this.langObj, {
                                iso: userLang,
                            });

                            const findLang = langs.find(
                                (val4): boolean => val4.iso === userLang,
                            ) as LangInterface;
                            const { image = APP_CONFIG.defaultLangImg } = findLang ?? {};
                            this.activeLang(_.isUndefined(findUserLang) ? val.iso : userLang, image);
                        }
                    });


                // register route event for url change
                merge(of(true), this.router.events.pipe(filter((event): boolean => event instanceof ActivationEnd)))
                    .subscribe((): void => {
                        this.metaService.createAlternateURL(langs);
                    });
                // first generating url
                // this.metaService.createAlternateURL(langs);
            },
            (): void => this.activeLang(APP_CONFIG.defaultLang, APP_CONFIG.defaultLangImg),
            (): void => {
                this.loaded = true;
            },
        );
    }

    public filter(obj: LangInterface[]): LangInterface[] {
        if (_.isUndefined(obj)) {
            return [];
        }

        if (!this.only || this.only.length === 0) {
            return obj;
        }
        return obj.filter((val): boolean => this.only.includes(val.iso));
    }

    public ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }

    /**
     * Active lang
     * @param  {string} iso
     * @return  {void}
     */
    public activeLang(iso: string, img: string = '', reload: boolean = false): void {
        this.activeIso = iso;
        this.activeImg = img;

        this.lang.setLang(iso, this.trans, img).then((): void => {
            $('html').attr('lang', iso);

            this.lang.onLangLoaded.next(true);
            if (reload) {
                document.location.reload();
            }
        });
    }
}
