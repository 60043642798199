import { ChangeDetectorRef, Component } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { banner } from '@services/decorators/class.decorator';

import { WindowRefService } from '@shared/window-ref';

import { BannerBase } from '../banner.base';

@banner({
    position: 9,
})
@Component({
    selector: 'app-banner[desktop-header]',
    templateUrl: './desktop-header.component.html',
})
export class BannerDesktopHeaderComponent extends BannerBase {
    public bannerPositionId = 9;
    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super(deviceService, ref, win);
    }
}
