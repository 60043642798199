/**
 *  Settings for branch
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { CanOpen } from '../actions/settings.action';

interface SettingsModel {
    navOpen: boolean;
}
@Injectable()
@State<SettingsModel>({
    name: 'setttings',
    defaults: {
        navOpen: false,
    },
})
export class BranchSettingState {
    /**
     * Get nav state
     * @return {boolean}
     */
    @Selector()
    public static nav(state: SettingsModel): boolean {
        return state.navOpen;
    }

    /**
     * Set/update user ddata
     */
    @Action(CanOpen)
    public toggleNav(
        ctx: StateContext<SettingsModel>,
        { open }: CanOpen,
    ): void {
        ctx.patchState({
            navOpen: open,
        });
    }
}
