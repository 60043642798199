import { Component, OnInit } from '@angular/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { InfoService } from 'src/app/services/info.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ClientService } from '@/services/client.service';

import { PageService } from '../../services/page.service';
import { BaseComponent } from '../base.component';

type Test<T> = T & { val: Test<T> };

@Component({
    selector: 'app-error404',
    templateUrl: './error404.component.html',
    styles: [],
})
export class Error404Component extends PageMixin(BaseComponent) implements OnInit {
    public constructor(
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected info: InfoService,
        public client: ClientService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
