/**
 *  Service for my matches
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CommonService } from '../shared/common.service';

import { MyMatchesInterface } from '../interfaces/my-matches.interface';

import URL_CONFIG from '../config/url.config';

import { BaseService } from './base.service';
import { LangService } from './lang.service';

@Injectable({
    providedIn: 'root',
})
export class MyMatchesService extends BaseService {
    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
    ) {
        super();
    }

    /**
     * Get source ids to match ids
     */
    public sourceIdToId(ids: string, source: string): Observable<number[]> {
        const url = URL_CONFIG.api.getMyMatchesSource;
        const options = {};
        return this.http.get<number[]>(`${url}?id=${ids}&source=${source}`, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Get Betradar ids to match ids
     */
    public brToId(brid: string): Observable<number[]> {
        const url = URL_CONFIG.api.getMyMatchesBr;
        const options = {};
        return this.http.get<number[]>(`${url}?brid=${brid}`, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Get My Matches from API
     * @param {number[]} ids
     * @return {Promise<Observable<ParticipantInterface>>}
     */
    public async get(ids: any[]): Promise<Observable<MyMatchesInterface>> {
        const url = URL_CONFIG.api.getMyMatches;
        return this.lang.getLang().then(
            (iso): Observable<MyMatchesInterface> => {
                const params = new HttpParams()
                    .set('lang', iso)
                    .set('ids', ids.join(','));
                const options = params ? { params } : {};
                return this.http.get<MyMatchesInterface>(url, options).pipe(
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap(this.common.networkOnline()),
                );
            },
            (): any => Promise.reject(new Error('My Matches error')),
        );
    }
}
