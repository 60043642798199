import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject, Directive } from '@angular/core';

import SPORT_CONFIG from '@config/sport.config';

import { PageService } from '../services/page.service';
import { InfoService } from '../services/info.service';

@Directive()
export class BaseComponent {
    @Inject(DOCUMENT) private document: Document;

    public tabVisible: boolean = true;

    protected lastTabVisible: boolean = true;

    public loaded: boolean = false;

    public loadedError: boolean = false;

    public stickyDetail: boolean;

    protected tabSubject: Subject<string>;

    public cdn: string = environment.cdn;

    public constructor(
        protected page: PageService,
        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
    ) {}

    public ngOnInit(): void {
        this.tabSubject = new Subject();
        this.checkTabVisibility();

        setTimeout((): void => {
            this.page.pageLoaded();
        }, 100); // page is loaded
    }

    public get viewPortHeight(): string {
        return `${this.page.window.innerHeight}px`;
    }

    public get viewPortHeightMatchDetail(): string {
        if (this.stickyDetail === false) {
            return 'inherit';
        }
        return '100vh';
    }

    public onStickyChange(event: any): void {
        this.stickyDetail = event;
        this.page.setSticky(event);
    }

    /**
     * Set page title and og params
     * @param {object}  head
     * @param  {string} head.title
     * @param  {string} head.description
     * @param {boolean}  ogCopy - if true title an desc from head will be copy to og params
     * @param {object}  og
     * @param  {string} og.ogTitle
     * @param  {string} og.ogImage
     * @param  {string} og.ogDescription
     * @return {void}
     */
    protected setTitle({ title, description }: Record<string, string>, ogCopy = false): void {
        const { host, protocol } = this.page.window.location;

        this.title!.setTitle(title!);

        if (!_.isUndefined(description)) {
            this.meta!.updateTag({ name: 'description', content: description });
        }


        this.meta!.updateTag({
            property: 'og:url',
            content: `${protocol}//${host}${this.route!.url}`,
        });


        this.info!.isLoaded().then((): void => {
            this.meta!.updateTag({
                property: 'og:image',
                content: `${protocol}//${host}${this.info!.socialImage}`,
            });
            this.meta!.updateTag({
                property: 'og:image:url',
                content: `${protocol}//${host}${this.info!.socialImage}`,
            });
            this.meta!.updateTag({
                property: 'og:image:secure_url',
                content: `${protocol}//${host}${this.info!.socialImage}`,
            });
            this.meta!.updateTag({
                property: 'og:image:type',
                content: 'image/png',
            });
            this.meta!.updateTag({
                property: 'og:image:width',
                content: '600',
            });
            this.meta!.updateTag({
                property: 'og:image:height',
                content: '600',
            });
            this.meta!.updateTag({
                name: 'twitter:image',
                content: `${protocol}//${host}${this.info!.socialImage}`,
            });
        });

        if (ogCopy) {
            this.meta!.updateTag({ property: 'og:title', content: title! });
            this.meta!.updateTag({
                name: 'twitter:card',
                content: 'summary',
            });
            this.meta!.updateTag({
                name: 'twitter:title',
                content: title!,
            });
            if (!_.isUndefined(description)) {
                this.meta!.updateTag({
                    property: 'og:description',
                    content: description,
                });
                this.meta!.updateTag({
                    name: 'twitter:description',
                    content: description,
                });
            }
        }
        this.meta!.updateTag({
            name: 'twitter:site',
            content: `${protocol}//${host}${this.route!.url}`,
        });
    }

    /**
     * Check if browser tab is visible or not
     * @return [description]
     */
    public checkTabVisibility(): void {
        let visibilityChange = null;
        let hidden: string;
        if (typeof document.hidden !== 'undefined') {
            // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        } else if (_.has(document, 'msHidden')) {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        } else if (_.has(document, 'webkitHidden')) {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }
        this.page.checkWakeLock();
        if (visibilityChange !== null) {
            document.addEventListener(
                visibilityChange,
                (): void => {
                    // @ts-ignore
                    if (document[hidden]) {
                        this.tabVisible = false;
                        this.tabSubject.next('hidden');
                    } else {
                        this.page.checkWakeLock();
                        this.tabVisible = true;
                        this.tabSubject.next('visible');
                    }
                },
                false,
            );
        }

        this.tabSubject.next('init');
    }


    public trackById(index: number, item: Record<string, any>): number {
        return item.id;
    }

    public trackByKey(index: number, item: Record<string, any>): any {
        return item.key;
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Start load
     */
    protected start(): void {
        document.body.style.cursor = 'progress';
    }

    /**
     * Error load data
     */
    protected error(updateCursor: boolean = false): void {
        this.loaded = true;
        this.loadedError = true;
        if (updateCursor) {
            document.body.style.cursor = '';
        }
    }

    /**
     * Successload data
     */
    protected finished(updateCursor: boolean = false): void {
        this.loaded = true;
        this.loadedError = false;
        if (updateCursor) {
            document.body.style.cursor = '';
        }
    }

    /**
     * Reset load data
     */
    protected reset(): void {
        this.loaded = false;
        this.loadedError = false;
    }

    public isSoccer(sportName: string): boolean {
        return SPORT_CONFIG.isSoccer(sportName);
    }

    public isHockey(sportName: string): boolean {
        return SPORT_CONFIG.isHockey(sportName);
    }
}
