/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SignInForm } from 'src/app/shared/forms/sign-in.form';
import { UserService } from 'src/app/services/user.service';
import { SignInterface } from 'src/app/interfaces/sign-in.interface';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';

import { CountryService } from '../../../services/country.service';
import { CountryInterface } from '../../../interfaces/country.interface';

import { WindowRefService } from '@/shared/window-ref';
import { SnackBarService } from '@/services/snack-bar.service';

// declare const dataLayer: any;
@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
})
export class SigninComponent implements OnInit {
    public submitText: string = 'signin';

    @Output()
    private onChange: EventEmitter<string> = new EventEmitter();

    @Input()
    private redirectUrl: string;

    @Input()
    public mobile: boolean = false;

    @Input()
    public idName: string = '';

    @Input()
    public forgotenPassword: boolean = true;

    /**
     * True in case of success signIn
     */
    public signInSuccess: boolean = false;

    public countries: CountryInterface[] | null;

    public constructor(
        public signInForm: SignInForm,
        public user: UserService,
        private snackbar: SnackBarService,
        private translate: TranslateService,
        private countryService: CountryService,
        private router: Router,
        private win: WindowRefService,
    ) {}

    public ngOnInit(): void {
        if (this.countryService.isLoaded) {
            this.countries = this.countryService.countries;
        } else {
            this.countryService.load();
            this.countryService.onLoad.subscribe((): any => {
                this.countries = this.countryService.countries;
            });
        }
    }

    /**
     * SignIn user
     * @return {void}
     */
    public signIn(): void {
        this.submitText = 'form_loading_text';
        this.signInForm.getGroup().disable();
        this.signInSuccess = false;
        this.user
            .signIn<SignInterface>(this.signInForm.getGroup().value)
            .subscribe(
                (): void => {
                    this.user.getStatus().then((): void => {
                        this.signInSuccess = true;


                        this.win.nativeWindow.dataLayer.push({
                            event: 'login',
                            email: this.signInForm?.email?.value,
                            type: 'manual',
                        });


                        this.translate
                            .get('web.sign_in_success')
                            .subscribe((): void => {
                                this.openSnackBar(
                                    this.translate.instant(
                                        'web.sign_in_success',
                                    ),
                                    'info',
                                );
                                setTimeout(() => { this.router.navigateByUrl('/page/account/dashboard'); }, 500);
                            });

                        this.onChange.emit('close');
                        this.resetForm();
                    });
                },
                (er): void => {
                    this.signInForm.getGroup().enable();
                    this.submitText = 'signin';
                    this.signInForm.setErrors(er);
                },
            );
    }

    private openSnackBar(message: string, action: 'info' | 'alert'): void {
        this.snackbar.openSnackBar(message, 'top', action);
    }

    /**
     * Reset forn
     */
    public resetForm(): void {
        this.signInForm.getGroup().enable();
        this.signInForm.getGroup().reset();
        this.submitText = 'signin';
        this.signInSuccess = false;
    }

    /**
     * Go to registration
     * @return {void}
     */
    public goToRegistration(): void {
        this.onChange.emit('registration');
    }

    public change(type: string): void {
        if (type === 'close') {
            this.translate.get('web.sign_in_success').subscribe((): void => {
                this.openSnackBar(
                    this.translate.instant('web.sign_in_success'),
                    'info',
                );
            });
            setTimeout(() => { this.router.navigateByUrl('/page/account/dashboard'); }, 1500);

            this.onChange.emit('close');
        }
    }


    public toggleUsePhoneNumber(): void {
        this.signInForm.email!.clearValidators();
        this.signInForm.phoneNumber!.clearValidators();
        this.signInForm.phoneNumberCode!.clearValidators();

        this.signInForm.usePhoneNumber!.patchValue(!this.signInForm.usePhoneNumber!.value);

        if (this.signInForm.usePhoneNumber!.value) {
            this.signInForm.phoneNumberCode!.setValidators([
                Validators.required,
            ]);
            this.signInForm.phoneNumber!.setValidators([
                Validators.required,
            ]);
        } else {
            this.signInForm.email!.setValidators([
                Validators.required,
                Validators.email,
            ]);
        }

        this.signInForm.email!.updateValueAndValidity();
        this.signInForm.phoneNumberCode!.updateValueAndValidity();
        this.signInForm.phoneNumber!.updateValueAndValidity();
    }
}
