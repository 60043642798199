/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  User object class
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import APP_CONFIG from '../config/app.config';
import { UserInterface } from '../interfaces/user.interface';

import { StorageService } from './storage.service';

export class UserStore {
    private storage: StorageService;

    private _isLoggedIn: boolean = false;

    private _profile: UserInterface | null= null;

    public constructor() {
        this.storage = new StorageService();
        this.init();
    }

    public setProfile(profile: UserInterface): void {
        this.storage.set(APP_CONFIG.userStorageKey, profile);
        this._profile = profile;
    }

    public signIn(): void {
        if (this._profile !== null && this._profile.demo) {
            delete this._profile.demo;
        }
        this._isLoggedIn = true;
    }

    public signOff(): void {
        this.storage.remove(APP_CONFIG.userStorageKey);
        this._isLoggedIn = false;
    }

    /**
     * Init object
     * @return [description]
     */
    private init(): void {
        const user = this.storage.get<UserInterface>(APP_CONFIG.userStorageKey);

        this._isLoggedIn =
            this.storage.isset(APP_CONFIG.userStorageKey) && user !== null && !user.demo;

        if (this.storage.isset(APP_CONFIG.userStorageKey)) {
            this._profile = user;
        }
    }

    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    public get profile(): UserInterface | null{
        return this._profile;
    }
}
