/**
 *  Forgotten password form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class ForgottenPasswordForm extends BaseForm {
    constructor() {
        super();
        this.init();
    }

    /**
   * Initialize form Controls
   * @return {void}
   */
    protected init(): void {
        super.init();
        this.formGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            recaptcha: new FormControl('', Validators.required),
        });
    }

    get email(): AbstractControl | null {
        return this.formGroup.get('email');
    }

    get recaptcha(): AbstractControl | null {
        return this.formGroup.get('recaptcha');
    }
}
