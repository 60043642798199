import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { UserService } from '@services/user.service';
import FAVORITE_CONFIG from '@config/favorite.config';
import { FavoriteService } from '@services/favorite.service';
import { MatchListService } from '@services/match-list.service';
import { WindowRefService } from '@shared/window-ref';

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html',
})
export class FavoriteComponent implements OnInit {
    @Input()
    public type: string;

    @Input()
    public sportId: number;

    @Input()
    public tournamentId: number;

    @Input()
    public matchId: number;

    public xclassDeff: string = 'icon  icon--14 icon--primary  mx-2 ';

    public xclassMatchDeff: string = 'icon  icon--14 icon-match';

    public xclassMatchDetailDeff: string = 'icon  icon--16 icon-match';

    @Input()
    public set classDef(val: any) {
        this.xclassDeff = val;
    }

    public classDefVal(): any {
        return this.xclassDeff;
    }

    @Input()
    public set classMatchDef(val: any) {
        this.xclassMatchDeff = val;
    }

    public classMatchDefVal(): any {
        return this.xclassMatchDeff;
    }

    public classMatchDetailDefVal(): any {
        return this.xclassMatchDetailDeff;
    }

    @Input()
    public participantId: number;

    /**
     * Is component properly setup
     * @var {boolean}
     */
    public isSet: boolean = false;

    public constructor(
        private favorite: FavoriteService,
        private user: UserService,
        private windowRefService: WindowRefService,
    ) {}

    public ngOnInit(): void {
        if (
            this.sportId &&
            _.indexOf(FAVORITE_CONFIG.availTypes, this.type) !== -1
        ) {
            this.isSet = true;
        } else if (_.indexOf(FAVORITE_CONFIG.availTypes, this.type) !== -1) {
            this.isSet = true;
        }
    }

    /**
     * Toggle favorite
     * @return [description]
     */
    public toggle(event: MouseEvent): void {
        switch (this.type) {
            case FAVORITE_CONFIG.availTypes[0]:
                this.favorite.toggleTournamnetFavorite(this.sportId, this.tournamentId);
                break;
            case FAVORITE_CONFIG.availTypes[4]:
                this.favorite.toggleTournamnetFavorite(this.sportId, this.tournamentId);
                break;
            case FAVORITE_CONFIG.availTypes[1]:
                this.favorite.toggleParticipantFavorite(this.participantId);
                break;
            case FAVORITE_CONFIG.availTypes[2]:
                this.favorite.toggleMatchFavorite(this.matchId);
                this.user.setMyMatches().subscribe((): void => {
                    console.info('User MyMatches updated !!');
                });
                break;
            case FAVORITE_CONFIG.availTypes[3]:
                this.favorite.toggleMatchFavorite(this.matchId);
                this.user.setMyMatches().subscribe((): void => {
                    console.info('User MyMatches updated !!');
                });
                break;
            default:
                break;
        }

        this.windowRefService.nativeWindow.dataLayer.push({
            event: 'add_to_favorites',
            sport: `sport_id_${this.sportId}`,
            league: `tournament_${this.tournamentId}`,
            match: `match_${this.matchId}`,
            type: this.type,
        });

        MatchListService.categoryCacheLimit = MatchListService.categoryLimit;
        event.stopPropagation();
        event.preventDefault();
    }

    public isFavorite(): boolean {
        if (this.type === FAVORITE_CONFIG.availTypes[0] || this.type === FAVORITE_CONFIG.availTypes[4]) {
            return this.favorite.existsTournament(
                this.sportId,
                this.tournamentId,
            );
        }
        if (this.type === FAVORITE_CONFIG.availTypes[1]) {
            return this.favorite.existsParticipant(this.participantId);
        }
        if (this.type === FAVORITE_CONFIG.availTypes[2] || this.type === FAVORITE_CONFIG.availTypes[3]) {
            return this.favorite.existsMatch(this.matchId);
        }

        return false;
    }

    /**
     * Check if type is tournament
     * @return {boolean}
     */
    public isTournament(): boolean {
        return this.type === FAVORITE_CONFIG.availTypes[0];
    }

    /**
     * Check if type is participant
     * @return {boolean}
     */
    public isParticipant(): boolean {
        return this.type === FAVORITE_CONFIG.availTypes[1];
    }

    /**
     * Check if type is match
     * @return {boolean}
     */
    public isMatch(): boolean {
        return this.type === FAVORITE_CONFIG.availTypes[2];
    }

    /**
     * Check if type is match list mobile
     * @return {boolean}
     */
    public isMatchListMobile(): boolean {
        return this.type === FAVORITE_CONFIG.availTypes[4];
    }

    /**
     * Check if type is match detail
     * @return {boolean}
     */
    public isMatchDetail(): boolean {
        return this.type === FAVORITE_CONFIG.availTypes[3];
    }
}
