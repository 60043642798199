/**
 *  Service for Time manipulation
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';

declare const moment: any;

@Injectable({
    providedIn: 'root',
})
export class TimeService {
    public static dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

    public static dateTimeFormat2Front: string = 'DD.MM HH:mm';

    public static dateTimeFormatCalendar: string = 'dd';
    public static dateTimeFormatCalendar2: string = 'dddd';

    public static dateTimeFormatFront: string = 'DD.MM.YYYY HH:mm:ss';

    public static dateTimeFormatFront2: string = 'DD.MM.YYYY HH:mm';

    public static dateFormatFront: string = 'DD.MM.YYYY';

    public static dateFormatSepFront: string = 'DD.MM YYYY';

    public static dateFormatFrontShort: string = 'DD.MM.YY';

    public static dateFormatFrontShort2: string = 'DD.MM';

    public static timeFormatFront: string = 'HH:mm';

    public static dateFormat: string = 'YYYY-MM-DD';

    public constructor() {}

    /**
     * Utc to local time
     * @return {any}
     */
    public static utcToLocal(utc: any): any {
        utc.local();
        return utc;
    }

    /**
     * Get local time from string/format
     * @param  {string} dateTime
     * @param  {string} format - format of dateTime (optional)
     * @return {any}
     */
    public static getLocalTime(
        dateTime: string,
        format?: string,
    ): any {
        return format ? moment(dateTime, format) : moment(dateTime);
    }

    /**
     * Get utc time from string/format
     * @param  {string} dateTime
     * @param  {string} format - format of dateTime (optional)
     * @return {any}
     */
    public static getUtcTime(dateTime: string, format?: string): any {
        return format ? moment.utc(dateTime, format) : moment.utc(dateTime);
    }

    /**
     * Local time to utc
     */
    public static toUtc(local: any): any {
        return local.utc();
    }

    /**
     * Get today start of day in local time
     * @return {any}
     */
    public static todayStartLocal(): any {
        return moment()
            .minute(0)
            .second(0)
            .hour(0);
    }

    /**
     * Get today end of day in local time
     * @return {any}
     */
    public static todayEndLocal(): any {
        return moment()
            .minute(59)
            .second(59)
            .hour(23);
    }

    /**
     * Get date start of day in local time
     * @param  {any} date
     * @return {any}
     */
    public static startLocal(date: any): any {
        return date
            .minute(0)
            .second(0)
            .hour(0);
    }

    /**
     * Get date end of day in local time
     * @param  {any} date
     * @return {any}
     */
    public static endLocal(date: any): any {
        return date
            .minute(59)
            .second(59)
            .hour(23);
    }
}
