/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Service for user
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
    SignIn,
    SignOff,
    User,
    UserDefault,
} from '../store/actions/user.action';
import { CommonService } from '../shared/common.service';
import { StorageService } from '../services/storage.service';
import { UserInterface } from '../interfaces/user.interface';
import URL_CONFIG from '../config/url.config';
import APP_CONFIG from '../config/app.config';
import {
    FormResponseInterface,
    FormErrorInterface,
} from '../interfaces/form-response.interface';

import { StatusInterface } from '../interfaces/status.interface';

import { AuthetificationDialogComponent } from '../components/user/authetification/authetification.component';

import { UtilsService } from './utils.service';
import { LangService } from './lang.service';


import { FavoriteService } from './favorite.service';
import { SnackBarService } from './snack-bar.service';

declare const moment: any;

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public redirectUrl = '';

    private httpOptions: Record<string, any>;

    public onNtf = new EventEmitter<string>();

    private static iso: null | string = null;

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private storage: StorageService,
        private lang: LangService,
        private store: Store,
        public dialog: MatDialog,
        private router: Router,
        private translate: TranslateService,
        private snackbar: SnackBarService,
        public favorite: FavoriteService,
    ) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }

    public openModal(
        type: string = 'signin',
        redirectUrl: string | null = null,
    ): void {
        redirectUrl = redirectUrl !== null ? redirectUrl : this.router.url;
        const dialogRef = this.dialog.open(AuthetificationDialogComponent, {
            width: '50%',
            data: { type, redirectUrl },
        });

        dialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    public static setIso(iso: string): void {
        UserService.iso = iso;
    }

    public static getIso(): string|null {
        return UserService.iso;
    }

    /**
     * Return true if user is logged in
     * @return [{Observable<boolean>}
     */
    public get isLoggedIn(): boolean {
        if (!this.expire() && this.storage.isset(APP_CONFIG.authKey)) {
            return true;
        }
        return false;
    }

    public get fullName(): string {
        const user = this.storage.get<UserInterface>(APP_CONFIG.authKey);
        return `${user?.email}`;
    }

    public get fullUser(): Record<string, any> | null {
        const user = this.storage.get<UserInterface>(APP_CONFIG.authKey);
        return user;
    }

    /**
     * Get User status
     * @return {Promise<boolean>}
     */
    public getStatus(): Promise<boolean> {
        const url = URL_CONFIG.api.getStatus;

        return new Promise((resolve, reject): void => {
            this.http
                .get<UserInterface>(url)
                .pipe(
                    distinctUntilChanged(),
                    switchMap(this.map),
                )
                .subscribe(
                    (val): void => {
                        const actual = moment().unix();
                        this.storage.set(APP_CONFIG.authExpirationKey, actual);
                        this.storage.set(APP_CONFIG.authKey, val);
                        this.store.dispatch(new SignIn());
                        this.store.dispatch(new User(val));
                        resolve(true);
                    },
                    (): void => {
                        this.storage.remove(APP_CONFIG.authExpirationKey);
                        this.storage.remove(APP_CONFIG.authKey);
                        this.store.dispatch(new SignOff());
                        this.store.dispatch(new UserDefault());
                        reject(new Error());
                    },
                );
        });
    }

    public signOffProcces(): void {
        this.signOff().then(
            (): void => {
                this.router.navigate(['/hp'], { queryParams: { signoff: 1 } });
            },
            (): void => {
                this.openSnackBar(
                    this.translate.instant('sign_off_failed'),
                    'info',
                );
            },
        );
    }

    public openSnackBar(message: string, action: 'info' | 'alert'): void {
        this.snackbar.openSnackBar(message, 'top', action);
    }

    /**
     *
     * @return {void}
     */
    public signOff(): Promise<boolean> {
        const url = URL_CONFIG.api.signOff;

        return new Promise((resolve, reject): void => {
            this.http
                .get<UserInterface>(url)
                .pipe(
                    distinctUntilChanged(),
                    switchMap(this.map),
                )
                .subscribe(
                    (): void => {
                        this.storage.remove(APP_CONFIG.authExpirationKey);
                        this.storage.remove(APP_CONFIG.authKey);
                        this.store.dispatch(new SignOff());
                        resolve(true);
                    },
                    (): void => {
                        reject(new Error());
                    },
                );
        });
    }

    private map(data: any): Observable<any> {
        return of(data);
    }

    /**
     * Create uniq id for user if does nto exists
     * @return   {Promise<string>}
     */
    public createUUID(): Promise<string> {
        return new Promise((resolve): void => {
            let token = UtilsService.createUUID();
            if (!this.storage.isset(APP_CONFIG.userUUIDkey)) {
                this.storage.set(APP_CONFIG.userUUIDkey, token);
            } else {
                token = this.storage.get<string>(APP_CONFIG.userUUIDkey)!;
            }
            resolve(token);
        });
    }

    /**
     * Authentication (VAPID)  setup
     *  @param {object} sub Subscription object
     * @return {Observable<any>}
     */
    public vapid(sub: Record<string, any>): Observable<any> {
        const url = URL_CONFIG.api.vapid;
        if (!sub.endpoint) {
            return throwError('Endpoint in Subscription object not defined');
        }
        const data = {
            uuid: this.storage.get<string>(APP_CONFIG.userUUIDkey),
            endpoint: sub.endpoint,
            lang: this.lang.getLangSnapshot(),
            auth: sub.keys && sub.keys.auth ? sub.keys.auth : '',
            p256dh: sub.keys && sub.keys.p256dh ? sub.keys.p256dh : '',
        };
        return this.http
            .post<StatusInterface>(url, data)
            .pipe(catchError(this.common.errorCallback));
    }

    /**
     * Update user my matches to server
     * @return {Observable<any>}
     */
    public setMyMatches(): Observable<any> {
        const url = URL_CONFIG.api.updMyMatches;

        if (!this.storage.isset(APP_CONFIG.userUUIDkey)) {
            return throwError('UUID not defined');
        }

        const data = {
            uuid: this.storage.get<string>(APP_CONFIG.userUUIDkey),
            matches: this.favorite.getMatchFavorites(),
        };
        return this.http
            .post<StatusInterface>(url, data)
            .pipe(catchError(this.common.errorCallback));
    }

    /**
     * Register new user
     * @param  data
     * @return  {Observable<FormResponseInterface |
     * FormErrorInterface>}
     */
    public makeRegistration<T extends Record<string, any>>(
        data: T,
    ): Observable<FormResponseInterface | FormErrorInterface> {
        const url = URL_CONFIG.api.registration;
        Object.assign(data, { lang: this.lang.getLangSnapshot() });
        return this.http
            .post<FormResponseInterface | FormErrorInterface>(url, data, {
            ...this.httpOptions,
            observe: 'body',
        })
            .pipe(catchError(this.common.errorFormCallback));
    }

    /**
     * SignIn
     * @param  data
     * @param  {boolean} social - if true social plugin
     * @return  {Observable<FormResponseInterface |
     * FormErrorInterface>}
     */
    public signIn<T>(
        data: T,
        social: boolean = false,
    ): Observable<FormResponseInterface | FormErrorInterface> {
        const url = social
            ? URL_CONFIG.api.signInSocial
            : URL_CONFIG.api.signIn;
        return this.http
            .post<FormResponseInterface | FormErrorInterface>(url, data, {
            ...this.httpOptions,
            observe: 'body',
        })
            .pipe(catchError(this.common.errorFormCallback));
    }

    /**
     * Forgotten password
     * @param  data
     * @return  {Observable<FormResponseInterface |
     * FormErrorInterface>}
     */
    public forgottenPassword<T extends Record<string, any>>(
        data: T,
    ): Observable<FormResponseInterface | FormErrorInterface> {
        const url = URL_CONFIG.api.forgottenPassword;
        Object.assign(data, { lang: this.lang.getLangSnapshot() });
        return this.http
            .post<FormResponseInterface | FormErrorInterface>(url, data, {
            ...this.httpOptions,
            observe: 'body',
        })
            .pipe(catchError(this.common.errorFormCallback));
    }

    /**
     * Reset password
     * @param  data
     * @return  {Observable<FormResponseInterface |
     * FormErrorInterface>}
     */
    public resetPassword<T>(
        data: T,
    ): Observable<FormResponseInterface | FormErrorInterface> {
        const url = URL_CONFIG.api.resetPassword;
        return this.http
            .post<FormResponseInterface | FormErrorInterface>(url, data, {
            ...this.httpOptions,
            observe: 'body',
        })
            .pipe(catchError(this.common.errorFormCallback));
    }

    /**
     * Verifycation of user activation code
     * @param  data
     * @return  {Observable<FormResponseInterface |
     * FormErrorInterface>}
     */
    public verify(code: string): Observable<StatusInterface> {
        const url = UtilsService.replace(
            'code',
            code as string,
            URL_CONFIG.api.userVerify,
        );
        return this.http
            .get<StatusInterface>(url, {
            ...this.httpOptions,
        })
            .pipe(catchError(this.common.errorCallback));
    }

    /**
     * IF true
     * @return [description]
     */
    private expire(): boolean {
        const actual = moment().unix();
        if (!this.storage.isset(APP_CONFIG.authExpirationKey)) {
            this.storage.set(APP_CONFIG.authExpirationKey, actual);
            return true;
        }
        /*eslint-disable */
        const time = this.storage.get(APP_CONFIG.authExpirationKey);
        const difference = actual - (time as number);
        /* eslint-enable */

        if (difference > APP_CONFIG.authExpiration) {
            return true;
        }

        return false;
    }
}
