<div class="modal-content">
    <div class="modal-body p-0">
        <div class="row no-gutters">
            <div class="col-md-6">
                <div mat-dialog-content class="mat-dialog-content--login">
                    <ul
                        class="nav nav-tabs nav-tabs--dark nav-justified text-uppercase font-weight-bold border-0"
                        id="authTab"
                        role="tablist"
                    >
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                [class.active]="data.type == 'signin'"
                                id="signin-tab"
                                data-toggle="tab"
                                href="#signin"
                                role="tab"
                                aria-controls="signin"
                                aria-selected="true"
                                >{{ 'web.sign_in' | translate | transinit }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                [class.active]="data.type == 'registration'"
                                id="registration-tab"
                                data-toggle="tab"
                                href="#registration"
                                role="tab"
                                aria-controls="registration"
                                aria-selected="false"
                                >{{ 'web.registration' | translate | transinit }}</a
                            >
                        </li>
                    </ul>

                    <div class="tab-content p-5">
                        <div
                            class="tab-pane show active pt-5"
                            style="position: relative; min-height: 300px"
                            [class.active]="data.type == 'signin'"
                            id="signin"
                            role="tabpanel"
                            aria-labelledby="signin-tab"
                        >
                            <div *ngIf="signState == 'signInBlock'" style="position: relative; top: 0px; left: 0px">
                                <h4 class="h2 modal-title mb-4">
                                    <svg class="icon icon--32 mr-3">
                                        <use href="#icon-user" />
                                    </svg>
                                    {{ 'web.sign_in' | translate }}
                                </h4>
                                <app-signin
                                    (onChange)="change($event)"
                                    [redirectUrl]="redirect"
                                    idName="desktopHeader"
                                ></app-signin>
                            </div>
                            <div
                                *ngIf="signState == 'forgottenPassword'"
                                style="position: relative; top: 0px; left: 0px"
                            >
                                <app-forgotten-password (onChange)="change($event)"></app-forgotten-password>
                            </div>
                        </div>
                        <div
                            class="tab-pane pt-5"
                            [class.active]="data.type == 'registration'"
                            id="registration"
                            role="tabpanel"
                            aria-labelledby="registration-tab"
                        >
                            <h4 class="h2 modal-title mb-4">
                                <svg class="icon icon--32 mr-3">
                                    <use href="#icon-user" />
                                </svg>
                                {{ 'web.registration' | translate }}
                            </h4>
                            <app-registration (onChange)="change($event)"></app-registration>
                        </div>
                        <div class="small pt-5 position-relative mt-auto">
                            <svg class="icon icon--20 mr-2 position-absolute">
                                <use href="#icon-info" />
                            </svg>
                            <div class="pl-5">
                                {{ 'web.forgotten_password_text' | translate }}
                                <a
                                    (click)="goToForgottenPassword()"
                                    class="text-reset font-weight-bold text-underline app-cursor"
                                >
                                    {{ 'web.forgotten_password' | translate }}</a
                                >.
                            </div>
                        </div>
                    </div>
                </div>
                <div mat-dialog-actions>
                    <button
                        type="button"
                        class="close close--login"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="onNoClick()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div class="col-md-6 d-none d-md-flex p-0">
                <img src="/img/24live_livescore_aboutus_catch_every_moment2.jpg" alt="" class="img-fluid img-cover" />
            </div>
        </div>
    </div>
</div>
