<div class="app-search-dialog">
    <h3 class="mat-dialog-title mb-3 font-weight-normal" mat-dialog-title id="mat-dialog-title-1">
        <svg class="icon icon--24 mr-3"><use href="#icon-search" /></svg>{{ 'web.search' | translate }}
    </h3>
    <mat-dialog-content class="mat-typography">
        <div class="row no-gutters mb-3 mb-md-0">
            <div class="col-md-7">
                <mat-form-field class="app-full-width">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_search_text' | translate }}"
                        type="search"
                        data-cy="search-text"
                        class="form-control app-search-fulltext"
                        [formControl]="searchForm.searchFormControl"
                    />
                    <mat-hint class="d-none d-md-block">{{ 'web.enter_text_min_length_3' | translate }}</mat-hint>
                    <mat-error
                        class="d-none d-md-block"
                        *ngIf="
                            !searchForm.searchFormControl?.pristine &&
                            searchForm.searchFormControl?.hasError('minlength')
                        "
                    >
                        {{ 'web.error_min_legth' | translate }}:
                        {{ searchForm.searchFormControl.errors.minlength.requiredLength }} ({{
                            searchForm.searchFormControl.errors.minlength.actualLength
                        }})
                    </mat-error>
                    <mat-error class="d-none d-md-block" *ngIf="searchForm.searchFormControl?.hasError('required')">
                        {{ 'web.error_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <ng-container *ngIf="!isMobile; else mobile">
                    <select class="custom-select border-light2 mb-3" [formControl]="searchForm.sportFormControl">
                        <option value="0">{{ 'web.all_sports' | translate }}</option>
                        <option *ngFor="let sport of sport.data; trackBy: trackByIndex" [value]="sport.id">
                            {{ 'sport.' + sport.code_name | translate }}
                        </option>
                    </select>
                </ng-container>
                <ng-template #mobile>
                    <select class="custom-select border-light2 mb-3" [formControl]="searchForm.sportFormControl">
                        <option value="0">{{ 'web.all_sports' | translate }}</option>
                        <option *ngFor="let sport of sport.data; trackBy: trackByIndex" [value]="sport.id">
                            {{ 'sport.' + sport.code_name | translate }}
                        </option>
                    </select>
                </ng-template>
            </div>
        </div>

        <div class="mt-5">
            <div *ngIf="loaded; else elseBlock">
                <ng-container *ngIf="loadedError">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use href="#icon-empty" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngFor="let row of data | keys">
                    <div *ngIf="notEmpty(row.value)" data-cy="search-result">
                        <h4 class="table-sport__head mb-0 bg-dark" [attr.data-cy]="'search-head-' + row.key">
                            {{ 'web.' + row.key | translate }}
                        </h4>
                        <div *ngFor="let sport of row.value | keys" class="table-sport__content">
                            <h5 class="table-sport__subhead mb-0 bg-white d-flex align-items-center app-search-sport">
                                <!-- <img src="/img/sports/{{ sport.key }}.png" alt="{{ 'web.' + sport.key | translate }}" width="20" class="mr-2"> -->
                                {{ 'sport.' + transformate(sport.key) | translate }}
                            </h5>
                            <div
                                class="table-sport__row border-white"
                                *ngFor="let item of sport.value; trackBy: trackById"
                            >
                                <div class="table-sport__cell table-sport__cell--img ml-2">
                                    <img [src]="item.image" class="img-fluid" [alt]="item.name" *ngIf="item.image" />
                                </div>
                                <div class="table-sport__cell table-sport__cell--team">
                                    <a
                                        (click)="goToDetail(row.key, item)"
                                        class="app-cursor font-weight-bold app-search-dialog__item"
                                    >
                                        {{ item.name }}
                                        <ng-container *ngIf="item.category">
                                            -
                                            <small
                                                >{{ item.category }}
                                                <ng-container
                                                    *ngIf="item.rawName && isSupportedSport(item.sport_code_name)"
                                                >
                                                    ({{ item.rawName }})
                                                </ng-container>
                                            </small>
                                        </ng-container>

                                        <ng-container *ngIf="item.tournaments">
                                            -
                                            <small>
                                                {{ item.tournaments[0]?.sub_tournament_name }}
                                                <ng-container
                                                    *ngIf="
                                                        item.tournaments[0]?.category_name &&
                                                        item.tournaments[0]?.category_name.length > 1
                                                    "
                                                >
                                                    ({{ item.tournaments[0]?.category_name }})
                                                </ng-container>
                                            </small>
                                        </ng-container>
                                    </a>
                                    <div *ngIf="row.key == 'participants'">
                                        <app-favorite [participantId]="item.id" [type]="'participant'">
                                            <span notactive>{{ 'web.add_participant_to_favorites' | translate }}</span>
                                            <span active>{{
                                                'web.remove_participant_from_favorites' | translate
                                            }}</span>
                                        </app-favorite>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="row justify-content-center" data-cy="search-not-found" *ngIf="noData()">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-2">
                            <svg class="icon icon--64 mb-4"><use href="#icon-empty" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry_search' | translate }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="col align-self-center" *ngIf="!pristine"><app-page-loader></app-page-loader></div>
            </ng-template>
            <div *ngIf="pristine || noData()" data-cy="search-nodata">
                <h4 class="table-sport__subhead mb-0 bg-white d-flex align-items-center">
                    <strong>{{ 'web.latest_search' | translate }}</strong>
                </h4>
                <div *ngIf="getLatest('participants').length > 0">
                    <h4 class="table-sport__head mb-0 bg-dark">{{ 'web.participants' | translate }}</h4>
                    <div
                        class="row"
                        *ngFor="let item of getLatest('participants'); trackBy: trackById"
                        class="table-sport__row border-white"
                    >
                        <div class="table-sport__cell table-sport__cell--img ml-2">
                            <img [src]="item.image" class="img-fluid" [alt]="item.name" />
                        </div>
                        <div class="table-sport__cell table-sport__cell--team">
                            <a
                                (click)="goToDetail('participants', item)"
                                class="app-cursor app-search-dialog__item"
                                data-cy="search-last-participant"
                            >
                                {{ item.name }}
                                <ng-container *ngIf="item.tournaments && item.tournaments.length > 0">
                                    - {{ item.tournaments[0].sub_tournament_name }} ({{
                                        item.tournaments[0].category_name
                                    }})
                                </ng-container>
                            </a>
                            <div>
                                <app-favorite [participantId]="item.id" [type]="'participant'">
                                    <span notactive>{{ 'web.add_participant_to_favorites' | translate }}</span>
                                    <span active>{{ 'web.remove_participant_from_favorites' | translate }}</span>
                                </app-favorite>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="getLatest('players').length > 0">
                    <h4 class="table-sport__head mb-0 bg-dark">{{ 'web.players' | translate }}</h4>
                    <div
                        class="row"
                        *ngFor="let item of getLatest('players'); trackBy: trackById"
                        class="table-sport__row border-white"
                    >
                        <div class="table-sport__cell table-sport__cell--img ml-2">
                            <img [src]="item.image" class="img-fluid" [alt]="item.name" />
                        </div>
                        <div class="table-sport__cell table-sport__cell--team">
                            <a (click)="goToDetail('players', item)" class="app-cursor app-search-dialog__item">
                                {{ item.name }}
                            </a>
                            <div>
                                <app-favorite [participantId]="item.id" [type]="'participant'">
                                    <span notactive>{{ 'web.add_participant_to_favorites' | translate }}</span>
                                    <span active>{{ 'web.remove_participant_from_favorites' | translate }}</span>
                                </app-favorite>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="getLatest('tournaments').length > 0">
                    <h4 class="table-sport__head mb-0 bg-dark">{{ 'web.tournaments' | translate }}</h4>
                    <div
                        class="row"
                        *ngFor="let item of getLatest('tournaments'); trackBy: trackById"
                        class="table-sport__row border-white"
                    >
                        <div class="table-sport__cell table-sport__cell--img ml-2">
                            <img [src]="item.image" class="img-fluid" [alt]="item.name" *ngIf="item.image" />
                        </div>
                        <div class="table-sport__cell table-sport__cell--team">
                            <a (click)="goToDetail('tournaments', item)" class="app-cursor app-search-dialog__item">
                                {{ item.name }}
                                <ng-container *ngIf="item.tournaments && item.tournaments.length > 0">
                                    - {{ item.tournaments[0].sub_tournament_name }} ({{
                                        item.tournaments[0].category_name
                                    }})
                                </ng-container>
                            </a>
                            <div>
                                <app-favorite [participantId]="item.id" [type]="'participant'">
                                    <span notactive>{{ 'web.add_participant_to_favorites' | translate }}</span>
                                    <span active>{{ 'web.remove_participant_from_favorites' | translate }}</span>
                                </app-favorite>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-dialog-close class="btn btn-outline-secondary btn-sm">{{ 'web.close' | translate }}</button>
    </mat-dialog-actions>
</div>
