/**
 *  Service for Favorite
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import * as _ from 'underscore';

import FAVORITE_CONFIG from '../config/favorite.config';

import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class FavoriteService {
    public constructor(private storage: StorageService) {}

    /**
     * Fire event when some tournament  is toggled from favorites
     * @event FavoriteService#onToggleTournament:
     * @return {EventEmitter}
     */
    public onToggleTournament: EventEmitter<number> = new EventEmitter();

    /**
     * Fire event when some match  is toggled from favorites
     * @event FavoriteService#onToggleMatch:
     * @return {EventEmitter}
     */
    public onToggleMatch: EventEmitter<number> = new EventEmitter();

    /**
     * Fire event when some participant  is toggled from favorites
     * @event FavoriteService#onToggleMatch:
     * @return {EventEmitter}
     */
    public onToggleParticipant: EventEmitter<number> = new EventEmitter();

    /**
     * Toggle participant to favorite
     * @return {void}
     */
    public toggleParticipantFavorite(participantId: number): void {
        const values: number[] = this.getParticipantFavorites();
        const index = _.indexOf(values, participantId);

        if (index === -1) {
            values.push(participantId);
        } else {
            values.splice(index, 1);
        }

        this.saveP(values).then(
            (): void => {
                try {
                    this.onToggleParticipant.emit(participantId);
                } catch (e: any) {
                    throw new Error(e);
                }
            },
        );
    }

    /**
     * Toggle match to favorite
     * @param {number} matchId - id of match
     * @param {boolean} onlyAdd - if tru match will be added if not exists otherwise will not be deleted
     * @return {void}
     */
    public toggleMatchFavorite(
        matchId: number,
        onlyAdd: boolean = false,
    ): void {
        const values: number[] = this.getMatchFavorites();
        const index = _.indexOf(values, matchId);

        if (index === -1) {
            values.push(matchId);
        } else if (!onlyAdd) {
            values.splice(index, 1);
        }

        this.saveM(values).then(
            (): void => {
                try {
                    this.onToggleMatch.emit(matchId);
                } catch (e: any) {
                    throw new Error(e);
                }
            },
        );
    }

    /**
     * Get number of matches favorites
     * @return {number}
     */
    public getNumMatches(): number {
        return +this.getMatchFavorites().length;
    }

    /**
     * Get or init participant favorite
     * @return {number[]}
     */
    public getParticipantFavorites(): number[] {
        return (!this.storage.isset(FAVORITE_CONFIG.participantKey)
            ? []
            : this.storage.get<number[]>(
                FAVORITE_CONFIG.participantKey,
            )) as number[];
    }

    /**
     * Get or init match favorite
     * @return {number[]}
     */
    public getMatchFavorites(): number[] {
        return (!this.storage.isset(FAVORITE_CONFIG.matchKey)
            ? []
            : this.storage.get<number[]>(FAVORITE_CONFIG.matchKey)) as number[];
    }

    /**
     * Empty match favorite
     * @return {Promise<any>}
     */
    public emptyMatchFavorites(): Promise<void> {
        return new Promise((resolve, reject): void => {
            this.saveM([]).then((): void => resolve(), (): void => reject());
        });
    }

    /**
     * Check if participant is in favorite
     * @param  {number} participantId
     * @return {boolean}
     */
    public existsParticipant(participantId: number): boolean {
        return _.indexOf(this.getParticipantFavorites(), participantId) !== -1;
    }

    /**
     * Check if match is in favorite
     * @param  {number} matchId
     * @return {boolean}
     */
    public existsMatch(matchId: number): boolean {
        return _.indexOf(this.getMatchFavorites(), matchId) !== -1;
    }

    /**
     * Toggle tournament to favorite
     * @return {void}
     */
    public toggleTournamnetFavorite(
        sportId: number,
        tournamentId: number,
        click: boolean = true,
        onlyRemove: boolean = false,
    ): void {
        const values: number[] = this.getTournamentFavorites(sportId);
        let clicked: number[] = this.getTournamentClickedFavorites(sportId);
        const index = _.indexOf(values, tournamentId);
        const indexClicked = _.indexOf(clicked, tournamentId);

        if (index === -1) {
            if (!onlyRemove) {
                values.push(tournamentId);
            }
        } else {
            values.splice(index, 1);
        }

        if (click) {
            if (indexClicked !== -1) {
                clicked.splice(indexClicked, 1);
            } else if (!onlyRemove) {
                clicked.push(tournamentId);
                clicked = _.uniq(clicked);
            }
        }

        this.saveT(sportId, values).then(
            async (): Promise<any> => {
                try {
                    await this.saveTC(sportId, clicked);
                } catch (e: any) {
                    throw new Error(e);
                }
                if (click) {
                    this.onToggleTournament.emit(tournamentId);
                }
            },
        );
    }

    /**
     * Check if tournament is in favorite
     * @param  {number} sportId
     * @param  {number} tournamentId
     * @return {boolean}
     */
    public existsTournament(sportId: number, tournamentId: number): boolean {
        return (
            _.indexOf(this.getTournamentFavorites(sportId), tournamentId) !== -1
        );
    }

    /**
     * Check if tournament is in favorite clicked
     * @param  {number} sportId
     * @param  {number} tournamentId
     * @return {boolean}
     */
    public existsTournamentClicked(
        sportId: number,
        tournamentId: number,
    ): boolean {
        return (
            _.indexOf(
                this.getTournamentClickedFavorites(sportId),
                tournamentId,
            ) !== -1
        );
    }

    /**
     * Get or init tournament favorite
     * @return {number[]}
     */
    public getTournamentFavorites(sportId: number): number[] {
        return (!this.storage.isset(
            `${FAVORITE_CONFIG.tournamentKey}_${sportId}`,
        )
            ? []
            : this.storage.get<number[]>(
                `${FAVORITE_CONFIG.tournamentKey}_${sportId}`,
            )) as number[];
    }

    /**
     * Get or init tournament clicked favorite
     * @return {number[]}
     */
    public getTournamentClickedFavorites(sportId: number): number[] {
        return (!this.storage.isset(
            `${FAVORITE_CONFIG.tournamentClickedKey}_${sportId}`,
        )
            ? []
            : this.storage.get<number[]>(
                `${FAVORITE_CONFIG.tournamentClickedKey}_${sportId}`,
            )) as number[];
    }

    /**
     * Save tournament favorites
     * @param  {number} sportId
     * @param {number[]} values
     * @return {Promise}
     */
    private saveT(sportId: number, values: number[]): Promise<true> {
        return new Promise((resolve): void => {
            this.storage.set(
                `${FAVORITE_CONFIG.tournamentKey}_${sportId}`,
                values,
            );
            resolve(true);
        });
    }

    /**
     * Save tournament favorites clicked
     * @param  {number} sportId
     * @param {number[]} values
     * @return {Promise}
     */
    private saveTC(sportId: number, values: number[]): Promise<true> {
        return new Promise((resolve): void => {
            this.storage.set(
                `${FAVORITE_CONFIG.tournamentClickedKey}_${sportId}`,
                values,
            );
            resolve(true);
        });
    }

    /**
     * Save participant favorites
     * @param {number[]} values
     * @return {Promise}
     */
    private saveP(values: number[]): Promise<true> {
        return new Promise((resolve): void => {
            this.storage.set(FAVORITE_CONFIG.participantKey, values);
            resolve(true);
        });
    }

    /**
     * Save match favorites
     * @param {number[]} values
     * @return {Promise}
     */
    public saveM(values: number[]): Promise<true> {
        return new Promise((resolve): void => {
            this.storage.set(FAVORITE_CONFIG.matchKey, values);
            resolve(true);
        });
    }
}
