/**
 * Block all transition out of page
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class RoutingBlockGuard implements CanDeactivate<CanComponentDeactivate> {
    public canDeactivate(): Observable<boolean> | boolean {
        return false;
    }
}
