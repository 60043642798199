/**
 *  FFortebet Uganda republic configuration
 * fortebet_ug
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */

import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: true,
        pagesInternal: false,
        cookie: false,
        oddsTab: true,
        closeIcon: false,
        share: true,
        winProbability: false,
        matchListAlpha: true,
        sportQuickNav: true,
        desktopLogo: true,
        betsStats: true,
        pageDarkMode: false,
        prematchH2HDefault: false,
        liveIcon: true,
        news: true,
        video: true,
        channels: true,
        map: true,
        canonicalPartner: false,
        partners: false,
        opta: false,
        useDefaultLogo: false,
        notification: true,
        tracker: true,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        seoCard: false,
    },
    pages: {
        about_us: 'https://www.fortebet.ug/#/app/page/about-us/en',
        contact: 'https://www.fortebet.ug/#/app/page/client-support/en',
        livebet: 'https://www.fortebet.ug/#/app/live/preview',
        condition: 'https://www.fortebet.ug/#/app/termsconditions/rules_privacy_policy/en',
    },
    social: {
        facebook: 'https://www.facebook.com/fortebet.ug/',
    },
    content: {
        betsImg: '/img/ball.svg',
        betsText: 'web.got_to_fortebet',
    },
    bet: {
        winApi: true,
        displayConfirm: true,
        isOddsLiveAndAfter: false,
    },
    onelang: 'en',
    colors: {
        icons: 'white',
    },
};

export default config;
