/**
 *  Service handling frontend sounds
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';

import {
    MP3_UNIVERSAL_SCORE,
    MP3_UNIVERSAL_SET_END,
    MP3_UNIVERSAL_MATCH_SET_END,
} from '../config/sounds.config';

import { StorageService } from './storage.service';

const SOUND_KEY = 'SOUND_KEY';

@Injectable({
    providedIn: 'root',
})
export class SoundService {
    public constructor(private storage: StorageService) {}

    /**
     * Chekc if soudn is off
     * @return {boolean}
     */
    public isSoundOff(): boolean {
        let isOff = false;
        if (this.storage.isset(SOUND_KEY)) {
            isOff = this.storage.get<boolean>(SOUND_KEY)!;
        }
        return isOff;
    }

    /**
     * Toogle sound on/off
     * @return {void}
     */
    public switchSound(): void {
        const set = !this.isSoundOff();
        this.storage.set(SOUND_KEY, set);
    }

    /**
     * Universal score change
     * @throws {string} - error info
     */
    public score(): void {
        if ('Audio' in window) {
            const sound = new Audio(MP3_UNIVERSAL_SCORE);
            sound.volume = 0.75;
            sound.play();
        } else { throw Error('Audio not supported'); }
    }

    /**
     * Universal set change
     * @throws {string} - error info
     */
    public set(): void {
        if ('Audio' in window) {
            const sound = new Audio(MP3_UNIVERSAL_SET_END);
            sound.volume = 0.75;
            sound.play();
        } else { throw Error('Audio not supported'); }
    }

    /**
     * Universal set change
     * @throws {string} - error info
     */
    public matchEnd(): void {
        if ('Audio' in window) {
            const sound = new Audio(MP3_UNIVERSAL_MATCH_SET_END);
            sound.volume = 0.75;
            sound.play();
        } else { throw Error('Audio not supported'); }
    }
}
