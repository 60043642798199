<!-- The content below is only a placeholder and can be replaced. -->
<!-- <app-loader [displayVal]="loadingState()" [clientName]="client.getName()">
    Loading ...
</app-loader> -->
<div class="app-component-div">
    <!-- <div [@routeAnimations]="prepareRoute(outlet)" style="height:100%"> -->
    <router-outlet #outlet="outlet"></router-outlet>
    <!-- </div> -->
</div>
<app-add-to-screen> </app-add-to-screen>

<div [innerHTML]="jsonLD"></div>
<div class="environment" [class.d-none]="isDevel">Developement</div>
<div class="livescore-snackbar"></div>
