import {
    State,
    Action,
    StateContext,
    Selector,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { NetworkStatus, Media, Partner, PageReload, DebugMode, DarkMode,
    MatchNotificationMode } from '@store/actions/page.action';

import APP_CONFIG from '@config/app.config';

import { Observable, of } from 'rxjs';

import { DisplayModeInterface } from '@/interfaces/mode.interface';
import { StorageService } from '@/services/storage.service';

const storage: StorageService = new StorageService();

const pageReload = storage.get<number>(APP_CONFIG.pageReloadKey) ?? 0;

const darkModeVal = !!(storage.get(APP_CONFIG.switchStickyKeyPageMode) ?? false);

const matchNotificationModeVal = !!(storage.get(APP_CONFIG.switchStickyKeyMatchNotification) ?? true);

if (darkModeVal) {
    document.body.classList.add('dark-mode');
}

interface PageStoreInterface {
    network: number;
    failedRequests: number;
    partner: {name: string} | null;
    media: DisplayModeInterface | null;
    pageReload: number;
    debug: boolean;
    darkMode: boolean;
    matchNtfMode: boolean;
}

@State<PageStoreInterface>({
    name: 'page',
    defaults: { network: 1,
        failedRequests: 0,
        darkMode: darkModeVal,
        matchNtfMode: matchNotificationModeVal,
        media: null,
        partner: null,
        pageReload,
        debug: false },
})
@Injectable()
export class PageState {
    @Selector()
    public static network(state: PageStoreInterface): string {
        return state.network === 1 ? 'online' : 'offline';
    }

    @Selector()
    public static numFailedRequests(state: PageStoreInterface): number {
        return state.failedRequests;
    }

    @Selector()
    public static getMedia(state: PageStoreInterface): DisplayModeInterface | null {
        return state.media;
    }

    @Selector()
    public static darkMode(state: PageStoreInterface): boolean {
        return state.darkMode;
    }

    @Selector()
    public static matchNtfMode(state: PageStoreInterface): boolean {
        return state.matchNtfMode;
    }

    @Selector()
    public static isDebug(state: PageStoreInterface): boolean {
        return state.debug;
    }

    @Action(NetworkStatus)
    public updateNetworkStatus(ctx: StateContext<PageStoreInterface>, { status }: NetworkStatus): void {
        const state = ctx.getState();
        const failedPlus: number = state.failedRequests + 1;
        const failedRequests = status === 1 ? 0 : failedPlus;
        ctx.patchState({ network: status, failedRequests });
    }

    @Action(Media)
    public updateMedia(ctx: StateContext<PageStoreInterface>, { media }: {media: DisplayModeInterface}): void {
        ctx.patchState({ media });
    }

    @Action(DebugMode)
    public updateDebugMode(ctx: StateContext<PageStoreInterface>, { mode }: {mode: boolean}): void {
        ctx.patchState({ debug: mode });
    }

    @Action(MatchNotificationMode)
    public updateMatchNtfMode(ctx: StateContext<PageStoreInterface>, { mode: matchNtfMode }: {mode: boolean}): void {
        storage.set(APP_CONFIG.switchStickyKeyMatchNotification, matchNtfMode);
        ctx.patchState({ matchNtfMode });
    }

    @Action(DarkMode)
    public updateDarkMode(ctx: StateContext<PageStoreInterface>, { mode: darkMode }: {mode: boolean}): void {
        storage.set(APP_CONFIG.switchStickyKeyPageMode, darkMode);

        if (darkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        ctx.patchState({ darkMode });
    }

    @Action(PageReload)
    public updatePageReaload(ctx: StateContext<PageStoreInterface>,
        { isManualReload }: {isManualReload: boolean}): Observable<number> {
        const state = ctx.getState();

        const pageReload = !isManualReload ? 0 : state.pageReload + 1;
        storage.set(APP_CONFIG.pageReloadKey, pageReload);
        ctx.patchState({ pageReload });
        return of(pageReload);
    }

    @Action(Partner)
    public updatePartner(ctx: StateContext<PageStoreInterface>, { name }: Record<string, any>): void {
        const state = ctx.getState();
        ctx.patchState({
            partner: {
                ...state.partner,
                name,
            },
        });
    }
}
