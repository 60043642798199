import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import APP_CONFIG from 'src/app/config/app.config';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

import PACKAGE from '../../../../../../package.json';

import { TranslationService } from '@/services/translation.service';

declare const moment: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    public time: any;

    public package: any;

    @Input()
    public sportName: string;

    public constructor(
        public client: ClientService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
        private user: UserService,
    ) {}

    public ngOnInit(): void {
        this.time = moment();
        this.package = PACKAGE;
    }

    public activeNtf(e: MouseEvent): void {
        this.storage.remove(APP_CONFIG.swNTFkey);
        this.user.onNtf.emit('active');
        e.preventDefault();
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    get isTranslateLoaded(): boolean {
        return TranslationService.isLoaded;
    }

    /**
     * Check if notification was declined
     */
    public get ntfDecline(): boolean {
        const ntf = this.storage.get<string>(APP_CONFIG.swNTFkey) || null;
        return ntf === 'no';
    }

    public get actTime(): any {
        return moment();
    }
}
