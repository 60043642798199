import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CommonService } from '../shared/common.service';
import { SearchInterface } from '../interfaces/search.interface';
import URL_CONFIG from '../config/url.config';
import CONFIG from '../config/app.config';

import { LangService } from './lang.service';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private http: HttpClient, private lang: LangService, private common: CommonService) {}

    /**
     * Get search result
     * @param  {string} gueryString
     * @param  {number} sportId
     * @return {Observable<SearchInterface>}
     */
    get(gueryString: string, sportId?: any): Observable<SearchInterface> {
        let iso = this.lang.getLangSnapshot();

        if (iso == null) {
            iso = CONFIG.defaultLang;
        }

        const url = URL_CONFIG.api.getSearch;

        let params = { q: gueryString, lang: iso };

        if (sportId !== null) {
            const ob = { sportId };
            params = { ...params, ...ob };
        }
        const options = params ? { params } : {};
        return this.http.get<SearchInterface>(url, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }
}
