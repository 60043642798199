/**
 *  Animation main navigation Levle 1- > level 2
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {

    style,
    animate,
    transition,

} from '@angular/animations';

export const SIGNIN_ANIMATION = [
    transition(':enter', [
        style({ transform: 'translateX(-110%)', opacity: '0' }),
        animate(
            '700ms  400ms ease-in',
            style({ transform: 'translateX(0%)', opacity: '1' }),
        ),
    ]),
    transition(':leave', [
        style({ position: 'absolute' }),
        animate(450, style({ transform: 'translateX(-100%)', opacity: '0' })),
    ]),
];
