export class UpdateMarkets {
    public static readonly type = '[Bets] Markets';

    public constructor() {}
}

export class GetOdds {
    public static readonly type = '[Bets] Get Odds';

    public constructor(
        public matchIds: number[],
        public onlyMain: boolean = true,
    ) {}
}
