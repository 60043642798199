import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ForgottenPasswordForm } from 'src/app/shared/forms/forgotten-password.form';
import { UserService } from 'src/app/services/user.service';
import { LangService } from 'src/app/services/lang.service';
import APP_CONFIG from 'src/app/config/app.config';
import { ForgottenPasswordInterface } from 'src/app/interfaces/forgotten-password.interface';

@Component({
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
})
export class ForgottenPasswordComponent {
    @ViewChild('captchaElem2', { static: true })
    public captcha: any;

    public submitText: string = 'reset_password';

    public siteKey: string = APP_CONFIG.recaptchaSiteKey;

    /**
     * True in case of success forgotten password
     */
    public success: boolean = false;

    @Output()
    public onChange: EventEmitter<string> = new EventEmitter();

    public constructor(public form: ForgottenPasswordForm, public user: UserService, public lang: LangService) {}

    public handleSuccessRC(token: any): void {
        this.form.patchValue({
            recaptcha: token,
        });
    }

    /**
     * Reset password
     * @return {void}
     */
    public send(): void {
        this.submitText = 'form_loading_text';
        this.form.getGroup().disable();
        this.success = false;
        this.user.forgottenPassword<ForgottenPasswordInterface>(this.form.getGroup().value).subscribe(
            (): void => {
                this.resetForm();
                this.success = true;
            },
            (er): void => {
                this.form.getGroup().enable();
                this.captcha.resetCaptcha();
                this.submitText = 'reset_password';
                this.form.setErrors(er);
            },
        );
    }

    /**
     * Reset forn
     */
    public resetForm(resetCaptcha: boolean = true): void {
        this.form.getGroup().enable();
        this.form.getGroup().reset();
        if (resetCaptcha) {
            this.captcha?.resetCaptcha();
        }
        this.submitText = 'reset_password';
        this.success = false;
    }

    /**
     * Go to sign in
     * @return {void}
     */
    public goToSignIn(): void {
        this.onChange.emit('signInBlock');
    }

    /**
     * Emit action
     * @param  {string} type
     * @return {void}
     */
    public action(type: string): void {
        this.onChange.emit(type);
    }
}
