
/**
 *  Service for match list
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
import * as _ from 'underscore';

import { StorageService } from '@services/storage.service';

import { CommonService } from '../shared/common.service';
import { LangService } from '../services/lang.service';
import { CategoryInterface } from '../interfaces/category.interface';
import { MatchData } from '../interfaces/match-data.interface';
import URL_CONFIG from '../config/url.config';
import SPORT_CONFIG from '../config/sport.config';

import { FavoriteService } from './favorite.service';
import { TimeService } from './time.service';
import { UtilsService } from './utils.service';

import { MatchTab } from '@/interfaces/match-list.interface';

declare const moment: any;

@Injectable({
    providedIn: 'root',
})
export class MatchListService {
    public static categoryCacheLimit = 1;

    public loaded: EventEmitter<number> = new EventEmitter();

    public alphaReset: EventEmitter<boolean> = new EventEmitter();

    private vallastTab: MatchTab;

    public static categoryLimit = 20;

    public static liveCategoryLimit = 4;

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
        private favorite: FavoriteService,
        private store: StorageService,
    ) {}

    public get lastTab(): MatchTab {
        return this.vallastTab;
    }

    public set lastTab(tab: MatchTab) {
        this.vallastTab = tab;
    }

    /**
     * Get Match list categories
     * @return {Promise<Observable<CategoryInterface>>}
     */
    public async getCategories(
        sportId: any,
        type: string = 'all',
        from: any,
        to: any,
        sort: string = 'alpha',
        first: boolean = false,
        category: string = '',
    ): Promise<Observable<CategoryInterface[]>> {
        if (!sportId) {
            return Promise.reject(new Error('Sport id not defined'));
        }

        const url = UtilsService.replace('sportId', sportId as string, URL_CONFIG.api.getCategory);

        if (type === 'live') {
            from = TimeService.todayStartLocal();
            to = TimeService.todayEndLocal();
        }
        return this.lang.getLang().then(
            (iso): Observable<CategoryInterface[]> => {
                const params = {
                    lang: iso,
                    type,
                    sort,
                    from: TimeService.toUtc(from).format(TimeService.dateTimeFormat),
                    to: TimeService.toUtc(to).format(TimeService.dateTimeFormat),
                    category,
                };
                const options = params ? { params } : {};
                const cacheKey: string = JSON.stringify({ ...params, sportId, type });
                if ((type !== 'live' || MatchListService.liveCategoryLimit >= 0) && (first ||
                        MatchListService.categoryCacheLimit < MatchListService.categoryLimit) &&
                    this.store.isset(SPORT_CONFIG.categoryMatchListCache)) {
                    MatchListService.liveCategoryLimit -= 1;

                    const { key, val } = this.store.get<Record<'key' | 'val', string | Record<string, any>>>(
                        SPORT_CONFIG.categoryMatchListCache,
                    ) as Record<'key' | 'val', any>;

                    if (key === cacheKey) {
                        MatchListService.categoryCacheLimit += 1;

                        return of(val) as Observable<CategoryInterface[]>;
                    }
                }

                MatchListService.liveCategoryLimit = 4;

                return this.http.get<CategoryInterface[]>(url, options).pipe(
                    distinctUntilChanged(),
                    switchMap(this.mapCategory(sportId)),
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap((val) => {
                        this.store.set(SPORT_CONFIG.categoryMatchListCache, { key: cacheKey, val });
                        MatchListService.categoryCacheLimit = 1;
                        this.common.networkOnline()();
                    }),
                );
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    /**
     * Get Match list categories
     * @return {Promise<Observable<MatchData>>}
     */
    public async getMatches(
        sportId: any,
        subTournamnetIds: number[],
        type: string = 'all',
        from: any,
        to: any,
        sort: string = 'alpha',
        full: boolean = true,
        sportName?: string,
        first: boolean = false,
    ): Promise<Observable<MatchData[]>> {
        let url = UtilsService.replace('sportId', sportId as string, URL_CONFIG.api.getMatchList);

        if (type === 'live') {
            if (SPORT_CONFIG.live.json.includes(sportName as string)) {
                url = UtilsService.replace('sportId', sportId as string, URL_CONFIG.api.getLiveMatchList);
            }
            from = TimeService.todayStartLocal();
            to = TimeService.todayEndLocal();
            full = true;
        }
        return this.lang.getLang().then(
            (iso): Observable<MatchData[]> => {
                if (type === 'live') {
                    url = UtilsService.replace('lang', iso as string, url);
                }
                const params = {
                    lang: iso,
                    type,
                    subtournamentIds: subTournamnetIds.join(','),
                    sort,
                    short: String(full ? 0 : 1),
                    from: TimeService.toUtc(from).format(TimeService.dateTimeFormat),
                    to: TimeService.toUtc(to).format(TimeService.dateTimeFormat),
                };
                const options = params ? { params } : {};
                const cacheKey: string = JSON.stringify({ ...params, sportId, type });
                if (type !== 'live' && first && this.store.isset(SPORT_CONFIG.matchMatchListCache)) {
                    const { key, val, created = moment().unix() } = this.store.get<
                    Record<'key' | 'val' | 'created', any>
                    >(SPORT_CONFIG.matchMatchListCache) as Record<'key' | 'val' | 'created', any>;

                    const actual = moment();

                    const diff = actual.diff(moment.unix(created), 'second');

                    if (key === cacheKey && diff < SPORT_CONFIG.matchMatchListCacheTimeout) {
                        return of(val) as Observable<MatchData[]>;
                    }
                }

                return this.http.get<MatchData[]>(url, options).pipe(
                    distinctUntilChanged(),
                    switchMap(this.map),
                    catchError(this.common.errorCallback2()), // then handle the error
                    tap((val) => {
                        if (val.length > 0) {
                            this.store.set(SPORT_CONFIG.matchMatchListCache, {
                                key: cacheKey,
                                val,
                                created: moment().unix(),
                            });
                        }
                        this.common.networkOnline()();
                    }),
                );
            },
            (): Promise<any> => Promise.reject(new Error()),
        );
    }

    /**
     * Emit data load
     * @fires TournamentService#loaded
     * @param  {number} tournamentId
     */
    public emitLoad(tournamentId: number): void {
        this.loaded.emit(tournamentId);
    }

    public mapCategory(sportId: number): (data: CategoryInterface[]) => Observable<CategoryInterface[]> {
        const vfavorite = this.favorite;
        return (data): Observable<CategoryInterface[]> => {
            if (_.isArray(data)) {
                data.sort((a, b): number => {
                    const aEx = vfavorite.existsTournament(sportId, a.tournament_id) ? 1 : 0;
                    const bEx = vfavorite.existsTournament(sportId, b.tournament_id) ? 1 : 0;
                    return bEx - aEx;
                });
            }
            return of(data);
        };
    }

    public map(data: any): Observable<any> {
        return of(data);
    }
}
