import { EventEmitter, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';

import { BannerObj, BannerPositionInterface } from '@interfaces/banner-position.interface';

import URL_CONFIG from '../config/url.config';
import { CommonService } from '../shared/common.service';


@Injectable({
    providedIn: 'root',
})
export class BannerService {
    public onLoad = new EventEmitter<boolean>();

    public isLoaded = false;

    public static isLoaded = false;

    public banners: BannerObj | null = null;

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private storeNgx: Store,
        private route: ActivatedRoute,
    ) {}

    public load(reload: boolean = false): void {
        if (!reload && BannerService.isLoaded) {
            return;
        }

        this.get().subscribe((val): void => {
            this.banners = val;
            this.isLoaded = true;
            BannerService.isLoaded = true;
            this.onLoad.emit();
        });
    }

    public get(): Observable<BannerObj> {
        const Url = URL_CONFIG.api.banners;

        const Partner = this.storeNgx.selectSnapshot<string>(
            state => (state.page?.partner && state.page?.partner?.name) || null,
        );

        const Options = Partner ? { params: new HttpParams().set('partnerName', Partner) } : {};

        return this.http.get<BannerPositionInterface[]>(Url, Options).pipe(
            distinctUntilChanged(),
            switchMap(this.map),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    private map(data: BannerPositionInterface[]): Observable<any> {
        return of(data);
    }
}
