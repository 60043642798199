
/**
 *  Base class for components
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { environment } from 'src/environments/environment';
// import * as _ from 'underscore';
import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input } from '@angular/core';

import APP_CONFIG from '../config/app.config';
import SPORT_CONFIG from '../config/sport.config';

import { MatchState } from '@/interfaces/match-data.interface';

@Directive()
export class BaseComponent {
    @Input()
    public sportName: string = '';

    @Input()
    public sportId: number;

    @Inject(DOCUMENT) private document: Document;

    public loadedError: boolean;

    public loaded: boolean;

    public cdn: string = environment.cdn;

    /**
     * Start load
     */
    protected start(): void {
        // document.body.style.cursor = 'progress';
    }

    /**
     * Reset load
     * @return {void}
     */
    protected reset(updateCursor: boolean = false): void {
        this.loadedError = false;
        this.loaded = false;
        if (updateCursor) {
            document.body.style.cursor = '';
        }
    }

    /**
     * Finish success
     * @return {void}
     */
    protected finished(updateCursor: boolean = false): void {
        this.loadedError = false;
        this.loaded = true;
        if (updateCursor) {
            document.body.style.cursor = '';
        }
    }

    get isLoading(): boolean {
        return !this.loaded;
    }

    /**
     * @listens BaseComponent#imageError
     * Listen to image error and load defautl image
     */
    public imageError(e: any, type = 'team'): void {
        const img = type === 'avatar' ? APP_CONFIG.defaultAvatar : APP_CONFIG.defaultLogo;
        e.target.src = img;
    }

    /**
     * Finish  with error
     * @return {void}
     */
    protected error(): void {
        this.loadedError = true;
        this.loaded = true;
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public isMatchAfter(matchState: MatchState): boolean {
        return matchState === MatchState.AFTER;
    }

    public isHockey(sportName: string): boolean {
        return SPORT_CONFIG.isHockey(sportName);
    }

    public isBasketballSport(sportName: string): boolean {
        return SPORT_CONFIG.isBasketball(sportName);
    }

    public isSoccer(sportName: string): boolean {
        return SPORT_CONFIG.isSoccer(sportName);
    }

    public isEsport(sportName: string): boolean {
        return SPORT_CONFIG.isEsport(sportName);
    }

    public isSnooker(sportName: string): boolean {
        return SPORT_CONFIG.isSnooker(sportName);
    }

    public isTennisSport(sportName: string): boolean {
        return SPORT_CONFIG.isTennis(sportName);
    }

    public isHandball(sportName: string): boolean {
        return SPORT_CONFIG.isHandball(sportName);
    }

    public isRugby(sportName: string): boolean {
        return SPORT_CONFIG.isRugby(sportName);
    }
}
