/**
 *  General App config
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

const CONFIG = {
    translatePath: 'translate/', // translate path in public root
    secret: 'ivansecret',
    defaultLang: 'en', // defautl fallback lang iso
    defaultLangImg: 'gb.svg', // defautl fallback lang img
    defaultClient: '24live',
    firstVisitKey: 'FIRST_VISIT', // wtich between frist visit
    introKey: 'INTRO', // itro key
    userUUIDkey: 'UUID_KEY',
    swNTFkey: 'WS_NTF_KEY', // service worker notification key
    wakeLockkey: 'WAKE_LOCK', // wake lock key storage
    pageReloadKey: 'PAGE_RELOAD_TIMES', // page roload info times
    pageReloadInfoMin: 2, // minimal manual page reloads to show notification
    missingTranskey: 'TRANS_MISSING_INDEX', // missing trans key
    defaultAvatar: '/img/default-avatar.svg',
    defaultLogo: '/img/default-logo.svg',
    logoPath: 'assets/participants/logos/',
    logoResponsivePath: 'assets/participants/logos-responsive/',
    brTrackerWidgetDomain: 'https://csdev.betradar.com',
    brTrackerClientId: '1aa725a84fa9fdc9b80c45004810a74d',
    /** Authentification and user* */
    authKey: 'authuser',
    authExpirationKey: 'authuserexpiration',
    authExpiration: 7200, // seconds
    matchListSlice: 30, // number of matches per slice
    matchListSlice2: 30, // number of matches per slice MOBIL
    matchListSlice3: 50, // number of matches per slice  DESKTOP
    matchListSlice4: 30, // number of matches <app-component-match></app-component-match>
    matchListSlice4Open: 1000, // open slice
    matchListSlice4TableTennis: 20, // number of matches <app-component-match></app-component-match> fot Table tennis
    recaptchaSiteKey: '6LdjkJ0UAAAAAE3rkigtzAp-wFX918ylnTTzGlkp',
    soundsPath: 'sounds/',
    /** Social plugins* */
    facebookAppId: '458176484808580',
    facebookAppIdDev: '458176484808580',
    linkedInAppId: '77wnmluvc6v0tc',
    googleClientId: '768818760475-vduc69obf9sifhet6oudgkb0uisn0el8.apps.googleusercontent.com',
    switchStickyKey: 'SWITCH_STICKY_MATCH_DETAIL',
    switchStickyKeyPageMode: 'SWITCH_STICKY_PAGE_MODE',
    switchStickyKeyMatchNotification: 'SWITCH_STICKY_MATCH_NOTIFICATION',
    defaults: {
        client: {
            codeName: '24live', // default client name
        },
        partner: {
            subType: null, // default partner subtype
        },
    },
};

export default CONFIG;
