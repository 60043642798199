/**
 *  Kiwibetconfiguration
 *  kiwibet
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2024 livescore
 */


import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: false,
        pagesInternal: false,
        cookie: false,
        oddsTab: false,
        share: true,
        closeIcon: false,
        winProbability: true,
        matchListAlpha: true,
        sportQuickNav: true,
        desktopLogo: false,
        betsStats: true,
        pageDarkMode: false,
        prematchH2HDefault: false,
        liveIcon: true,
        news: true,
        video: true,
        channels: true,
        map: true,
        canonicalPartner: false,
        partners: false,
        seoCard: true,
        opta: false,
        tracker: false,
        matchDetailStatistics: false,
        matchDetailPeriodStats: false,
        matchDetailLeagueTable: false,
        matchDetailPlayers: false,
        matchDetailH2H: false,
        matchDetailCuptree: false,
        matchDetailTopPlayers: false,
        matchDetailMatchActions: false,
        matchDetailCommentary: false,
        matchDetailForm: false,
        matchDetailProbability: false,
        matchDetailChannels: false,
        matchDetailEvents: false,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        useDefaultLogo: false,
        notification: true,
    },


    pages: {
        defaultMatchDetailSubPage: 'funfacts',
    },
    social: {

    },
    content: {
        betsImg: '/img/ball.svg',
        betsText: 'web.got_to_fortebet',
    },
    bet: {
        winApi: true,
        displayConfirm: true,
        isOddsLiveAndAfter: false,
    },

    colors: {
        icons: 'white',
    },
};

export default config;
