/**
 *  Pipe to setting default trans if not exists
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';

import ALT_TRANS from '../translate/alt-trans';

@Pipe({
    name: 'transinit',
})
export class TransInitPipe implements PipeTransform {
    public transform(value: any): any {
        if (!value) {
            return value;
        }
        type TRANS = keyof typeof ALT_TRANS;
        if (/\[\[.*\]\]/g.test(value) || /^(web|status|sport)\.(.+)$/.test(value)) {
            const key = value.replace(/(\[|\])/g, '');
            if (ALT_TRANS[key as TRANS]) {
                return ALT_TRANS[key as TRANS];
            }
        }

        return value;
    }
}
