<div>
    <div *ngIf="!success">
        <h2>{{ 'web.forgotten_password' | translate }}</h2>
        <form [formGroup]="form.getGroup()">
            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="form.getGroup().errors && (form.getGroup().touched || form.getGroup().dirty)"
            >
                {{ form.getErrorMessage(form.getGroup()) | translate }}
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.email' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'web.enter_email' | translate }}"
                            formControlName="email"
                            class="form-control form-control-lg"
                        />
                        <mat-error *ngIf="form.email.invalid">{{
                            form.getErrorMessage(form.email) | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 mb-5 pt-2">
                    <ngx-recaptcha2
                        #captchaElem2
                        [siteKey]="siteKey"
                        (success)="handleSuccessRC($event)"
                        [useGlobalDomain]="false"
                        [hl]="lang.getLangSnapshot()"
                        [type]="'Checkbox'"
                        formControlName="recaptcha"
                    >
                    </ngx-recaptcha2>
                    <span class="text-danger" *ngIf="form.recaptcha.invalid">{{
                        form.getErrorMessage(form.recaptcha) | translate
                    }}</span>
                </div>
                <div class="col-12 align-self-end text-right">
                    <button
                        class="btn btn-primary btn-lg btn-block-xs-only"
                        [disabled]="form.getGroup().invalid"
                        (click)="send()"
                    >
                        {{ 'web.' + submitText | translate }}
                    </button>
                </div>
                <div class="col-6">
                    <button
                        class="btn btn-outline-secondary"
                        *ngIf="form.getGroup().errors !== null"
                        (click)="resetForm()"
                    >
                        {{ 'web.reset_form' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="success">
        <div class="row mt-4">
            <div class="col align-self-center text-center">
                <p class="badge-text-draw">
                    {{ 'web.password_resend' | translate }}
                    <br />
                    <i class="material-icons"> check_circle_outline </i>
                </p>
                <p class="text-secondary">{{ 'web.forgotten_password__success_text' | translate }}</p>

                <p>
                    <button class="btn btn-outline-secondary" (click)="action('close')" color="warn">
                        {{ 'web.close_modal' | translate }}
                    </button>
                </p>
            </div>
        </div>
    </div>

    <div class="mt-5">
        <i class="fas fa-chevron-left"></i>&nbsp;&nbsp;&nbsp;
        <a class="text-reset app-cursor" (click)="goToSignIn()">{{ 'web.sign_in' | translate }}</a>
    </div>
</div>
