/* eslint-disable @typescript-eslint/indent */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RoutingBlockGuard } from '@pages/guards/routing-block.guard';
import { MatchWidgetResolverService } from '@pages/page-sport/match-widget/match-widget-resolver.service';
// import { Observable, EMPTY } from 'rxjs';

// import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { Error404Component } from './pages/error404/error404.component';
// import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';

// import { VerifyPhoneUserComponent } from './components/user/verify-phone-user/verify-phone-user.component';
import { H2HResolverService } from './pages/page-sport/h2h/h2h-resolver.service';
import { H2HComponent } from './pages/page-sport/h2h/h2h.component';
import { ParticipantCompareResolverService } from './pages/page-sport/participant-compare/participant-compare.service';
// import { FirstPageResolverService } from './pages/homepage/first-page-resolver.service';

const APP_ROUTES: Routes = [
    {
        path: 'hp',
        redirectTo: '/page/sport/match-list/soccer-5',
        pathMatch: 'full',

    },
    {
        path: 'match-widget/:id',
        loadChildren: (): any => import('./pages/page-sport/match-widget/match-widget.module')
            .then((m): any => m.MatchWidgetModule),
        resolve: {
            br: MatchWidgetResolverService,
        },
        canDeactivate: [RoutingBlockGuard],
    },
        {
        path: 'widget',
        loadChildren: (): any => import('./pages/page-sport/widget/widget.module')
            .then((m): any => m.WidgetModule),
    },
    {
        path: 'compare/:sport/:first/:second',
        loadChildren: (): any => import('./pages/page-sport/participant-compare/participant-compare.module')
            .then((m): any => m.ParticipantCompareModule),
        resolve: {
            participants: ParticipantCompareResolverService,
        },
    },
    {
        path: 'h2h/:matchId',
        component: H2HComponent,
        resolve: {
            br: H2HResolverService,
        },
        canDeactivate: [],
    },
    {
        path: 'verify-user/:code',
                loadChildren: (): any => import('./pages/verify-user/verify-user.module')
            .then((m): any => m.VerifyUserModule),
        data: { animation: 'VerifyUser' },
    },
    // {
    //     path: 'verify-phone-user',
    //     component: VerifyPhoneUserComponent,
    //     data: { animation: 'VerifyPhoneUser' },
    // },

    {
        path: 'reset-password/:code',
          loadChildren: (): any => import('./pages/reset-password/reset-password.module')
            .then((m): any => m.ResetPasswordModule),
        data: { animation: 'ResetPassword' },
    },
    {
        path: 'page',
        loadChildren: (): any => import('./pages/pages.module').then((m): any => m.PagesModule),
    },
    {
        path: 'statistics',
        loadChildren: (): any => import('./modules/statistics/statistics.module').then((m): any => m.StatisticsModule),
        data: {
            preload: false,
        },
    },


    // {
    //     path: 'branch',
    //     loadChildren: (): any => import('./modules/branch/branch.module').then((m): any => m.BranchModule),
    //     data: {
    //         preload: false,
    //     },
    // },
    {
        path: 'widgets',
        loadChildren: (): any => import('./modules/widgets/widgets.module').then((m): any => m.WidgetsModule),
        data: {
            preload: false,
        },
    },
    {
        path: '',
        redirectTo: '/page/sport/match-list/soccer-5',
        pathMatch: 'full',
    },
    { path: '404', component: Error404Component },
    { path: '**', component: Error404Component },
];
// @Injectable({ providedIn: 'root' })
// export class CustomPreloadingStrategy implements PreloadingStrategy {
//     public preload(route: Route, load: () => Observable<any>): Observable<any> {
//         if (this.shouldPreload()) {
//             return load();
//         }
//         return EMPTY;
//     }

//     private shouldPreload(): boolean {
//         // eslint-disable-next-line no-extra-parens
//         const conn = (navigator as any).connection;
//         if (conn) {
//             if (conn.saveData) {
//                 return false;
//             }
//             const effectiveType = conn.effectiveType || '';
//             // 2G, 3G network
//             if (['2g', '3g'].includes(effectiveType)) {
//                 return false;
//             }
//         }
//         return true;
//     }
// }

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
