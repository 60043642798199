import { BannerInterface } from 'src/app/interfaces/banner.interface';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ChangeDetectorRef } from '@angular/core';

import { WindowRefService } from '@shared/window-ref';

import { BaseComponent } from '../base.component';

/**
 *  Abstract class
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */

export abstract class BannerBase extends BaseComponent {
    public bannerPositionId: number|null = null;

    public banner: BannerInterface | null = null;


    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super();
    }

    public setBanner(banner: BannerInterface | null): void {
        this.banner = banner;

        this.ref.markForCheck();
    }

    public click(): void {
        this.win.nativeWindow.dataLayer.push({
            event: 'click_banner',
            banner_id: this.bannerPositionId,
            banner_name: this.banner?.comment,
        });
    }

    public utm(): string {
        let q = '';

        if (this.banner!.utm_campaign !== null) {
            q += `utm_campaign=${this.banner!.utm_campaign}&`;
        }
        if (this.banner!.utm_medium !== null) {
            q += `utm_medium=${this.banner!.utm_medium}&`;
        }
        if (this.banner!.utm_source !== null) {
            q += `utm_source=${this.banner!.utm_source}&`;
        }
        return q.substring(0, q.length - 1);
    }
}
