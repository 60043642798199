/*
 * location ref
 */
import { Injectable } from '@angular/core';

function getLocation(): any {
    // eslint-disable-next-line no-restricted-globals
    return location;
}

@Injectable({
    providedIn: 'root',
})
export class LocationRefService {
    // eslint-disable-next-line no-restricted-globals
    public get nativeLocation(): typeof location {
        return getLocation();
    }
}
