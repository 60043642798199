/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Base HTTP interceptor
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        // const duplicate = req.clone({ params: req.params.set('filter', 'completed') });
        // return next.handle(duplicate);
        const authReq = req.clone({
            // Prevent caching in IE, in particular IE11.
            // See: https://support.microsoft.com/en-us/help/234067/how-to-prevent-caching-in-internet-explorer
            setHeaders: {
                // 'Cache-Control': 'no-cache',
                // Pragma: 'no-cache',
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        return next.handle(authReq).pipe(
            tap((evt): void => {
                if (evt instanceof HttpResponse) {
                    // console.log('---> status:', evt.status);
                    // console.log('---> filter:', req.params.get('filter'));
                }
            }),
        );
    }
}
