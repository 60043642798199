/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/**
 *  Service for common tasks
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';

import { FormErrorInterface } from '../interfaces/form-response.interface';
import { NetworkStatus } from '../store/actions/page.action';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public constructor(private store: Store, private router: Router) {}

    /**
     * Error callback form validation
     * @param  {Response | any}         error
     * @return { Observable<FormErrorInterface> }
     */
    public errorFormCallback(
        error: Response | any,
    ): Observable<FormErrorInterface> {
        const { status } = error;

        if (status === 422) {
            const er = error.error.errors;
            return throwError({ control: er });
        }
        if (status === 406) {
            const g: any[] = [];
            error.error.errors.forEach((val: any): any => g.push(val));
            return throwError({ global: g });
        }

        return throwError({ global: ['httpError'] });
    }

    /**
     * Dispatch network status to online
     * @return {function}
     */
    public networkOnline(): () => void {
        return (): void => {
            this.store.dispatch(new NetworkStatus(1));
        };
    }

    /**
     * Error Callback for Observables server calls
     * @param  {Response | any}         error
     * @return { Observable }
     */
    public errorCallback(error: Response | any): Observable<never> {
        if (!environment.production) {
            console.log(error);
        }
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`,
            );
        }

        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    }

    /**
     * Error Callback for Observables server calls
     * @param  {Response | any}         error
     * @return { Observable }
     */
    public errorCallback2(): (error: Response | any) => Observable<any> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;

        return (error: Response | any): Observable<any> => {
            const { status } = error;

            if (!environment.production) {
                console.log(error);
            }
            if (error.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                console.error('An error occurred:', error.error.message);
            } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                console.error(
                    `Backend returned code ${error.status}, ` +
                        `body was: ${JSON.stringify(error.error)}`,
                );
            }

            if (status === 404) {
                _this.router.navigate(['/404']);
            } else {
                _this.store.dispatch(new NetworkStatus(0));
            }

            // return an observable with a user-facing error message
            return throwError(
                'Something bad happened; please try again later.',
            );
        };
    }

    /**
     * Return default ajax headers
     * @return { Object } headers
     *         { Headers } headers.headers
     */
    public ajaxHeaders(): Record<string, any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }),
        };
        return httpOptions;
    }
}
