/**
 *  Various system helpers
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
/* eslint-disable */
import { Injectable } from '@angular/core';
import * as _ from 'underscore';

declare const window: any;

@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    public static isTypeof<T>(value: any, type: 'string' | 'number' | 'function' | 'boolean' | 'object'): value is T {
        return value !== null && type === typeof value;
    }

    /**
     * Return deep copy of object
     * @param  {any} val
     * @return {any}
     */
    public static deepCopy(val: any) {
        return JSON.parse(JSON.stringify(val));
    }

    public static hasClass(el: Element, className: string): boolean {
        return el.classList.contains(className);
    }

    public static toggleClass(el: Element, className: string): void {
        el.classList.toggle(className);
    }
    
    public static addClass(el: HTMLElement | undefined, className: string): void {
        el?.classList.add(className);
    }

    public static pretty(data: Record<string, any>): string {
        return JSON.stringify(data, null, 2);
    }
    public static copyToClipboard(text: string): void {
        navigator.clipboard.writeText(text)
    }

    public static isDateValue(...val: [any]) {
        return !Number.isNaN(new Date(...val).valueOf());
    } 

    public static scrollPostiion(el = window): Record<'x' | 'y', number> {
        const x = el.pageXOffset !== undefined ? el.pageXOffset : el.scrollLeft;
        const y = el.pageYOffset !== undefined ? el.pageYOffset : el.scrollTop;
        return { x, y };
    }

    public static hasChild(parent: Element, child: Element): boolean {
        return parent !== child && parent.contains(child);
    }

    public static triggerEvent(el: HTMLElement, eventType: string, detail: CustomEventInit): void {
        el.dispatchEvent(new CustomEvent(eventType, { detail }));
    }

    public static removeEvent(el: HTMLElement, evt: string, fn: EventListenerOrEventListenerObject, opts = false): void {
        el.removeEventListener(evt, fn, opts);
    }

    public static randomColor(): string {
        return `#${Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")}`;
    }

    public static reverseString(str: string): string {
        return str.split('').reverse().join('');
    }

    /**
     * This example returns a random integer between the specified values. The value is no lower than min (or the next integer greater than min if min isn't an integer), and is less than (but not equal to) max.
     * @returns 
     */
    public static getRandomInt(min: number, max: number): number {
        const minCeiled = Math.ceil(min);
        const maxFloored = Math.floor(max);
        return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); 
    }


    /**
     * Get selected text
     * @returns {String}
     */
    public static getSelectedText(): string {
        return window.getSelection().toString();
    }

    /**
     * Check if element is active
     * @param el 
     * @returns {Boolean}
     */
    public static isActiveElement(el: Element): boolean {
        return el === document.activeElement;
    }

    public static isAppleDevice(): boolean {
        return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
    }

    public static clearAllCookies(): void {
        document.cookie.split(';').
            forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
    }

    public static celsiusToFahrenheit (celsius: number): number {
      return celsius * 9/5 + 32;  
    } 

    public static uniquerArray (ar:unknown[]): unknown[] {
      // @ts-ignore
      return [...new Set(ar)];  
    } 

    public static fahrenheitToCelsius(fahrenheit: number) {
        return  (fahrenheit - 32) * 5/9;
    }

    public static checkWebpFeature(feature = 'lossy', callback: (feature: string, result: boolean) => any) {
        let kTestImages: Record<string, any> = {
            lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
            lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
            alpha:
                'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
            animation:
                'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
        };
        var img = new Image();
        img.onload = function () {
            var result = img.width > 0 && img.height > 0;
            callback(feature, result);
        };
        img.onerror = function () {
            callback(feature, false);
        };
        img.src = 'data:image/webp;base64,' + kTestImages[feature];
    }

    /**
     * Replace string
     * @param  pattern      - what
     * @param  replacement  - replace string
     * @param  text         - source text
     * @return   {string}
     */
    public static replace(pattern: string | string[], replacement: string | string[], text: string): string {
        if (_.isArray(pattern)) {
            let regex;
            pattern.forEach((val, key) => {
                regex = new RegExp(`\{${val}\}`, 'gi');
                text = text.replace(regex, replacement[key]);
            });
            return text;
        }

        const regex = new RegExp(`\{${pattern}\}`, 'gi');
        return text.replace(regex, <string>replacement);
    }

    public static parseSportUrl(url: string): [number, string] {
        const [...sport] = url.split('-');
        const sportId: number = +sport.pop()!;
        const sportName: string = sport.join('-');
        return [sportId, sportName];
    }

    /**
     * Generate uniq token
     * @return {string}
     */
    public static createUUID(): string {
        let dt = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c): string => {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    }

    public static requestAnimFrame = (() => {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (/* function */ callback: () => void, /* DOMElement */ element: any) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    })();

    /**
     * Behaves the same as setTimeout except uses requestAnimationFrame() where possible for better performance
     * @param {function} fn The callback function
     * @param {number} delay The delay in milliseconds
     */

    public static requestTimeout(fn: () => any, delay: number): number {
        if (
            !window.requestAnimationFrame &&
            !window.webkitRequestAnimationFrame &&
            !(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
            !window.oRequestAnimationFrame &&
            !window.msRequestAnimationFrame
        )
            return window.setTimeout(fn, delay);

        var start = new Date().getTime(),
            handle = new Object() as any;

        function loop() {
            var current = new Date().getTime(),
                delta = current - start;
            // @ts-ignore
            delta >= delay ? fn.call() : (handle.value = UtilsService.requestAnimFrame(loop));
        }

        handle.value = UtilsService.requestAnimFrame(loop);
        return handle;
    }

    /**
     * Compare two arrays
     * @param a 
     * @param b 
     * @returns {Boolean}
     */
    public static compareArray(a: any[], b: any[]): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    public static isArray(obj: unknown): boolean {
        return Array.isArray(obj);
    }
    /**
     * Compare more objects
     * @param a 
     * @param b 
     * @returns {Boolean}
     */
    public static compareObjects(...objects: Record<string, any>[]): boolean {
        return  objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));
    }

    public static bytesToSize(bytes: number) {
        if (bytes === 0) return '0 B';

        var k = 1024;
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    }

    /**
     * Download any binary large object
     * @param fileName Download data
     * @param {ArrayBuffer} data
     */
    public static download<T extends ArrayBuffer>(fileName: string, data: T, type: string =  'application/pdf'): void {
        const blob = new Blob([data], { type });
        const element = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        element.href = url;
        element.download = fileName;
        element.target = '_blank';
        element.click();
        element.remove();
    }

    /**
     * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
     * @param {int|object} fn The callback function
     */
    public static clearRequestTimeout = function (handle: any): any {
        window.cancelAnimationFrame
            ? window.cancelAnimationFrame(handle.value)
            : window.webkitCancelAnimationFrame
            ? window.webkitCancelAnimationFrame(handle.value)
            : window.webkitCancelRequestAnimationFrame
            ? window.webkitCancelRequestAnimationFrame(handle.value) /* Support for legacy API */
            : window.mozCancelRequestAnimationFrame
            ? window.mozCancelRequestAnimationFrame(handle.value)
            : window.oCancelRequestAnimationFrame
            ? window.oCancelRequestAnimationFrame(handle.value)
            : window.msCancelRequestAnimationFrame
            ? window.msCancelRequestAnimationFrame(handle.value)
            : clearTimeout(handle);
    };

    public static activateFullscreen(element: any) {
        if (element.requestFullscreen) {
            element.requestFullscreen(); // W3C spec
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen(); // Firefox
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen(); // Safari
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen(); // IE/Edge
        }
    }

    /**
     * Deactive fullscreen
     */
    public static deactivateFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ('mozCancelFullScreen' in document) {
            (document as any).mozCancelFullScreen();
        } else if ('webkitExitFullscreen' in document) {
            (document as any).webkitExitFullscreen();
        }
    }

    /**
     * Check if fullscreen mode is enabled
     * @return boolean
     */
    public static isFullscreenEnabled(): boolean {
        return (
            document.fullscreenEnabled ||
            (document as any).mozFullScreenEnable ||
            (document as any).webkitFullscreenEnabled
        );
    }

    /**
     * Is in fullscreen mode
     * @return boolean
     */
    public static fullscreenElement(): boolean {
        return (
            document.fullscreenElement ||
            (document as any).mozFullScreenElemen ||
            (document as any).webkitFullscreenElement
        );
    }
}
