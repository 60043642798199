import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import * as _ from 'underscore';
import { Store } from '@ngxs/store';

import URL_CONFIG from '../config/url.config';
import BET_CONFIG from '../config/bet.config';
import { CommonService } from '../shared/common.service';
import {
    BetsMarketInterface,
    BetsInterface,
} from '../interfaces/bets-market.interface';

import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class BetsService {
    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private store: StorageService,
        private storeNgx: Store,
    ) {}

    /**
     * Get odds markets
     * @return {Observable<BetsMarketInterface[]>}
     */
    public getMarkets(): Observable<BetsMarketInterface[] | null> {
        const Url = URL_CONFIG.api.betMarkets;

        if (this.store.isset(BET_CONFIG.marketsKey)) {
            return of(
                this.store.get<BetsMarketInterface[]>(BET_CONFIG.marketsKey),
            );
        }

        return this.http.get<BetsMarketInterface[]>(Url).pipe(
            tap((val): void => {
                this.store.set(BET_CONFIG.marketsKey, val);
            }),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Get odds
     * @return {Observable<{[prop: number]: BetsInterface[]}>}
     */
    public getOdds(
        matchIds: number[],
        onlyMain: boolean = true,
        renew: boolean = false,
    ): Observable<{ [prop: number]: BetsInterface[] }> {
        const Url = URL_CONFIG.api.betOdds;
        const Found: Record<string, any> = {};
        const StoreKey = onlyMain
            ? BET_CONFIG.oddsKeyMain
            : BET_CONFIG.oddsKeyAll;

        const Partner = this.storeNgx.selectSnapshot<string>(
            state => (state.page.partner && state.page.partner.name) || null,
        );

        let options: Record<string, any> = {};

        if (this.store.isset(StoreKey) && !renew) {
            const Stored = this.store.get<{
                [prop: number]: BetsInterface[];
            }>(StoreKey);
            _.each(Stored as {
                [prop: number]: BetsInterface[];
            }, (val, key): void => {
                const Index = matchIds.findIndex(
                    (matchId): boolean => matchId === +key,
                );
                if (Index !== -1) {
                    Found[key] = val;
                    matchIds.splice(Index, 1);
                }
            });
        }

        let observable;
        if (matchIds.length === 0) {
            observable = of(Found);
        } else {
            const Params: Record<string, string | number> = {};
            const Om = 'only_main';
            Params[Om] = onlyMain ? 1 : 0;
            if (Partner !== null) {
                Params.partnerName = Partner;
            }
            options = {
                ...options,
                params: {
                    ...Params,
                    matchIds,
                },
            };
            observable = this.http.get<BetsInterface[]>(Url, options).pipe(
                map((val: any): {
                    [prop: number]: BetsInterface[];
                } => {
                    const Normalize = _.groupBy(
                        val,
                        (item: any): number => item.match_id,
                    );
                    this.store.set(StoreKey, Normalize);
                    return Normalize;
                }),
                catchError(this.common.errorCallback),
            ); // then handle the error
        }

        return observable;
    }
}
