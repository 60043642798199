/**
 *  Object to Array pipe
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'keys',
    pure: true,
})
export class KeysPipe implements PipeTransform {
    public transform(value: any): any {
        if (!value) {
            return value;
        }

        const keys: Record<string, any>[] = [];
        Object.keys(value).forEach((val): void => {
            keys.push({ key: val, value: value[val] });
        });
        return keys;
    }
}
