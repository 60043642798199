import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MyMatchesService } from '@/services/my-matches.service';

@Injectable({
    providedIn: 'root',
})
export class ParticipantCompareResolverService implements Resolve<number[] | null> {
    public constructor(public matches: MyMatchesService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<[number, number]> {
        const first = route.paramMap.get('first');
        const second = route.paramMap.get('second');

        return of([+first!, +second!]);
    }
}
