<div *ngIf="type == 'main'" class="d-flex">
    <ng-container *ngFor="let row of data">
        <ul class="list-unstyled col">
            <li *ngFor="let sport of row; index as i; trackBy: trackByFn">
                <a
                    class="dropdown-item icon--sport icon--{{ sport.code_name }}"
                    (click)="resetScroll()"
                    [routerLink]="this.getRouterArr(sport)"
                >
                    <img
                        src="{{ cdn }}/img/sports/{{ sport.code_name }}.png"
                        alt=" {{ 'sport.' + sport.code_name | translate }}"
                        loading="lazy"
                        height="24"
                        width="24"
                    />
                    {{ 'sport.' + sport.code_name | translate }}
                    <ng-container *ngIf="isDebug">
                        <br /><small class="d-block my-n1"
                            ><span class="text-danger">{{ sport.num_live_matches }}</span> /
                            {{ sport.num_matches }}</small
                        >
                    </ng-container>
                    <ng-container *ngIf="!isDebug && sport.num_live_matches > 0">
                        <div class="table-sport__cell--live app-word-nowrap">
                            {{ 'web.live' | translate }}
                        </div>
                    </ng-container>
                    <div *ngIf="isNew(sport.code_name)" class="table-sport__cell--new app-word-nowrap">
                        {{ 'web.new_sport' | translate }}
                    </div>
                </a>
            </li>
        </ul>
    </ng-container>
</div>

<nav *ngIf="type == 'mobile'" class="nav flex-column flex-nowrap w-100 bg-white">
    <ng-container *ngFor="let sport of dataRaw; index as i; trackBy: trackByFn">
        <a
            class="nav-link active"
            style="position: relative"
            (click)="resetScroll(); goTo()"
            [routerLink]="this.getRouterArr(sport)"
        >
            <img
                src="{{ cdn }}/img/sports/{{ sport.code_name }}.png"
                [alt]="'sport.' + sport.code_name | translate"
                loading="lazy"
                class="mr-2"
                height="28"
                width="28"
            />
            {{ 'sport.' + sport.code_name | translate }}
            <ng-container *ngIf="isDebug">
                <span class="float-right ml-auto"
                    ><span class="text-danger">{{ sport.num_live_matches }}</span> / {{ sport.num_matches }}</span
                >
            </ng-container>
            <div *ngIf="isNew(sport.code_name)" class="table-sport__cell--new">
                {{ 'web.new_sport' | translate }}
            </div>
            <ng-container *ngIf="!isDebug && sport.num_live_matches > 0">
                <div
                    class="float-right ml-aut table-sport__cell--live"
                    style="max-width: fit-content; position: absolute; top: 50%; right: 4%; transform: translateY(-50%)"
                >
                    {{ 'web.live' | translate }}
                </div>
            </ng-container>
        </a>
    </ng-container>
</nav>

<ng-container *ngIf="type == 'aside'">
    <a
        class="nav-link"
        data-toggle="tooltip"
        data-placement="right"
        [attr.data-cy]="sport.code_name"
        title="{{ 'sport.' + sport.code_name | translate }}"
        [class.active]="sport.id == sportId"
        *ngFor="let sport of dataRaw | slice : 0 : limit; index as i; trackBy: trackByFn"
        [ngStyle]="{ 'pointer-events': disabled ? 'none' : null }"
        (click)="resetScroll()"
        [routerLink]="this.getRouterArr(sport)"
    >
        <img
            src="{{ cdn }}/img/sports/{{ sport.code_name }}.png"
            alt="{{ 'sport.' + sport.code_name | translate }}"
            loading="lazy"
            width="24"
            height="24"
        />
        <span class="sr-only">{{ 'sport.' + sport.code_name | translate }}</span></a
    >
</ng-container>
