/**
 *  Search navigation
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { FavoriteService } from 'src/app/services/favorite.service';
import SPORT_CONFIG from 'src/app/config/sport.config';

@Pipe({
    name: 'navSearch',
})
export class NaviSearchPipe implements PipeTransform {
    public constructor(private favorite: FavoriteService) {}

    public transform(
        value: any,
        type: string,
        text: any,
        sportId?: number,
        sportName?: string,
        limit?: number,
    ): any {
        let fce;
        let isObject = false;
        if (_.isArray(value) && value.length > 0 && _.has(value[0], 'value')) {
            isObject = true;
        }

        if (type === 'fulltext') {
            if (typeof text === 'undefined' || text.length < 3) {
                if (!_.isUndefined(limit) && !_.isNull(limit) && value) {
                    return value.slice(0, limit);
                }

                return value;
            }
            text = text.toLowerCase();
            const globalRegex = RegExp(text, 'g');
            fce = (v: any): boolean => {
                const t = v.name.toLowerCase();
                return t.match(globalRegex);
            };
        } else if (type === 'alpha') {
            if (typeof text === 'undefined') {
                return value;
            }
            fce = (v: any): boolean => {
                let t: string = '';
                let star = true;
                if (isObject) {
                    const [first] = v.value;
                    v = first;
                }

                if (
                    _.has(v, 'sub_tournament_name') &&
                    !_.isUndefined(sportName) &&
                    SPORT_CONFIG.isSet(sportName as string) &&
                    v.sub_tournament_name !== null
                ) {
                    t = v.sub_tournament_name.toLowerCase().charAt(0);
                } else if (
                    _.has(v, 'category_name') &&
                    v.category_name !== null
                ) {
                    t = v.category_name.toLowerCase().charAt(0);
                } else if (v.name && v.name !== null) {
                    t = v.name.toLowerCase().charAt(0);
                }

                if (
                    !_.isUndefined(text.star) &&
                    text.star === true
                ) {
                    star = this.favorite.existsTournament(
                        sportId as number,
                        v.tournament_id,
                    );
                }

                return (
                    (text.alpha.length === 0 ||
                        text.alpha.indexOf(t) !== -1) &&
                    star
                );
            };
        }

        return value.filter(fce);
    }
}
