/* eslint-disable import/order */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseInterceptor } from 'src/app/services/interceptors/base.interceptor';
import { RouterModule } from '@angular/router';

import { ShareButtonsModule } from '@ngx-share/buttons';
import { ShareModule } from '@ngx-share/core';

import { SwiperModule } from 'swiper/angular';

// import { QuicklinkModule } from 'ngx-quicklink';

import { NgxParallaxScrollModule } from 'ngx-parallax-scroll';

/** Social plugin* */
// import {

//     AuthServiceConfig,
//     GoogleLoginProvider,
//     FacebookLoginProvider,
// } from 'angularx-social-login';

/** Material design modules* */
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';


/** End Material design modules* */

import { NgxCaptchaModule } from 'ngx-captcha';

import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PageLoaderComponent } from '@/components/loader/page/page.component';

import { TrackerComponent } from '../components/tracker/tracker.component';
import { LangComponent } from '../components/lang/lang.component';


import { CustomTranslateLoaderService } from './translate/custom-translate-loader.service';
import { CustomMissingTranslationHandler } from './translate/custom-missing-translate-loader.service';

/** Custom Pipes* */
import { LiveDatePipe } from './pipes/live-datetime.pipe';
import { SoccerPipe } from './pipes/score.pipe';
import { KeysPipe } from './pipes/key.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { HtmlSafePipe } from './pipes/html-safe.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NaviSearchPipe } from './pipes/nav-search.pipe';
import { OrderOddsPipe } from './pipes/order-odds.pipe';
import { ResponsivePipe } from './pipes/responsive.pipe';
import { SportSortPipe } from './pipes/sport-sort.pipe';
import { AppNumberPipe } from './pipes/number.pipe';
import { TransInitPipe } from './pipes/trans-init.pipe';
import { UniversalPipe } from './pipes/universal.pipe';
import { AlphaColorPipe } from './pipes/alpha-color.pipe';
import { RenameSportNavPipe } from './pipes/rename-sport-nav.pipe';
import { StatsValPipe } from './pipes/stats-val.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
/** End Custom Pipes* */


import { IntersectionDirective } from './directives/intersection.directive';
import { LeagueTableSortPipe } from './pipes/league-table-sort.pipe';
import { ScrollEndDirective } from './directives/scroll.directive';
import { ParticipanStatsDirective } from './directives/participant-stats.directive';
import { SearchTransferPipe } from './pipes/search-transfer.pipe';
import { Nl2BrPipe } from './pipes/nl2br.pipe';


// const fbAppId = environment.production ? APP_CONFIG.facebookAppIdDev : APP_CONFIG.facebookAppIdDev;

// const socConfig = new AuthServiceConfig([
//     {
//         id: GoogleLoginProvider.PROVIDER_ID,
//         provider: new GoogleLoginProvider(APP_CONFIG.googleClientId),
//     },
//     {
//         id: FacebookLoginProvider.PROVIDER_ID,
//         provider: new FacebookLoginProvider(fbAppId),
//     },
// ]);

// export function provideConfig(): AuthServiceConfig {
//     return socConfig;
// }

@NgModule({
    declarations: [
        PageLoaderComponent,
        LiveDatePipe,
        SoccerPipe,
        KeysPipe,
        NaviSearchPipe,
        TruncatePipe,
        OrderOddsPipe,
        ResponsivePipe,
        AppNumberPipe,
        LeagueTableSortPipe,
        SafePipe,
        HtmlSafePipe,
        TransInitPipe,
        SportSortPipe,
        UniversalPipe,
        AlphaColorPipe,
        LangComponent,
        TrackerComponent,
        IntersectionDirective,
        ScrollEndDirective,
        RenameSportNavPipe,
        StatsValPipe,
        SearchTransferPipe,
        ParticipanStatsDirective,
        Nl2BrPipe,
        MarkdownPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        ShareModule,
        ShareButtonsModule,
        MatTooltipModule,


        MatDialogModule,
        MatInputModule,

        MatIconModule,


        NgxCaptchaModule,
        SwiperModule,
        // QuicklinkModule,

        NgxParallaxScrollModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoaderService,
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler,
            },
            isolate: true,
        }),
    ],
    exports: [
        LangComponent,

        ReactiveFormsModule,
        CommonModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        TranslateModule,
        LiveDatePipe,
        SoccerPipe,
        UniversalPipe,
        NaviSearchPipe,
        PageLoaderComponent,
        TransInitPipe,
        TruncatePipe,
        OrderOddsPipe,
        ResponsivePipe,
        AppNumberPipe,
        SportSortPipe,
        LeagueTableSortPipe,
        KeysPipe,
        SafePipe,
        HtmlSafePipe,
        MatTooltipModule,
        ShareModule,
        ShareButtonsModule,

        MatDialogModule,
        MatInputModule,

        MatIconModule,


        NgxCaptchaModule,

        Nl2BrPipe,


        // QuicklinkModule,

        SwiperModule,
        TrackerComponent,
        IntersectionDirective,
        ParticipanStatsDirective,
        AlphaColorPipe,
        ScrollEndDirective,
        NgxParallaxScrollModule,
        RenameSportNavPipe,
        StatsValPipe,
        SearchTransferPipe,
        MarkdownPipe,
    ],
    providers: [
        CustomTranslateLoaderService,

        { provide: HTTP_INTERCEPTORS, useClass: BaseInterceptor, multi: true },

        // {
        //     provide: AuthServiceConfig,
        //     useFactory: provideConfig,
        // },
    ],
})
export class SharedModule {}
