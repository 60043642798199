/* eslint-disable camelcase */
import { Score } from './score.interface';
import { TennisRankingInterface } from './tennis-rankings.interface';
import { BetsInterface } from './bets-market.interface';

export enum MatchState {
    BEFORE = 'before',
    AFTER = 'after',
    INPLAY = 'inplay-live',
    CANCELED = 'cancelled',
    INPLAYPAUSE = 'inplay-pause',
}

export enum CodeState {
    AP = 'ap',
    AET = 'aet',
    NOTSTARTED = 'not_started',
    ENDED = 'ended',
    WALKOVER = 'walkover',
    RETIRED = 'retired',
    POSTPONED = 'postponed',
    PENALTIES = 'penalties',
}

export enum RefereeType {
    BASIC = 'referee',
    MAIN = 'main_referee',
    FIRST_ASSISTANT = 'first_assistant_referee',
    SECOND_ASSISTANT = 'second_assistant_referee',
    VIDEO_ASSISTANT = 'video_assistant_referee',
    FOURTH_OFFICIAL = 'fourth_official',

}

export enum HockeyPosition {
    GOALIE = 'goalie',
    LEFTBACK = 'left back',
    RIGHTBACK = 'right back',
    LEFTWINK = 'left wink',
    CENTER = 'center',
    RIGHTWINK = 'right wink',
}

export enum Periods {
    AP = 'ap',
    AET = 'aet',
    PENALTIES = 'penalties',
    PERIOD1 = 'period_1',
    PERIOD2 = 'period_2',
    PERIOD3 = 'period_2',
    PERIOD4 = 'period_2',
    PERIOD5 = 'period_2',
    PERIOD6 = 'period_2',
    PERIOD7 = 'period_2',
    PERIOD8 = 'period_2',
    PERIOD9 = 'period_2',
    PERIOD10 = 'period_2',
    PERIOD11 = 'period_2',
    PERIOD12 = 'period_2',
    PERIOD13 = 'period_2',
    PERIOD14 = 'period_2',
    OVERTIME = 'overtime',
    INTERRUPTED = 'interrupted',
}

export interface StatsTournament {
    readonly goals_for_team: number | null;
    readonly goals_in_tournament: number | null;
    readonly assists_for_team: number | null;
    readonly matches_played_for_team: number | null;
    readonly minutes_played: number | null;
    readonly name: string;
    readonly red_cards_for_team: number | null;
    readonly tournament_id: number;
    readonly tournament_name: string;
    readonly tournamentOrder: number;
    readonly yellow_cards_for_team: number | null;

    readonly assists: string | null;
    readonly goals_scored: string | null;
    readonly matches_played: string | null;
    readonly red_cards: string | null;
    readonly yellow_cards: string | null;
}

export enum MatchResult {
    WIN = 'win',
    LOSE = 'lose',
    DRAW = 'draw',
}

export enum Winner {
    NOT_SET = 0,
    HOME = 1,
    AWAY = 2
}

export type PeriodKeys =
    'score_period_1' | 'score_period_2' | 'score_period_3' | 'score_period_4' | 'score_normal_time';

export type MatchDetailDisplayMode = 'all' | 'table' | 'h2h' | 'overview' |
'statistics' | 'statistics-extended' | 'tracker' | 'video' | 'cuptree' | 'lineup' | 'lineup-opta' | 'news';

export type H2HlDisplayMode = 'widget';

export type PlayerStatsInfo = keyof StatsTournament;

/**
 *  Match data interfaces
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */

export interface MatchCommentary {
    source_id: number;
    time: number;
    message: string;
}

export interface Probability {
    name: string;
    outcomes: {name: string; probability: number}[];
}

export type TimelineType = 'deciding_team' | 'match_called' | 'period_score' | 'match_ended' | 'score_change'
| 'point' | 'match_started' | 'period_start' | 'first_serve';
type TennisResultIndex = 'server' | 'receiver';
type TennisResultIndex2 = 'won' | 'lost';

export type TennisResult = `${TennisResultIndex}_${TennisResultIndex2}` | 'double_fault' | 'ace';

export type TennisMapFce = Record<string, Record<string, any>[] | boolean>;
export type DartsMapFce = Record<string, any>;
export type DefaultMapFce = Record<string, Record<string, any>[]>;

export interface PlayerStatistics {
    home: {[key: number]: {player: {name: string, id: number}, statistics: Record<string, number | string> }},
    away: {[key: number]: {player: {name: string, id: number}, statistics: Record<string, number | string> }},
}

export interface PossibleGoal {
    id: number;
    type: 'possible_goal';
    time: string;
}

export interface PeriodStatistics {

    barons: {home: string, away: string};
    dragons: {home: string, away: string};
    gold: {home: string, away: string};
    inhibitors: {home: string, away: string};
    kills: {home: string, away: string};
    towers: {home: string, away: string};

}
export interface Timeline {
    readonly id: number | null;
    readonly source_id?: number | null;
    readonly away_score: number | null;
    readonly break_name: string | null;
    competitor: 'home' | 'away' | null;
    readonly first_serve_fault: boolean | null;
    readonly home_score: number | null;
    homeScoreDiff?: number | null;
    awayScoreDiff?: number | null;
    readonly last_point_result: string | null;
    readonly match_clock: string | null;
    readonly away_match_timescore: string | null;
    readonly period: number | null;
    period_name: string | null;
    readonly match_time?: string;
    frame_number: number;
    frame_score: string;
    frameScoreHome?: number;
    frameScoreAway?: number;
    readonly players: any[] | null;
    mainPlayers: Record<string, any>[];
    assistsPlayers: Record<string, any>[];
    suspensionPlayers: Record<string, any>[];
    fouledPlayers: Record<string, any>[];
    substitutionPlayers: { in: string[]; out: string[]; };
    readonly points: any | null;
    readonly reason: string | null;
    readonly result: TennisResult | null;
    readonly server: 'home' | 'away' | null;
    readonly serving: 'home' | 'away' | null;
    readonly suspension_minutes: number | null;
    readonly tie_break: boolean | null;
    readonly number_of_red_balls?: number;
    readonly time: string | null;
    type: TimelineType | string | null;
    readonly x: number | null;
    readonly y: number | null;
    readonly inning?: number;
    readonly ball_number?: number;
    readonly over_number?: number;
}

export interface MatchSimple {
    readonly id: number;
    readonly date: string;
    readonly home_team: string;
    readonly away_team: string;
    readonly home_image: string;
    readonly away_image: string;
    readonly tournament: string;
    readonly flag?: string;
    readonly score: Score;
    readonly badge: string;
    readonly badge_class: string;
    readonly code_state: string;
    readonly match_state: string;
}

export interface Channel {
    name: string;
    readonly url: string;
    readonly country: {flag: string | null; name: string | null, id: number};
}
export interface MatchMeta {
    tracker?: number;
    place?: string;
    stadium?: string;
    stadium_capacity?: number;
    attendance?: number;
    referee?: string;
    referees?: {name: string; type: RefereeType;nationality: string;iso: string}[];
    referees_text?: string | null;
    weather?: string;
    stadium_coords?: string;
}

export interface Phrases {
    id: number;
    match_action_id?: number;
    time: string | null;
    text: string;
    type: {id: number; description: string};
    event_type: {id: number; name: string; description: string};
}

export interface MatchActionScoreItem {
    type: 'GOAL';
    team: 'home' | 'Away';
    state: Periods;
    time: string;
    addedTime: string;
    image?: string;
    name?: string;
    subs_name: string[];
    points?: number;
    actual_score?: string;
}
export interface MatchActionsScore {
    period: { name: string };
    actions: MatchActionScoreItem[];
}


export interface MatchActionItem {
    state: Periods;
    time: string;
    image?: string;
    name?: string;
    subs_name: string[];
    points?: number;
    actual_score?: string;
}
export interface MatchActions {
    period: { name: string };
    actions: MatchActionItem[];
}

export type TotalStatistics =
    {home: Record<string, number | string>, away: Record<string, number | string>,
        context?:Record<string, any>};

export interface H2H {
    limit: number;
    sportName?: string;
    total: {
        home_team: MatchSimple[];
        away_team: MatchSimple[];
        h2h: MatchSimple[];
    };
    home: { home_team: MatchSimple[]; h2h: MatchSimple[] };
    away: { away_team: MatchSimple[]; h2h: MatchSimple[] };
}


export interface Jersey {
    base: string;
    horizontalStripes: boolean;
    number: string;
    shirtType: string;
    sleeve: string;
    sleeveDetail: string;
    split: boolean;
    squares: boolean;
    stripes: boolean;
    jerseyType: 'away' | 'home' | 'goalkeeper' | 'third';
}


export interface TennisTournamentInfo {
    name: string;
    short_name: string;
}
export interface Team {
    id: number;
    type: string;
    image?: string;
    countryImage?: string | null;
    name: string;
    name_short?: string;
    scratch: boolean;
    red_cards: number;
    formation?: string;
    cuptree_is_final?: boolean;
    table_position?: number;
    seed?: number;
    bracket_number?: number;
    tennis_tournament_info?: TennisTournamentInfo;
    jerseys?: Jersey[];
    seasonStats?: Record<string, number | string>;
    readonly lineup_string?: string;
    readonly bench_string?: string;

}

export type PlayerInfo = 'form' | 'age' | 'height' | 'nationality';

export interface Player {
    id: number;
    position: string;
    number: number;
    flag: string;
    name: string;
    name_short: string;
    age: number;
    height: number;
    injury: boolean;
    start_lineup: boolean;
    goals?: number;
    red_card?: number;
    yellow_card?: number;
    yellow_red_card?: number;
    assists?: number;
    lineupPosition?: number | string;
    matchPosition?: number | string;
    readonly formation?: string;
    readonly lineupFormation?: string;
    sort?: number;
    substitute: number;
    statistics?: any;
    team: string;
    role?: string;
    photo?: string;
    missing_reason?: string;
    missing_registered?: string;
    missing_expected_back?: string;
    missing_global?: boolean;
    statsTournament?: StatsTournament[];
}

export type PlayerExtend = Player & {home?: string; away?: string;}


export interface News {
    id: string;
    title: string;
    content: string;
    ext_link: string;
    datetime: string;
    image_original: string;
    image_small: string;
}

export interface SportEventCondition {
    weather_info: {
        rain_conditions: 'no_rain' | 'expected_soon' | 'showers' | 'rain' | 'heavy_rain' | 'unknown';
        sky_conditions: 'clear' | 'partly_cloudy' | 'cloudy' | 'overcast' | 'unknown';
        temperature_range: 'very_hot' | 'hot' | 'warm' | 'mild' | 'cold' | 'very_cold' | 'unknown';
        wind_conditions: 'no_wind' | 'breeze' | 'windy' | 'gale' | 'unknown';
    }
    pitch_info: {
        boundary_position: 'in' | 'normal' | 'unknown';
        grass_cover: 'barren' | 'normal' | 'green' | 'unknown';
        pitch_moisture: 'very_dry' | 'dry' | 'normal' | 'moist' | 'unknown';
        pitch_quality: 'normal' | 'delayed' | 'deteriorating' | 'slow' | 'low' | 'unknown';
    }
    outfield_info: {
        outfield_conditions: 'slow' | 'normal' | 'fast';
    }
    day_night: 'day' | 'night';
    type: 'test' | 'odi' | 't10' | 't20i' | 'first_class' | 'list_a' | 't20';
}

export interface MatchData<T = []> {
    id: number;
    category_id: number;
    category_name: string;
    image: string;
    sport_code_name: string;
    sport_id: number;
    sub_tournament_id: number;
    tournament_id: number;
    season_id?: number;
    start_date: string;
    minute?: string | null;
    added_time?: number;
    period_length: number;
    isLive: boolean;
    winner?: number;
    aggregate_winner?: number;
    display_time: boolean;
    last_time_update?: number;
    display_period_time?: boolean;
    last_update?: number;
    is_favorited?: boolean;
    code_state: CodeState | null;
    match_state: MatchState;
    hasTable: boolean;
    hasCupTree: boolean;
    liveTable?: boolean;
    hasPlayerStats?: boolean;
    metainfo?: MatchMeta;
    periodStatistics?: PeriodStatistics[];
    match_actions?: MatchActions[];
    match_actions_score?: MatchActionsScore[];
    h2h?: H2H;
    participants: T extends Team ? T : Team[];
    tracker?: number | null;
    round: number;
    round_name: string;
    info: string;
    participantStatistics: TotalStatistics,
    playerStatistics: PlayerStatistics,
    score: Score;
    players?: Player[];
    tennis_rankings?: TennisRankingInterface[];
    comments?: string[];
    funfacts?: string[];
    tournament_name: string;
    sub_tournament_name: string;
    odds?: BetsInterface;
    twitch_channel?: string;
    twitch_video?: string | number;
    youtube_video?: string;
    youtube_preview?: string | null;
    nhl_video?: string;
    isport_video?: string;
    vimeo_video?: string;
    tvcom_video?: string;
    phrases: Phrases[];
    news?: News[];
    possibleGoal?: PossibleGoal;
    live_timelines?: Timeline[];
    channels?: Channel[];
    weather?: Record<string, any>;
    stoppage_time_announced: string | null;
    opta_competition: string | number | null;
    opta_season: string | number | null;
    opta_match: string | number | null;
    probabilities: Probability[] | null;
    match_commentaries: MatchCommentary[];
    sport_event_conditions: SportEventCondition;
    innings?:Record<string, any>[];
    toss_won_by?: number;
    toss_decision?: 'bowl' | 'bat';

}
