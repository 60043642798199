/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { Select } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SearchComponent } from 'src/app/components/search/search.component';
import { UserState } from 'src/app/store/states/user.state';
import { Observable, of, from } from 'rxjs';
import * as $ from 'jquery';
import { NavCompetitionService } from 'src/app/services/nav-competitions.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { delay, filter, concatMap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FavoriteService } from 'src/app/services/favorite.service';

import { SportName } from '@interfaces/sport.interface';

import { WindowRefService } from '@shared/window-ref';

import { InfoWidgetComponent } from '../user/info-widget/info-widget.component';
import { BaseComponent } from '../base.component';

import { UserService } from '@/services/user.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnInit {
    private backButtonConnfig = ['/page/sport/match/', '/page/sport/participant/',
        '/page/sport/event/', '/page/sport/player/', '/compare'];

    @ViewChild(InfoWidgetComponent, { static: true })
    private infoComponent: InfoWidgetComponent;


    @Select(UserState.isLoggedIn) public isLoggedIn$: Observable<boolean>;

    @Input()
    public fullMenu = true;

    @Input()
    public sportMenu = true;

    @Input()
    public eventType = 'matches';

    @Input()
    public sportId: number;

    @Input()
    public date: string;

    @Input()
    public activeBackButton = false;

    @Input()
    public sportName: string;

    public userLoggedIn: any = {};

    public backButton: boolean = false;

    public lastUrl: string;

    public firstLoadNav = false;

    public loginStatus = false;

    public hideFavNav = false;

    @Input()
    public eventListLink: string[];

    @Input()
    public showCompetitionsMenu = true;


    public constructor(
        public client: ClientService,
        public dialog: MatDialog,
        private nav: NavCompetitionService,
        private route: ActivatedRoute,
        private router: Router,
        public deviceService: DeviceDetectorService,
        public location: Location,
        public favorite: FavoriteService,
        private win: WindowRefService,
        private user:UserService,
    ) {
        super();
    }


    public ngOnInit(): void {
        this.detectActualUrl(this.router.url);

        this.backButton = this.activeBackButton;
        this.router.events
            .pipe(filter((event): boolean => event instanceof NavigationEnd))
            .subscribe((event: any): void => {
                const user = this.user.fullUser;

                this.win.nativeWindow.dataLayer.push({
                    event: 'screen_view',
                    screen_sport: this.sportName,
                    path: event.urlAfterRedirects,
                    email: user?.email ?? null,
                    name: user?.name ?? null,

                });

                this.backButton = false;
                this.hideFavNav = false;
                this.detectActualUrl(event.urlAfterRedirects);
                this.backButtonConnfig.forEach((val): void => {
                    if (this.router.url.includes(val)) {
                        this.backButton = true;
                    }
                });
            });


        from(this.route.children)
            .pipe(concatMap((ch): any => ch.paramMap))
            .subscribe((): void => {
                this.firstLoadNav = false;
            });
        this.route.queryParamMap.pipe(delay(1000)).subscribe((param): void => {
            if (!this.loginStatus) {
                if (param.has('signin')) {
                    const rUrl = param.has('redirectUrl') ? param.get('redirectUrl') as string : undefined;
                    this.infoComponent.openModal('signin', rUrl);
                } else if (param.has('registration')) {
                    const rUrl = param.has('redirectUrl') ? param.get('redirectUrl') as string : undefined;
                    this.infoComponent.openModal('registration', rUrl);
                } else if (param.has('forgottenPassword')) {
                    const rUrl = param.has('redirectUrl') ? param.get('redirectUrl') as string : undefined;
                    this.infoComponent.openModal('forgottenPassword', rUrl);
                }
            }

            if (param.has('q')) {
                this.openSearchDialog(param.get('q'));
            }
        });

        this.isLoggedIn$.subscribe((val): void => {
            this.loginStatus = val;
            this.userLoggedIn = {};
            if (val) {
                $('#mob_nav_sports-tab').addClass('active');
                $('#mob_nav_sports').addClass('show active');
                $('#mob_nav_login').removeClass('active');
                this.userLoggedIn = {
                    'nav-item--user': true,
                    'nav-item--user--active': true,
                    'position-relative': true,
                };
            }
        });

        if (!this.eventListLink) {
            this.eventListLink = ['/page', 'sport', 'match-list'];
        }
    }

    get hasBackButton(): boolean {
        return this.backButton;
    }

    /**
     * Detect last active url
     * @param  {string} url
     * @return {void}
     */
    private detectActualUrl(url: string): void {
        this.fullMenu = true;

        if (url.includes('page/sport/match-list')) {
            this.lastUrl = 'match-list';
        } else if (url.includes('page/sport/event')) {
            this.lastUrl = 'event';
        } else if (url.includes('page/sport/participant')) {
            this.lastUrl = 'participant';
        } else if (url.includes('page/sport/my-matches')) {
            this.fullMenu = false;
            this.lastUrl = 'my-matches';
        } else if (url.startsWith('/hp')) {
            this.fullMenu = false;
        } else if (url.startsWith('/page/static')) {
            this.fullMenu = false;
        }
    }

    get isEventPage(): boolean {
        return this.lastUrl === 'event';
    }

    /**
     * on open reset all fitlers
     * @return {void}
     */
    public resetNav(e: MouseEvent | null, close = false): void {
        const delayT = this.firstLoadNav === false ? 100 : 0;
        if (!close) {
            this.firstLoadNav = true;
        }

        of(true)
            .pipe(delay(delayT))
            .subscribe((): void => {
                if (close) {
                    $('.dropdown-menu--megamenu').removeClass('dropdown-menu--megamenu--active');
                    $('body').removeClass('megamenu-open');
                    $('body').removeClass('lock-scroll');
                } else {
                    $('.dropdown-menu--megamenu').toggleClass('dropdown-menu--megamenu--active');
                    $('body').toggleClass('megamenu-open');
                    $('body').toggleClass('lock-scroll');
                }

                this.nav.reset();
                if (e) {
                    e.preventDefault();
                }
            });
    }

    /**
     * Handle mobile sign in event
     * @event SigninComponent#onChange
     * @param {string} action
     */
    public mobileSign(action: string): void {
        if (action === 'registration') {
            this.closeNavBarMobile();
        }
        this.closeNavBarMobile();
    }

    public goToHp(): void {
        this.closeNavBarMobile();
        this.router.navigate(['/hp']);
    }

    public closeNavBarMobile(): void {
        $('#navbar-mobile').removeClass('collapse show');
        $('.overlay').remove();
        $('body').removeClass('lock-scroll');
        this.isMobileNavOpen = false;
        this.resetNav(null, true);
    }

    get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    get isMobilMenuAvailable(): boolean {
        const mql = (window as any).matchMedia('(max-width: 990px)');
        return this.isMobile || mql.matches;
    }

    public isMobileNavOpen = false;

    public openNavBarMobile(): void {
        $('.overlay').remove();
        const overlay = $('<div class="overlay" style=""></div>');
        overlay.appendTo('body').on('click', (): void => {
            this.closeNavBarMobile();
        });

        $('body').addClass('lock-scroll');
        this.isMobileNavOpen = true;
    }

    public closeModal(): void {
        window.top?.postMessage({ closeModal: true }, '*');
    }

    public openSearchDialog(q: string | null = null): void {
        let options = {
            maxWidth: '94%',
            width: '94%',
            data: { q },
        };
        if (!this.deviceService.isMobile()) {
            options = {
                maxWidth: '60%',
                width: '60%',
                data: { q },
            };
        }

        const dialogRef = this.dialog.open(SearchComponent, options);

        dialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }

    public goBack(): void {
        this.location.back();
    }

    onNavLoad({ data }: Record<string, any>): void {
        this.hideFavNav = !data || data.length === 0 || this.sportName === SportName.GOLF;
    }
}
