/* eslint-disable camelcase */
/**
 *  List All Sports [GET /api/get-sports]
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */


export interface SportInterface {
    id: number;
    image: string;
    code_name: string;
    num_matches: number;
    num_live_matches: number;
}


export enum SportName {
    GOLF = 'golf',
    MOTOSPORT = 'motor-sport',
}
