/**
 *  Format ISO 8601 date to moment
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform, Injectable } from '@angular/core';

import { TimeService } from '../../services/time.service';

declare const moment: any;

@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'livDate',
})
export class LiveDatePipe implements PipeTransform {
    public transform(value: any, args?: string, locale?: string): any {
        let format = TimeService.timeFormatFront;

        const date = moment(value);


        if (!date.isValid()) {
            return value;
        }

        const dateObject = date.toDate();
        const lang = navigator.language;
        const dateOptions: Intl.DateTimeFormatOptions = {
            weekday: undefined,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };
        const dateOptions2: Intl.DateTimeFormatOptions = {
            weekday: undefined,
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        };
        const dateOptionsShort: Intl.DateTimeFormatOptions = {
            weekday: undefined,
            year: undefined,
            month: '2-digit',
            day: '2-digit',
        };
        const timeOptions: Intl.DateTimeFormatOptions = {
            minute: '2-digit',
            hour: '2-digit',
            second: undefined,

        };
        switch (args) {
            case 'localDateTime':
                return `${dateObject.toLocaleDateString(lang, dateOptions).replace(/\s+/g, '')}
                ${dateObject.toLocaleTimeString(lang, timeOptions)}`;
                break;
            case 'localDateTimeShort':
                return `${dateObject.toLocaleDateString(lang, dateOptionsShort).replace(/\s+/g, '')}
                ${dateObject.toLocaleTimeString(lang, timeOptions).replace(/\s+/g, '')}`;
                break;
            case 'localDate':
                return `${dateObject.toLocaleDateString(lang, dateOptions2).replace(/\s+/g, '')}`;
                break;
            case 'localTime':
                return `
                ${dateObject.toLocaleTimeString(lang, timeOptions)}`;
                break;
            case 'localDateShort':
                return `
                ${dateObject.toLocaleDateString(lang, dateOptionsShort).replace(/\s+/g, '')}`;
                break;
            case 'time':
                format = TimeService.timeFormatFront;
                break;
            case 'datetime':
                format = TimeService.dateTimeFormatFront;
                break;
            case 'calendar':
                format = TimeService.dateTimeFormatCalendar;
                break;
            case 'datetime3':
                format = TimeService.dateTimeFormatFront2;
                break;
            case 'datetime2':
                format = TimeService.dateTimeFormat2Front;
                break;
            case 'date':
                format = TimeService.dateFormatFront;
                break;
            case 'datesep':
                format = TimeService.dateFormatSepFront;
                break;
            case 'dateShort':
                format = TimeService.dateFormatFrontShort;
                break;
            case 'dateShort2':
                format = TimeService.dateFormatFrontShort2;
                break;
            case 'datedb':
                format = TimeService.dateFormat;
                break;
            case 'birthdate':
                // no utc, keep in same timezone as client
                return TimeService.utcToLocal(moment(value)).format(TimeService.dateFormatFront);
                break;
            case 'age':
                // no utc, keep in same timezone as client
                return moment().diff(moment(value), 'years', false);
                break;
            default:
                format = TimeService.dateTimeFormatFront;
        }

        const dateSet = moment.utc(value);
        if (locale) {
            dateSet.locale(locale);
        }
        return TimeService.utcToLocal(dateSet).format(format);
    }
}
