<app-header [fullMenu]="false"></app-header>

<main role="main">
    <div class="container position-relative page-404">
        <div class="text-center mb-0 py-4 py-md-7 px-5 px-md-0">
            <div class="mb-4">&nbsp;</div>
            <span class="text-primary text-uppercase font-weight-bold">{{ 'web.page_not_found' | translate }}</span>
            <h1 class="text-uppercase font-weight-extrabold">{{ 'web.page_not_found_text' | translate }}</h1>
            <p class="lead font-weight-normal mb-5">&nbsp;</p>
            <img src="/img/404.png" alt="404" class="img-fluid" width="420" />
        </div>
    </div>
</main>

<app-footer></app-footer>
