/**
 *  Base service vlass
 *  @author Livescore <score-stats.com>
 *  @copyright 2019 livescore
 */

import { EventEmitter } from '@angular/core';

export class BaseService {
    public loaded: EventEmitter<number> = new EventEmitter();

    /**
   * Emit data load
   * @fires BaseService#loaded
   * @param  {number} id
   */
    public emitLoad(id: number): void {
        this.loaded.emit(id);
    }
}
