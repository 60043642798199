/**
 *  Service for langs
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Observer, ReplaySubject, Subscriber, timer } from 'rxjs';
import { skipWhile, take, map } from 'rxjs/operators';

import { InfoService } from '../services/info.service';
import { StorageService } from '../services/storage.service';
import { LangInterface } from '../interfaces/lang.interface';
import APP_CONFIG from '../config/app.config';

@Injectable({
    providedIn: 'root',
})
export class LangService {
    public onLangLoaded: ReplaySubject<boolean> = new ReplaySubject();

    public constructor(private storage: StorageService, private info: InfoService, private trans: TranslateService) {}

    public isLangLoaded(): Observable<void> {
        return new Observable<void>((observe: Observer<void>) => {
            this.trans.get('sport.soccer').subscribe(() => {
                observe.next();
            });
        });
    }

    /**
     * Get all actual langs
     * @param {string} act  - if set some period of time
     * @return Observable<SportInterface[]>
     */
    public getLangs(reset: boolean = true): Observable<LangInterface[]> {
        if (reset) { this.resetLang(); }
        return Observable.create((subscriber: Subscriber<any>): void => {
            this.info.isLoaded().then((data): void => subscriber.next(data!.langs), (): void => subscriber.error());
        });
    }

    /**
     * Set lang iso
     * @param  {string} iso
     * @return  {Promise<boolean>}
     */
    public setLang(iso: string, translate: TranslateService, img?: string): Promise<boolean> {
        return new Promise((resolve): void => {
            translate.use(iso);
            this.storage.set('lang', iso);
            if (img) {
                this.storage.set('langImg', img);
            }
            resolve(true);
        });
    }

    /**
     * Get current lang iso
     * Wait 400ms * 20
     * @return {Promise<string>}
     */
    public getLang(): Promise<string> {
        if (this.storage.get<string>('lang') !== null) {
            return Promise.resolve(this.storage.get<string>('lang') as string);
        }

        return new Promise((resolve, reject): void => {
            const sub = timer(0, 400)
                .pipe(
                    take(40),
                    map((n): void => {
                        if (n >= 39) {
                            sub.unsubscribe();
                            reject();
                        }
                    }),
                    skipWhile((): boolean => this.storage.get<string>('lang') == null),
                    take(1),
                )
                .subscribe((): void => {
                    sub.unsubscribe();
                    resolve(this.storage.get<string>('lang') as string);
                });
        });
    }

    /**
     * Get saved lang iso without Promise
     * @return {string}
     */
    public getLangSnapshot(): string {
        return this.storage.get<string>('lang') || APP_CONFIG.defaultLang;
    }

    public isActiveDefault(): boolean {
        return !!(this.getLangSnapshot() === APP_CONFIG.defaultLang);
    }

    /**
     * Get saved lang iso without Promise
     * @return {string}
     */
    public isStored(): string | null {
        return this.storage.get<string>('lang');
    }

    public isArabic(): boolean {
        return !!(this.getLangSnapshot() === 'fa' || this.getLangSnapshot() === 'ar');
    }

    /**
     * Reset stored lang
     */
    public resetLang(): void {
        this.storage.set('lang', null);
    }
}
