import {
    State,
    Action,
    StateContext,
    Selector,
    createSelector,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { Injectable } from '@angular/core';

import { BetsService } from '../../services/bets.service';
import { UpdateMarkets, GetOdds } from '../actions/bets.action';
import {
    BetsMarketInterface,
    BetsInterface,
} from '../../interfaces/bets-market.interface';

export interface BetsStateInterface {
    markets: BetsMarketInterface[] | null;
    odds: { [prop: number]: BetsInterface[] } | null;
}

@State<BetsStateInterface>({
    name: 'bets',
    defaults: { markets: null, odds: null },
})
@Injectable()
export class BetsState {
    public constructor(private bet: BetsService) {}

    @Selector()
    public static markets(onlyMain: boolean): any {
        return createSelector(
            [BetsState],
            (state: BetsStateInterface): BetsMarketInterface[] => state.markets!.filter(
                (s): boolean => !onlyMain || s.is_main,
            ),
        );
    }

    @Selector()
    public static bet(state: BetsStateInterface): any {
        return (id: any): any => (_.has(state.odds, id as string) ? state.odds![id] : null);
    }

    @Action(UpdateMarkets)
    public markets(
        ctx: StateContext<BetsStateInterface>,
    ): Observable<BetsMarketInterface[]> {
        return this.bet.getMarkets().pipe(
            // @ts-ignore
            tap((markets: BetsMarketInterface[]): void => {
                ctx.patchState({
                    markets,
                });
            }),
        );
    }

    @Action(GetOdds)
    public odds(
        ctx: StateContext<BetsStateInterface>,
        { matchIds, onlyMain }: GetOdds,
    ): Observable<{ [prop: number]: BetsInterface[] }> {
        return this.bet.getOdds(matchIds, onlyMain).pipe(
            tap((data: { [prop: number]: BetsInterface[] }): void => {
                const state = ctx.getState();
                ctx.patchState({
                    odds: {
                        ...state.odds,
                        ...data,
                    },
                });
            }),
        );
    }
}
