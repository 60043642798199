/**
 *  Storage service
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public set(key: string, value: any): void {
        if (value) {
            value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
    }

    public get<T>(key: string): T | null{
        const value: string = localStorage.getItem(key)!;

        if (value && value !== 'undefined' && value !== 'null') {
            return JSON.parse(value) as T;
        }

        return null;
    }

    /**
     * Check if key exists
     * @param  {string}  key
     * @return {boolean}
     */
    public isset(key: string): boolean {
        return localStorage.getItem(key) !== null;
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }
}
