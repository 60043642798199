/**
 *  Service for Partners
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { PartnerInterface } from 'src/app/interfaces/info.interface';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root',
})
export class PartnerService {
    private partners: { [prop: string]: PartnerInterface };

    private partnesCount: number = 0;

    public init(ob: PartnerInterface[]): void {
        this.partnesCount = ob.length || 0;
        this.partners = _.indexBy(ob, 'partner_code_name');
    }

    /**
     * Get partner by code name
     * @param  {string} codeName
     * @return  {PartnerInterface}
     */
    public getPartner(codeName: string): PartnerInterface | null {
        return this.partners?.[codeName] ?? null;
    }

    /**
     * Get number of partners
     */
    public getCount(): number {
        return this.partnesCount;
    }
}
