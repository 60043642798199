/**
 *  Animation page transition
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import {
    style,
    animate,
    transition,
    query,
    group,
    trigger,
} from '@angular/animations';

export const PAGE_TRANS_ANIMATION = trigger('routeAnimations', [
    transition('* <=> *', [
        style({ position: 'relative' }),
        query(
            ':enter, :leave',
            [
                style({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                }),
            ],
            { optional: true },
        ),
        query(':enter', [style({ left: '-100%' })], { optional: true }),
        // query(':leave', animateChild()),
        group([
            query(
                ':leave',
                [animate('3000ms  ease-out', style({ left: '100%' }))],
                { optional: true },
            ),
            query(
                ':enter',
                [animate('3000ms  ease-out', style({ left: '0%' }))],
                { optional: true },
            ),
        ]),
        // query(':enter', animateChild()),
    ]),
]);
