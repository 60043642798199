import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';
import { IParallaxScrollConfig } from 'ngx-parallax-scroll';
import { banner } from '@services/decorators/class.decorator';

import { WindowRefService } from '@shared/window-ref';

import { BannerBase } from '../banner.base';

@banner({
    position: 5,
})
@Component({
    selector: 'app-banner[mobile-header]',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
    animations: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class BannerMobileHeaderBodyComponent extends BannerBase {
    public bannerPositionId = 5;
    public ngParallaxConf: IParallaxScrollConfig = {
        parallaxSpeed: 0.5,
        parallaxSmoothness: 0.4,
        parallaxDirection: 'straight',
        parallaxTimingFunction: 'ease-in',
        parallaxThrottleTime: 0.1,
    };

    public constructor(public deviceService: DeviceDetectorService, protected ref: ChangeDetectorRef,
        protected win: WindowRefService) {
        super(deviceService, ref, win);
    }


    /**
     * Dismiss banner
     */
    public close(): void {
        this.banner = null;
    }
}
