/**
 *  Service for Nav filter
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class NavCompetitionService {
    public onReset = new EventEmitter<boolean>();

    /**
   * Reset filter data
   */
    public reset(): void {
        this.onReset.emit(true);
    }
}
