/**
 *  Favorite App config
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

export default {
    availTypes: ['tournament', 'participant', 'match', 'match-detail', 'match-list-mobile'], // NOTE: order matter!!!
    tournamentKey: 'FAVORITE_TOURNAMENT',
    matchKey: 'FAVORITE_MATCH',
    participantKey: 'FAVORITE_PARTICIPANT',
    tournamentClickedKey: 'FAVORITE_TOURNAMENT_CLICK', // list of ids which was manually clicked
};
