/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { RouterOutlet, ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { InfoService } from 'src/app/services/info.service';

import { TranslateService } from '@ngx-translate/core';
import { delay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { LocationRefService } from '@shared/location-ref';


import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { environment } from '@environments/environment';

import { PAGE_TRANS_ANIMATION } from './shared/animations/page-trans.animation';
import BET_CONFIG from './config/bet.config';
import APP_CONFIG from './config/app.config';
import { PageService } from './services/page.service';
import { ClientService } from './services/client.service';
import { TranslationService as TService } from './services/translation.service';
import { StorageService } from './services/storage.service';
import { LangService } from './services/lang.service';
import { BannerService } from './services/banner.service';
import { MetaService } from './services/meta.service';
import { PageState } from './store/states/page.state';

import { WindowRefService } from './shared/window-ref';

import { SnackBarService } from './services/snack-bar.service';

import { DebugMode, Media, Partner } from '@/store/actions/page.action';
import { MediaMode } from '@/interfaces/mode.interface';


// declare const ga: Function;x

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [
        PAGE_TRANS_ANIMATION,
        // animation triggers go here
    ],
})
export class AppComponent implements OnInit, AfterViewInit {
    @Select(PageState.network) public networkStatus$: Observable<string>;

    @Select(PageState.numFailedRequests) public numFailedRequests$: Observable<number>;

    @Select((state: any) => state.page.debug) debug$: Observable<boolean>;

    private loadingStateVal: boolean = false;

    private pageDone: boolean = false;

    jsonLD: SafeHtml;

    public constructor(
        private page: PageService,
        private info: InfoService,
        private banner: BannerService,
        private route: ActivatedRoute,

        private translate: TranslateService,
        private tservice: TService,
        public client: ClientService,
        private store: StorageService,
        private lang: LangService,
        public router: Router,
        private storeNgx: Store,
        private ngZone: NgZone,
        private location: LocationRefService,
        private sanitizer: DomSanitizer,
        private meta: MetaService,
        private win: WindowRefService,
        private snackbar: SnackBarService,
    ) {}

    public ngAfterViewInit(): void {
        this.ngZone.runOutsideAngular((): void => {
            $('body').removeClass('loader-active');
        });

        // this.router.events
        //     .pipe(
        //         delay(500),
        //         filter((e): boolean => e instanceof NavigationEnd),
        //     )
        //     .subscribe((event: NavigationEnd): void => {
        //         // ga('set', 'page', event.urlAfterRedirects);
        //         // ga('send', 'pageview');
        //     });
    }

    public ngOnInit(): void {
        this.ngZone.runOutsideAngular((): void => {
            $('#loader-temp').hide();

            $('<script id="app-tracker-script">')
                .attr('data-sr-language', this.lang.getLangSnapshot())
                .attr('class', 'trackerjs')
                .attr('src', `https://widgets.sir.sportradar.com/${APP_CONFIG.brTrackerClientId}/widgetloader`)
                .appendTo('head');
        });

        this.debug$.subscribe((mode: boolean) => {
            this.page.debug = mode;
        });

        this.route.queryParamMap.pipe(switchMap(val => of(val))).subscribe((param): void => {
            this.page.pageLoaded();

            if (param.has('partnerName')) {
                this.storeNgx.dispatch(new Partner(param.get('partnerName')!)).subscribe(() => {
                    this.banner.load();
                });
            } else if (!this.location.nativeLocation.href.includes('partnerName')) {
                this.banner.load();
            }

            if (param.has('displayMode')) {
                const displayMode: MediaMode = param.get('displayMode') as MediaMode;
                const place = param.has('place') ? param.get('place')! : null;
                this.storeNgx.dispatch(new Media({ displayMode, place }));
            }

            if (param.has('debugMode')) {
                this.storeNgx.dispatch(new DebugMode(true));
            }

            if (param.has('signoff')) {
                this.translate
                    .get('web.sign_off_success')
                    .pipe(delay(800))
                    .subscribe((): void => {
                        this.openSnackBar(
                            this.translate.instant('web.sign_off_success'),
                            'info',
                        );
                    });
            } else if (param.has('signindone')) {
                this.translate
                    .get('web.web_sign_in_success')
                    .pipe(delay(900))
                    .subscribe((): void => {
                        this.openSnackBar(
                            this.translate.instant('web.web_sign_in_success'),
                            'info',
                        );
                    });
            }
        });

        this.page.pageLoadEvent.subscribe((val): void => {
            if (val === true) {
                $('#loader-temp').hide();
            }

            // this.banner.load();
        });

        this.lang.onLangLoaded.subscribe((): void => {
            this.pageDone = true;
            // this.loadingStateVal = true;
        });

        this.info.load();

        this.loadingStateVal = false;

        this.store.remove(BET_CONFIG.marketsKey);
        this.store.remove(BET_CONFIG.oddsKeyMain);
        this.store.remove(BET_CONFIG.oddsKeyAll);

        this.tservice.sendMissing();

        this.meta.jsonLDSetter$.subscribe((jsonLD: {[key: string]: unknown}): void => {
            this.jsonLD = this.getSafeHTML(jsonLD);
        });
    }

    private generateJsonLD(): void {
        const json = {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: 'https://24live.com/',
            name: '24live',
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'Holušická 2253/1',
                addressLocality: 'Praha',
                addressRegion: 'Chodov',
                postalCode: '148 00',
                addressCountry: 'CZ',
            },
        };

        this.jsonLD = this.getSafeHTML(json);
    }

    get isDevel(): boolean {
        return !environment.developement;
    }

    // @HostListener('window:resize', ['$event'])
    // private onResize(): void {
    //     this.win.nativeWindow.location.reload();
    // }

    getSafeHTML(jsonLD: {[key: string]: unknown}): SafeHtml {
        const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        // escape / to prevent script tag in JSON
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    private openSnackBar(message: string, action: 'info' | 'alert'): void {
        this.snackbar.openSnackBar(message, 'top', action);
    }

    public prepareRoute(outlet: RouterOutlet): string {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }

    /**
     * Get state of loading
     * @return boolean
     */
    public loadingState(): boolean {
        return this.loadingStateVal;
    }
}
