/**
 *  Kiwibetconfiguration
 *  kiwibet
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2024 livescore
 */

import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: false,
        pagesInternal: false,
        cookie: false,
        oddsTab: false,
        closeIcon: false,
        share: true,
        winProbability: true,
        matchListAlpha: true,
        sportQuickNav: true,
        desktopLogo: false,
        betsStats: true,
        pageDarkMode: false,
        prematchH2HDefault: false,
        liveIcon: true,
        news: true,
        video: true,
        channels: true,
        map: true,
        canonicalPartner: false,
        partners: false,
        seoCard: true,
        opta: false,
        tracker: false,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: false,
        matchDetailFunFacts: false,
        useDefaultLogo: false,
        notification: true,
    },


    pages: {

    },
    social: {

    },
    content: {
        betsImg: '/img/ball.svg',
        betsText: 'web.got_to_fortebet',
    },
    bet: {
        winApi: true,
        displayConfirm: true,
        isOddsLiveAndAfter: false,
    },

    colors: {
        icons: 'white',
    },
};

export default config;
