<header class="app-light-header d-flex justify-content-between">
    <div class="app-light-header__client">
        <img
            [src]="'/img/' + client.getName() + '.png'"
            class="app-light-header__logo"
            data-cy="light-header-logo"
            [alt]="client.getName()"
        />
    </div>
    <div class="app-light-header__domain align-self-center">
        <i class="fas fa-globe-africa"></i> {{ client.getMainDomain() }}
    </div>
    <div *ngIf="client.use('closeIcon')">
        <button type="button" data-cy="search-button2" class="btn btn-link nav-link text-white" (click)="closeModal()">
            <i class="far fa-times-circle d-block mb-1" style="font-size: 26px"></i>
            <!-- {{ 'web.close_modal' | translate | transinit }} -->
        </button>
    </div>
</header>
<app-lang class="d-none" [mobile]="false" [flags]="false"></app-lang>
