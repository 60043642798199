/**
 *  H2H Component resolver
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2024 livescore
 */

import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { BaseComponent } from '@pages/base.component';
import { PageService } from '@services/page.service';
import { InfoService } from '@services/info.service';
import { ClientService } from '@services/client.service';
import { LangService } from '@services/lang.service';


import { MatchData, MatchDetailDisplayMode } from '@interfaces/match-data.interface';

import { MatchService } from '@services/match.service';


@Component({
    selector: 'app-h2h',
    templateUrl: './h2h.component.html',

})
export class H2HComponent extends PageMixin(BaseComponent) implements OnInit {
    private subscriber: Subscription;

    public $br: Observable<Data>;

    public data: MatchData<[]>;

    public mode: MatchDetailDisplayMode;

    public constructor(
        protected page: PageService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        private router: ActivatedRoute,


        private match: MatchService,
    ) {
        super(page, title, meta, route, info);
    }

    /**
     * Load data from server
     */
    public ngOnInit(): void {
        super.ngOnInit();

        $('body').removeClass('loader-active');
        this.$br = this.router.data;
        this.$br.subscribe((val) => {
            if (val.br) {
                this.getMatch(val.br);
            } else {
                this.error();
            }
        });
    }


    private getMatch(id: number): void {
        this.match.getMatch(id).then((m) => {
            m.subscribe((match) => {
                this.route.navigate(['/compare/', `${match.sport_code_name}-${match.sport_id}`,
                    match.participants[0]?.id, match.participants[1]?.id], { queryParams: { mode: 'widget' } });
            }, () => {
                this.error();
            });
        }).catch(() => {
            this.error();
        });
    }
}
