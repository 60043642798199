/**
 *  Forbet configuration
 *
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */

import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: true,
        cookie: false,
        pagesInternal: false,
        oddsTab: true,
        share: true,
        closeIcon: false,
        winProbability: true,
        matchListAlpha: false,
        sportQuickNav: false,
        desktopLogo: false,
        pageDarkMode: false,
        betsStats: true,
        prematchH2HDefault: false,
        liveIcon: true,
        news: true,
        video: true,
        channels: true,
        map: true,
        canonicalPartner: false,
        partners: false,
        opta: true,
        useDefaultLogo: false,
        notification: true,
        tracker: true,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        seoCard: false,
    },
    social: {
        facebook: '',
        instagram: '',
        youtube: '',
        twitter: '',
    },
    bet: {
        winApi: true,
        displayConfirm: false,
        isOddsLiveAndAfter: true,
    },
    colors: {
        icons: 'white',
    },
};

export default config;
