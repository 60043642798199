/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Client config
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2020 livescore
 */


import FortebetUG from '@config/guards/fortebet-ug';
import FortunebetSS from '@config/guards/fortunebet-ss';
import live24 from '@config/guards/24live';
import championbet from '@config/guards/championbet';
import championbetGh from '@config/guards/championbet-gh';
import forbet from '@config/guards/forbet';
import FortebetNG from '@config/guards/fortebet-ng';
import premierbetCi from '@config/guards/premierbet-ci';
import kingsBet from '@config/guards/kings-bet';
import estave from '@config/guards/estave';
import kiwibet from '@config/guards/kiwibet';
import betubetu from '@config/guards/betubetu';
import betubetu2 from '@config/guards/betubetu2';

const CONFIG = {
    parentTypes: { betting: { name: 'betting' } }, // parent types
    clients: {

        fortebet_ug: FortebetUG,
        fortebet_ng: FortebetNG,
        fortunebet_ss: FortunebetSS,
        premierbet_ci: premierbetCi,
        kings_bet: kingsBet,

        forbet,

        '24live': live24,
        championbet,
        championbet_gh: championbetGh,

        estave,
        kiwibet,
        betubetu,
        betubetu2,
    },
};

export default CONFIG;
