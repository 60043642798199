type Constructor<T = Record<any, any>> = new (...args: any[]) => T;

export const TranslateMixin = <T extends Constructor>(base:T):T => class extends base {
    constructor(...args: any[]) {
        super(...args);
    }

    public trans(text: string): string {
        return this.translate.instant(text);
    }
};
