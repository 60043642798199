export class AddMissingTrans {
    public static readonly type = '[Translations] Add missing translations';

    public constructor(public trans: string, public path: string) {}
}

export class ClearAllMissingTrans {
    public static readonly type = '[Translations] Clear all missing';
}

export class SendMissingTrans {
    public static readonly type = '[Translations] Send translations to server';
}
