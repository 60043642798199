import { State, Action, StateContext, Selector } from '@ngxs/store';
import { TimeService } from 'src/app/services/time.service';
import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { Permissions, UserInterface } from '@interfaces/user.interface';

import { SignIn, SignOff, User, UserDefault } from '../actions/user.action';

declare const moment: any;


interface UserStatusModel {
    isLoggedIn: boolean;
    data: UserInterface;
}

@State<UserStatusModel>({
    name: 'user',
    defaults: {
        isLoggedIn: false,
        data: {
            email: '',
            name: '',
            firstname: '',
            surname: '',
            gravatar: '',
            lastSignIn: null,
            permisions: [],
        },
    },
})
@Injectable()
export class UserState {
    /**
     * Get is logged in info
     * @return {boolean}
     */
    @Selector()
    public static isLoggedIn(state: UserStatusModel): boolean {
        return state.isLoggedIn;
    }

    /**
     * Get user data
     * @return {boolean}
     */
    @Selector()
    public static data(state: UserStatusModel): UserInterface {
        return state.data;
    }

    /**
     * Get user data
     * @return {boolean}
     */
    @Selector()
    public static permissions(state: UserStatusModel): Permissions[] {
        const p = cloneDeep(state.data);
        return p.permisions!.map((v: any) => {
            v.isValid = (moment().isBetween(v.pivot.valid_from, v.pivot.valid_to) && v.pivot.active === 1);

            return v;
        }) ?? [];
    }

    /**
     * Get user last sign in
     * @return {boolean}UtilsService
     */
    @Selector()
    public static lastSign(state: UserStatusModel): any | null {
        if (state.data.lastSignIn === null) {
            return moment();
        }
        return TimeService.utcToLocal(moment(state.data.lastSignIn));
    }

    /**
     * Set/update user ddata
     */
    @Action(User)
    public setUserData(ctx: StateContext<UserStatusModel>, { user }: User): void {
        ctx.patchState({
            data: user,
        });
    }

    @Action(UserDefault)
    public clearUserData(ctx: StateContext<UserStatusModel>): void {
        ctx.patchState({
            data: {
                email: '',
                name: '',
                firstname: '',
                surname: '',
                gravatar: '',
            },
        });
    }

    @Action(SignIn)
    public signIn(ctx: StateContext<UserStatusModel>): void {
        ctx.patchState({
            isLoggedIn: true,
        });
    }

    @Action(SignOff)
    public signOff(ctx: StateContext<UserStatusModel>): void {
        ctx.patchState({
            isLoggedIn: false,
        });
    }
}
