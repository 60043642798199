<!-- Nav level 1 -->
<ng-container *ngIf="this.type != 'selected' || (this.type == 'selected' && this.categoryId != null)">
    <ng-container *ngIf="level == 1">
        <div class="row mx-0 dropdown-menu--megamenu__competitions small bg-white">
            <ng-container *ngIf="loaded; else elseBlock">
                <div *ngIf="loadedError" class="col">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div *ngIf="noSearchResult" class="col">
                    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry_navigation' | translate }}</h4>
                        </div>
                    </div>
                </div>
                <div
                    *ngFor="let nav of data | navSearch : 'alpha' : alphaFilter; trackBy: trackByFn"
                    [class.mark]="nav.favorite_stick"
                    [class.d-none]="hasNoChild(nav.id)"
                    class="app-navi-parent col-lg-3 p-0 p-lg-2 border-bottom border-right border-light border-left border-left-md-0"
                >
                    <div class="pl-5">
                        <h6 class="h5 font-weight-bold ml-n5" (click)="mobileOpenSecondLevel($event, nav)">
                            <ng-container *ngIf="!isMobile">
                                <a
                                    [routerLink]="categoryUrl ? [categoryUrl, sportName + '-' + sportId, nav.id] : null"
                                    (click)="resetNav()"
                                    routerLinkActive="text-danger"
                                    data-cy="competiton-navigation-item"
                                    class="text-reset align-self-center"
                                >
                                    <span
                                        *ngIf="level == 1"
                                        [class.text-danger]="nav.active"
                                        (click)="nav.active = !nav.active; $event.preventDefault()"
                                        ><img
                                            src="{{ cdn + nav.image }}"
                                            width="20"
                                            class="mx-1 rounded-circle"
                                            [alt]="nav.name"
                                        />
                                        {{ nav.name }}
                                    </span>
                                </a>
                            </ng-container>
                            <ng-container *ngIf="isMobile">
                                <span *ngIf="level == 1" [class.emph-text-node]="nav.active"
                                    ><img
                                        src="{{ cdn + nav.image }}"
                                        width="20"
                                        class="mx-1 rounded-circle"
                                        [alt]="nav.name"
                                    />
                                    {{ nav.name }}
                                </span>
                            </ng-container>
                        </h6>
                    </div>
                    <ng-container *ngIf="isMobile && nav.active">
                        <div class="border-bottom">
                            <app-navigation
                                class="d-block"
                                [type]="type"
                                [level]="2"
                                [sportId]="sportId"
                                [categoryId]="nav.id"
                                [sportName]="sportName"
                                [cache]="cacheData"
                                [level2]="nav.level2"
                                [fulltext]="fulltext"
                            ></app-navigation>
                        </div>
                    </ng-container>
                    <div class="mx-n2 px-2 py-0 py-md-2" *ngIf="!isMobile">
                        <div class="pl-md-3 pl-lg-5" id="app-nav-{{ nav.id }}">
                            <ng-container *ngIf="!nav.active">
                                <div
                                    *ngFor="
                                        let nav2 of nav.level2 | navSearch : 'fulltext' : fulltext : 0 : '' : 4;
                                        trackBy: trackByFn
                                    "
                                    class="app-nav-{{ nav.id }} dropdown-menu--megamenu__competitions__item"
                                >
                                    <app-favorite
                                        *ngIf="!isStage() && sportName !== 'golf'"
                                        [tournamentId]="nav2.id"
                                        [type]="'tournament'"
                                        [sportId]="sportId"
                                        class="d-flex"
                                    >
                                        <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                                        <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
                                    </app-favorite>
                                    <img
                                        *ngIf="nav2.iso"
                                        alt="{{ nav2.iso }}"
                                        class="mr-2 rounded-circle"
                                        width="14"
                                        height="14"
                                        src="/img/flags/1x1/{{ nav2.iso | lowercase }}.svg"
                                    />
                                    <a
                                        [routerLink]="[tournamentUrl, sportName + '-' + sportId, nav2.id]"
                                        (click)="resetNav()"
                                        routerLinkActive="text-danger"
                                        data-cy="competiton-navigation-item"
                                        class="text-reset align-self-center"
                                    >
                                        <img
                                            *ngIf="nav2.image && nav2.image.length > 0"
                                            src="{{ cdn + imageRep(nav2.image) }}"
                                            width="20"
                                            class="mr-2"
                                            alt="{{ nav2.name }}"
                                        />

                                        {{ nav2.name }}</a
                                    >
                                </div>
                            </ng-container>
                            <div *ngIf="nav.level2?.length > 3" class="megamenu-more-btn megamenu-more-show mt-1">
                                <a
                                    (click)="
                                        closeAll();
                                        secondNaviLoaded = nav.id;
                                        nav.active = !nav.active;
                                        $event.preventDefault();
                                        $event.stopPropagation()
                                    "
                                    class="text-reset app-cursor"
                                    >{{ 'web.show_more' | translate }}</a
                                >
                            </div>

                            <div class="megamenu-more border-bottom" *ngIf="nav.active">
                                <app-navigation
                                    class="d-block"
                                    [type]="type"
                                    [level]="2"
                                    [sportId]="sportId"
                                    [categoryId]="nav.id"
                                    [sportName]="sportName"
                                    [cache]="cacheData"
                                    [level2]="nav.level2"
                                    [fulltext]="fulltext"
                                ></app-navigation>
                                <div class="megamenu-more-btn megamenu-more-hide mt-1">
                                    <a
                                        (click)="nav.active = !nav.active; $event.stopPropagation()"
                                        class="text-reset app-cursor"
                                        >{{ 'web.show_less' | translate }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="data && data.length == 0">
                    <div class="w-100 tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                        <div class="text-center pt-7">
                            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + sportName" /></svg>
                            <h4 class="font-weight-bold">{{ 'web.empty_data_navigation' | translate }}</h4>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #elseBlock>
                <div class="col m-4 align-self-center">
                    <app-page-loader></app-page-loader>
                </div>
            </ng-template>
        </div>
    </ng-container>
</ng-container>

<!-- Nav level 2 all -->
<ng-container *ngIf="level == 2 && type != 'top'">
    <ng-container *ngIf="!isMobile">
        <div
            *ngFor="let nav of level2 | navSearch : 'fulltext' : fulltext; trackBy: trackByFn"
            class="app-nav-{{ categoryId }} dropdown-menu--megamenu__competitions__item"
        >
            <app-favorite
                *ngIf="!isStage() && sportName !== 'golf'"
                [tournamentId]="nav.id"
                [type]="'tournament'"
                [sportId]="sportId"
                class="d-inline"
            >
                <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
            </app-favorite>
            <img
                *ngIf="nav.iso"
                [alt]="nav.iso"
                class="mr-2 rounded-circle"
                width="14"
                height="14"
                src="/img/flags/1x1/{{ nav.iso | lowercase }}.svg"
            />
            <a
                [routerLink]="[tournamentUrl, sportName + '-' + sportId, nav.id]"
                (click)="resetNav()"
                data-cy="competiton-navigation-item"
                routerLinkActive="text-danger"
                class="text-reset align-self-center"
            >
                <img src="{{ cdn + nav.image }}" width="17" [alt]="nav.name" />
                {{ nav.name }} {{ fulltext }}</a
            >
        </div>
    </ng-container>
    <ng-container *ngIf="isMobile">
        <ng-container *ngIf="loadingLevel2">
            <app-page-loader [simple]="true"></app-page-loader>
        </ng-container>
        <div
            *ngFor="let nav of data | navSearch : 'fulltext' : fulltext; trackBy: trackByFn"
            class="app-nav-{{ categoryId }} dropdown-menu--megamenu__competitions__item"
        >
            <img
                *ngIf="nav.iso"
                [alt]="nav.name"
                class="ml-2 mobile-nav-flag rounded-circle"
                width="20"
                height="20"
                src="/img/flags/1x1/{{ nav.iso | lowercase }}.svg"
            />
            <a
                [routerLink]="[tournamentUrl, sportName + '-' + sportId, nav.id]"
                (click)="resetNav()"
                data-cy="competiton-navigation-item"
                routerLinkActive="text-danger"
                class="text-reset pl-2 align-self-center"
            >
                <img src="{{ cdn + nav.image }}" width="17" *ngIf="nav.image !== null" [alt]="nav.name" />
                {{ nav.name }} {{ fulltext }}</a
            >
            <app-favorite
                *ngIf="!isStage() && sportName !== 'golf'"
                [tournamentId]="nav.id"
                [type]="'tournament'"
                [sportId]="sportId"
                class="d-inline"
            >
                <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
            </app-favorite>
        </div>
    </ng-container>
</ng-container>

<!-- Nav  top -->
<div class="row mx-0 dropdown-menu--megamenu__competitions p-0" style="overflow: hidden; position: relative">
    <ng-container *ngIf="this.type != 'selected' || (this.type == 'selected' && this.categoryId != null)">
        <ng-container *ngIf="level == 2 && type == 'top' && view == 'topNav'">
            <ng-container *ngIf="loaded">
                <swiper class="swiper-container px-2 px-sm-3" #swiper [config]="swiperConfig">
                    <ng-template swiperSlide *ngFor="let nav of data; trackBy: trackByFn">
                        <a
                            class="nav-link d-flex align-items-center px-2 align-items-center"
                            [routerLink]="[tournamentUrl, sportName + '-' + sportId, nav.id]"
                        >
                            <!-- <svg class="icon icon--12 mr-2"><use href="#icon-star" /></svg> -->
                            <app-favorite
                                *ngIf="nav.favorite_stick == false && !isStage() && sportName !== 'golf'"
                                classDef="icon icon--12 mr-2"
                                class="d-flex align-items-center"
                                [tournamentId]="nav.id"
                                [type]="'tournament'"
                                [sportId]="sportId"
                            >
                                <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                                <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
                            </app-favorite>
                            <img
                                *ngIf="nav.image?.length > 0"
                                src="{{ cdn + imageRep(nav.image) }}"
                                width="20"
                                height="15"
                                class="mr-2 app-participant-logo"
                                alt="{{ nav.name }}"
                            />
                            <ng-container *ngIf="nav.image?.length == 0">&nbsp:&nbsp;</ng-container>
                            {{ nav.name | truncate : 16 }}
                        </a>
                    </ng-template>
                </swiper>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="level == 2 && type == 'top' && view == 'eventNav'">
            <div
                *ngFor="let nav of data | navSearch : 'fulltext' : fulltext; trackBy: trackByFn"
                class="app-nav-{{
                    categoryId
                }} dropdown-menu--megamenu__competitions__item mobile-event-nav-header__favorite"
            >
                <a
                    [routerLink]="[tournamentUrl, sportName + '-' + sportId, nav.id]"
                    (click)="resetNav()"
                    routerLinkActive="text-danger"
                    class="text-reset"
                >
                    <img
                        src="{{ cdn + nav.image }}"
                        width="17"
                        *ngIf="isMobile && nav.image !== null"
                        [alt]="nav.name"
                    />
                    {{ nav.name }} {{ fulltext }}</a
                >
                <app-favorite
                    *ngIf="!isStage() && sportName !== 'golf'"
                    [tournamentId]="nav.id"
                    [type]="'tournament'"
                    [sportId]="sportId"
                    class="d-inline"
                >
                    <span notactive>{{ 'web.add_tournament_to_favorites' | translate }}</span>
                    <span active>{{ 'web.remove_tournament_from_favorites' | translate }}</span>
                </app-favorite>
            </div>
        </ng-container>
    </ng-container>
</div>
