/* eslint-disable */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';


/* eslint-disable-line */
import { SharedModule } from '@/shared/shared.module';

import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';

import { environment } from '../environments/environment';

import SPORT_CONFIG, { SportConfigToken } from '@config/sport.config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Error404Component } from './pages/error404/error404.component';
import { UserState } from './store/states/user.state';
import { BetsState } from './store/states/bets.state';
import { PageState } from './store/states/page.state';
import { BranchUserState } from './modules/branch/store/states/user.state';
import { BranchSettingState } from './modules/branch/store/states/settings.state';
import { BranchAppState } from './modules/branch/store/states/app.state';
import { ComponentModule } from './components/component.module';


import { TranslationsState } from './store/states/transaltions.state';
import { H2HComponent } from '@pages/page-sport/h2h/h2h.component';
// TODO ready to use in future
// export function initializeApp1(): () => void {
//   return ():void => {console.log('App Init');}
// }



/** Social plugin* */



@NgModule({
    declarations: [AppComponent, Error404Component,  H2HComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        ComponentModule,
        SocialLoginModule,
        AppRoutingModule,
        NgxsModule.forRoot([
            UserState,
            BetsState,
            PageState,
            BranchUserState,
            BranchSettingState,
            BranchAppState,
            TranslationsState,
        ], {
            developmentMode: !environment.production,
        }),
    ],

    providers: [
        {provide: SportConfigToken, useValue: SPORT_CONFIG},
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                lang: 'en',
                providers: [
                {
                    id: GoogleLoginProvider.PROVIDER_ID,
                    provider: new GoogleLoginProvider(
                    '768818760475-bahpghpmf3ptgnivkib418tbga724s57.apps.googleusercontent.com'
                    )
                },
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider('735699593514920')
                }
                ],
                onError: (err) => {
                console.error(err);
                }
            } as SocialAuthServiceConfig,
            }
        // { provide: ErrorHandler, useClass: SentryErrorHandler },
        // { provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [], multi: true}

    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
