import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { MyMatchesService } from '@/services/my-matches.service';

@Injectable({
    providedIn: 'root',
})
export class H2HResolverService implements Resolve<number | null> {
    public constructor(public matches: MyMatchesService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<number | null> {
        const id = route.paramMap.get('matchId') || null;


        const source = route.queryParamMap.get('source') || 'internal';
        let obs: Observable<any>;


        if (id === null) {
            obs = of([]);
        } else if (source === 'internal') {
            obs = of([id]);
        } else if (source === 'br') {
            obs = this.matches.brToId(id.toString());
        } else {
            obs = this.matches.sourceIdToId(id.toString(), source);
        }
        return obs.pipe(
            mergeMap(
                (ids: number[]): Observable<number | null> => {
                    if (ids.length > 0) {
                        return of(ids.pop()!);
                    }
                    return of(null);
                },
            ),
        );
    }
}
