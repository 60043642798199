/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Custom translation service
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';

import * as $ from 'jquery';
import { forEach } from 'lodash-es';
import { TranslationService } from 'src/app/services/translation.service';

import { PageState } from '@store/states/page.state';

import { Select } from '@ngxs/store';

import { CommonService } from '../common.service';
import CONFIG from '../../config/app.config';


@Injectable({
    providedIn: 'root',
})
export class CustomTranslateLoaderService implements TranslateLoader {
    @Select(PageState.isDebug) public isDebug$: Observable<boolean>;

    private isDebug = false;

    public constructor(private http: HttpClient, private common: CommonService) {
        this.isDebug$.subscribe((debug: boolean): void => {
            this.isDebug = debug;
        });
    }

    public getTranslation(lang: string): Observable<any> {
        return this.load(lang);
    }

    private load(lang: string): Observable<any> {
        const options = this.common.ajaxHeaders();
        const file = `locale-${lang}.json`;

        // if (TranslationService.isLoading) {
        //     return empty();
        // }

        TranslationService.isLoading = true;
        return this.http.get(CONFIG.translatePath + file, options).pipe(
            catchError(this.common.errorCallback),
            map((response: any) => {
                if (this.isDebug && 'web' in response) {
                    forEach(response.web, (val: any, key: string) => {
                        if (typeof val === 'string') {
                            response.web[key] = `${val} (${key})`;
                        }
                    });
                }

                if (this.isDebug && 'status' in response) {
                    forEach(response.status, (val: any, key: string) => {
                        if (typeof val === 'object') {
                            forEach(response.status[key], (val2: any, key2: string) => {
                                response.status[key][key2] = `${val2} (${key2})`;
                            });
                        }
                    });
                }

                return response;
            }),
            finalize((): void => {
                $('.app-main-loader').remove();

                TranslationService.isLoaded = true;
            }),
        );
    }

    private extractData(res: Response): Observable<any> {
        const body = res.json() || {};
        return of(body);
    }
}
