import { Component, Output, QueryList, ContentChildren, AfterContentInit } from '@angular/core';
import * as _ from 'underscore';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { BannerInterface, BannerType } from '@interfaces/banner.interface';
import { propertyMetada } from '@services/decorators/propety.decorator';

import { DisplayModeInterface, MediaMode } from '@interfaces/mode.interface';

import { LangService } from '@services/lang.service';

import { UserService } from '@services/user.service';

import { BannerService } from '../../services/banner.service';
import { BannerPositionInterface } from '../../interfaces/banner-position.interface';

import { PageState } from '@/store/states/page.state';

@Component({
    selector: 'app-banner-container',
    templateUrl: './banner.component.html',
})
export class BannerComponent implements AfterContentInit {
    @ContentChildren('banners') private contentChildren!: QueryList<BannerType>;

    @Select(PageState.getMedia) public mediaOb$: Observable<DisplayModeInterface>;

    @Output()
    public banner: BannerInterface;

    @propertyMetada({
        description: 'Banner position information',
        type: 'object',
    })
    public bannerPosition: BannerPositionInterface;

    /**
     * Should display banners in some case
     */
    private stopBanner: boolean = false;

    public constructor(private bannerService: BannerService, private lang: LangService) {}

    public ngAfterContentInit(): void {
        this.lang.onLangLoaded.subscribe(() => {
            if (this.bannerService.isLoaded) {
                this.pickBanner();
            } else {
                this.bannerService.onLoad.subscribe((): any => {
                    this.pickBanner();
                });
            }

            this.mediaOb$.subscribe((media): void => {
                if (media && media.displayMode && media.displayMode === MediaMode.KIOSK) {
                    this.stopBanner = true;
                    this.pickBanner();
                }
            });
        });
    }

    public pickBanner(): void {
        this.contentChildren.forEach((elm): void => {
            const id: number | null = elm.bannerPositionId;

            if (this.bannerService.banners !== null && !_.isUndefined(this.bannerService.banners[id!])) {
                const bannerPosition = this.bannerService.banners[id!.toString()];


                let filteredBanners = [] as BannerInterface[] | undefined;
                if (bannerPosition) {
                    filteredBanners = bannerPosition?.banners
                        ?.filter(b => b.lang === null || b.lang === this.lang.getLangSnapshot())
                        .filter(b => b.country === null || b.country === UserService.getIso());
                }

                const banner = bannerPosition &&
                    filteredBanners![Math.floor(Math.random() * filteredBanners!.length)];


                const isLangEnabled = banner && (banner.lang === null || banner.lang === this.lang.getLangSnapshot());

                if (this.stopBanner || !isLangEnabled) {
                    elm.setBanner(null);
                    return;
                }
                if (banner) {
                    this.responsiveImages(banner);
                }

                elm.setBanner(banner);
            }
        });
    }

    private responsiveImages(banner: BannerInterface): void {
        const split: string[] = banner.path.split('/');
        const image: string | undefined = split.pop();
        const path: string = split.join('/');
        const [imageName, imageType] = image!.split('.');
        banner.mobile_image = `${path}/${imageName}-mobile.${imageType}`;
        banner.small_image = `${path}/responsive/${imageName}-small.${imageType}`;
        banner.small_image = `${path}/responsive/${imageName}-small.${imageType}`;
        banner.medium_image = `${path}/responsive/${imageName}-medium.${imageType}`;
        banner.large_image = `${path}/responsive/${imageName}-large.${imageType}`;
    }
}
