import { Component, OnInit, Input } from '@angular/core';
import { NavCompetitionService } from 'src/app/services/nav-competitions.service';
import * as $ from 'jquery';
import * as _ from 'underscore';
import { DeviceDetectorService } from 'ngx-device-detector';

import { NaviLevel2Interface } from '@interfaces/navigation.interface';

import SPORT_CONFIG from '../../../config/sport.config';

import { WindowRefService } from '@/shared/window-ref';

declare const moment: any;

@Component({
    selector: 'app-header-navigation',
    templateUrl: './navigation.component.html',
})
export class HeaderNavigationComponent implements OnInit {
    @Input()
    public sportId: number;

    @Input()
    public sportName: string;

    public naviSearch: string;

    public year = moment().year();

    public years: number[] = [];

    public tab: string;

    public isMobile: boolean = false;

    public alphaFilter: { star: boolean; alpha: string[] } = {
        star: false,
        alpha: [],
    };

    public constructor(
        private nav: NavCompetitionService,
        private deviceService: DeviceDetectorService,
        private win: WindowRefService,
    ) {}

    public ngOnInit(): void {
        this.isMobile = this.deviceService.isMobile();

        this.nav.onReset.subscribe((): void => {
            this.naviSearch = '';
            this.tab = 'all';
        });
        this.tab = 'all';

        $('.dropdown-menu--megamenu').click((event): void => {
            if (
                event.currentTarget.className.includes('dropdown-menu--megamenu') &&
                _.isFunction(event.target.className.includes) &&
                event.target.className.includes('dropdown-menu--megamenu')
            ) {
                $('.dropdown-menu--megamenu').removeClass('dropdown-menu--megamenu--active');
                $('body').removeClass('megamenu-open');
                $('body').removeClass('lock-scroll');
            }
        });

        if (moment().month() >= 7 && this.isStage()) {
            this.year = +moment().year() + 1;
        }

        for (let i = 2019; i <= +moment().year() + 1; i += 1) {
            this.years.push(i);
        }
    }

    public active(tab: string): void {
        this.tab = tab;
    }

    public isStage(): boolean {
        return SPORT_CONFIG.isStage(this.sportName);
    }

    showFavorite: boolean = true;
    public onFavoriteLoaded(data: {
        sportId: number;
        categoryId: number;
        data: NaviLevel2Interface;
    }): void {
        this.showFavorite = true;
        // @ts-ignore
        if (!data.data[0]?.id) {
            this.showFavorite = false;
        }
    }

    public get topPx(): number {
        const x = this.win.nativeWindow.scrollY;

        if (this.deviceService.isMobile()) {
            return 58;
        }

        return Math.max(103 - x, 0);
    }
}
