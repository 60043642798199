/**
 *  Service for Clients
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import type { ClientObInterface, ClientsID, ClientsSubID, PageElements } from '@interfaces/client.interface';
import { ClientInterface } from 'src/app/interfaces/info.interface';
import CLIENT_CONFIG from '@config/client.config';
import APP_CONF from '@config/app.config';


@Injectable({
    providedIn: 'root',
})
export class ClientService implements ClientObInterface {
    private name: ClientsID = APP_CONF.defaultClient as ClientsID;

    private nameView: string = APP_CONF.defaultClient as ClientsID;

    private mainDomain: string;

    private socialImage: string;

    private parentDomain?: string;

    private parentType?: string;

    public clientLoaded: boolean = false;


    public init(ob: ClientInterface): void {
        this.name = ob.name as ClientsID;
        this.nameView = ob.name_view ?? APP_CONF.defaultClient;
        this.mainDomain = ob.main_domain;
        this.socialImage = ob.social_image;
        this.parentDomain = ob.parent_domain;
        this.parentType = ob.parent_type;
        this.clientLoaded = true;
    }

    /**
     * Test id client has active element object
     * @return {boolean}
     */
    public use(right: PageElements): boolean {
        const key: ClientsID = this.getName();
        const status = CLIENT_CONFIG.clients[key]?.active?.[right];

        if (typeof status === 'undefined') {
            return false;
        }

        return !!status;
    }

    /**
     * Check if has definition and return it
     */
    public has(group: ClientsSubID, item: string): false | string {
        // @ts-ignore
        return CLIENT_CONFIG.clients?.[this.getName()]?.[group]?.[item] || false;
    }

    /**
     * Client has one lang
     */
    public hasOneLang(): string | null {
        return (CLIENT_CONFIG.clients[this.getName()] && CLIENT_CONFIG.clients[this.getName()].onelang) || null;
    }

    /**
     * Client has default lang
     */
    public hasDefaultLang(): string | null {
        return (CLIENT_CONFIG.clients[this.getName()] && CLIENT_CONFIG.clients[this.getName()].defaultlang) || null;
    }

    public hasBetsImg(): string | null {
        return (CLIENT_CONFIG.clients[this.getName()] &&
        CLIENT_CONFIG.clients[this.getName()].content?.betsImg) || null;
    }

    public hasBetsText(): string | null {
        return (CLIENT_CONFIG.clients[this.getName()] &&
        CLIENT_CONFIG.clients[this.getName()].content?.betsText) || null;
    }

    public getName(): ClientsID {
        return this.name;
    }

    public iconColor(): string | 'black' {
        return CLIENT_CONFIG.clients?.[this.getName()].colors?.icons ?? 'black';
    }

    public getVName(): string {
        return this.nameView;
    }

    public getNameView(): string {
        return this.name;
    }

    public getMainDomain(): string {
        return this.mainDomain;
    }

    public getSocialImage(): string {
        return this.socialImage;
    }

    public getParentDomain(): string {
        return this.parentDomain!;
    }

    public get isParentBetting(): boolean {
        return this.parentType === CLIENT_CONFIG.parentTypes.betting.name;
    }
}
