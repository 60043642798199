<ng-container *ngIf="!mobile">
    <div>
        <form [formGroup]="signInForm.getGroup()">
            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="signInForm.getGroup().errors && (signInForm.getGroup().touched || signInForm.getGroup().dirty)"
            >
                {{ signInForm.getErrorMessage(signInForm.getGroup()) | translate }}
            </div>

            <div class="row">
                <!-- <div class="col-12 pb-4">
                    <ul
                        class="nav nav-tabs nav-tabs--border nav-justified text-uppercase font-weight-bold border-0"
                        id="tabs-login"
                        role="tablist"
                    >
                        <li class="nav-item" (click)="toggleUsePhoneNumber()">
                            <a
                                class="nav-link active border-left-0"
                                id="login-phone-tab"
                                data-toggle="tab"
                                href="#login-phone"
                                role="tab"
                                aria-controls="login-phone"
                                aria-selected="true"
                                >{{ 'web.auth_by_email' | translate }}</a
                            >
                        </li>
                        <li class="nav-item" (click)="toggleUsePhoneNumber()">
                            <a
                                class="nav-link border-right-0"
                                id="login-email-tab"
                                data-toggle="tab"
                                href="#login-email"
                                role="tab"
                                aria-controls="login-email"
                                aria-selected="false"
                                >{{ 'web.auth_by_phone' | translate }}
                            </a>
                        </li>
                    </ul>
                </div> -->
                <div *ngIf="!signInForm.usePhoneNumber.value" class="col-12">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.email' | translate }}</mat-label>
                        <input
                            matInput
                            data-cy="signin-email"
                            placeholder="{{ 'web.enter_email' | translate }}"
                            class="form-control form-control-lg"
                            formControlName="email"
                        />

                        <mat-error *ngIf="signInForm.email?.invalid">{{
                            signInForm.getErrorMessage(signInForm.email) | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="signInForm.usePhoneNumber.value" class="col-12">
                    <div class="form-row">
                        <div class="col-md-4">
                            <select
                                class="form-control form-control-lg"
                                formControlName="phone_number_code"
                                id="phoneNumberCode"
                            >
                                <option *ngFor="let country of countries" value="{{ country.phoneCode }}">
                                    (+{{ country.phoneCode }}) {{ country.name }}
                                </option>
                            </select>

                            <mat-error *ngIf="signInForm.phoneNumberCode?.invalid">{{
                                signInForm.getErrorMessage(signInForm.phoneNumberCode) | translate
                            }}</mat-error>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="legacy" class="d-block">
                                <mat-label>{{ 'web.phone_number' | translate }}</mat-label>
                                <input
                                    matInput
                                    placeholder="{{ 'web.enter_phone_number' | translate }}"
                                    class="form-control form-control-lg"
                                    formControlName="phone_number"
                                />

                                <mat-error *ngIf="signInForm.phoneNumber?.invalid">{{
                                    signInForm.getErrorMessage(signInForm.phoneNumber) | translate
                                }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3 pb-1">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.password' | translate }}</mat-label>
                        <input
                            matInput
                            type="password"
                            data-cy="signin-password"
                            class="form-control form-control-lg"
                            placeholder="{{ 'web.enter_password' | translate }}"
                            formControlName="password"
                        />
                        <mat-error *ngIf="signInForm.password.invalid">{{
                            signInForm.getErrorMessage(signInForm.password) | translate
                        }}</mat-error>
                    </mat-form-field>
                    <p class="mt-3">
                        <label class="livescore-form-input-container"
                            >{{ 'web.rememeber_me' | translate }}
                            <input type="checkbox" formControlName="rememeberMe" />
                            <span class="checkmark"></span>
                        </label>

                        <!-- <mat-checkbox formControlName="rememeberMe">{{ 'web.rememeber_me' | translate }}</mat-checkbox> -->
                    </p>
                </div>
                <div class="col-md-12 align-self-end text-right mb-4 mt-0">
                    <button
                        class="btn btn-primary btn-lg btn-block-xs-only"
                        data-cy="signin-submit"
                        [disabled]="signInForm.getGroup().invalid"
                        (click)="signIn()"
                    >
                        {{ 'web.' + submitText | translate }}
                    </button>
                    <button
                        class="btn btn-danger btn-block mb-3 mb-md-0"
                        *ngIf="signInForm.getGroup().errors !== null"
                        (click)="resetForm()"
                    >
                        {{ 'web.reset_form' | translate }}
                    </button>
                </div>
                <div class="col-md-5 mb-2">
                    <app-social-sign-in
                        type="facebook"
                        [idName]="idName"
                        (onChange)="change($event)"
                    ></app-social-sign-in>
                </div>
                <div class="col-md-7 mb-2">
                    <app-social-sign-in
                        type="google"
                        [idName]="idName"
                        (onChange)="change($event)"
                    ></app-social-sign-in>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-container *ngIf="mobile">
    <div class="py-5 px-3 app-mobile-signin">
        <form [formGroup]="signInForm.getGroup()">
            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="signInForm.getGroup().errors && (signInForm.getGroup().touched || signInForm.getGroup().dirty)"
            >
                {{ signInForm.getErrorMessage(signInForm.getGroup()) | translate }}
            </div>

            <!-- <ul
                class="nav nav-tabs nav-tabs--border nav-justified text-uppercase font-weight-bold border-0 mb-3"
                id="tabs-login"
                role="tablist"
            >
                <li class="nav-item" (click)="toggleUsePhoneNumber()">
                    <a
                        class="nav-link active border-left-0"
                        id="login-phone-tab"
                        data-toggle="tab"
                        href="#login-phone"
                        role="tab"
                        aria-controls="login-phone"
                        aria-selected="true"
                        >{{ 'web.auth_by_email' | translate }}</a
                    >
                </li>
                <li class="nav-item" (click)="toggleUsePhoneNumber()">
                    <a
                        class="nav-link border-right-0"
                        id="login-email-tab"
                        data-toggle="tab"
                        href="#login-email"
                        role="tab"
                        aria-controls="login-email"
                        aria-selected="false"
                        >{{ 'web.auth_by_phone' | translate }}
                    </a>
                </li>
            </ul> -->

            <ng-container *ngIf="!signInForm.usePhoneNumber.value">
                <mat-form-field appearance="legacy" class="d-block">
                    <mat-label>{{ 'web.email' | translate }}</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'web.enter_email' | translate }}"
                        class="form-control"
                        formControlName="email"
                    />

                    <mat-error *ngIf="signInForm.email?.invalid">{{
                        signInForm.getErrorMessage(signInForm.email) | translate
                    }}</mat-error>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="signInForm.usePhoneNumber.value">
                <div class="row">
                    <div class="col-12">
                        <select class="form-control" formControlName="phone_number_code" id="phoneNumberCode">
                            <option *ngFor="let country of countries" value="{{ country.phoneCode }}">
                                (+{{ country.phoneCode }}) {{ country.name }}
                            </option>
                        </select>

                        <mat-error *ngIf="signInForm.phoneNumberCode?.invalid">{{
                            signInForm.getErrorMessage(signInForm.phoneNumberCode) | translate
                        }}</mat-error>
                    </div>
                    <div class="col-12 mt-3">
                        <mat-form-field appearance="legacy" class="d-block">
                            <mat-label>{{ 'web.phone_number' | translate }}</mat-label>
                            <input
                                matInput
                                placeholder="{{ 'web.enter_phone_number' | translate }}"
                                class="form-control"
                                formControlName="phone_number"
                            />

                            <mat-error *ngIf="signInForm.phoneNumber?.invalid">{{
                                signInForm.getErrorMessage(signInForm.phoneNumber) | translate
                            }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <mat-form-field appearance="legacy" class="d-block">
                <mat-label>{{ 'web.password' | translate }}</mat-label>
                <input
                    matInput
                    type="password"
                    placeholder="{{ 'web.enter_password' | translate }}"
                    formControlName="password"
                />
                <mat-error *ngIf="signInForm.password.invalid">{{
                    signInForm.getErrorMessage(signInForm.password) | translate
                }}</mat-error>
            </mat-form-field>
            <p>
                <label class="livescore-form-input-container"
                    >{{ 'web.rememeber_me' | translate }}
                    <input type="checkbox" formControlName="rememeberMe" />
                    <span class="checkmark"></span>
                </label>
                <!-- <mat-checkbox formControlName="rememeberMe">{{ 'web.rememeber_me' | translate }}</mat-checkbox> -->
            </p>

            <button
                type="submit"
                class="btn btn-primary btn-block mb-3"
                [disabled]="signInForm.getGroup().invalid"
                (click)="signIn()"
            >
                {{ 'web.' + submitText | translate }}
            </button>

            <div class="row mx-n1 mt-3 text-uppercase btns-login">
                <div class="col px-1">
                    <app-social-sign-in
                        type="facebook"
                        [idName]="idName"
                        (onChange)="change($event)"
                    ></app-social-sign-in>
                </div>
                <div class="col px-1 d-flex justify-content-center" [class.mt-4]="idName !== 'standalone'">
                    <app-social-sign-in
                        type="google"
                        [idName]="idName"
                        (onChange)="change($event)"
                    ></app-social-sign-in>
                </div>
            </div>

            <div class="text-center mt-2">
                <a
                    [routerLink]="['/page/sport/match-list/soccer-5']"
                    (click)="goToRegistration()"
                    [queryParams]="{ registration: 1 }"
                    >{{ 'web.registration' | translate }}</a
                >
            </div>

            <div class="border-top mt-3 pt-3 d-flex" *ngIf="forgotenPassword">
                <svg class="icon mr-2 mt-1">
                    <use href="#icon-info"></use>
                </svg>
                <div>
                    {{ 'web.forgotten_password_text' | translate }}
                    <a
                        [routerLink]="['/page/sport/match-list/soccer-5']"
                        [queryParams]="{ signin: 1 }"
                        class="font-weight-bold text-reset text-underline"
                        >{{ 'web.forgotten_password' | translate }}</a
                    >.
                </div>
            </div>
        </form>
    </div>
</ng-container>
