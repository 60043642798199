<ng-container *ngIf="isSet">
    <ng-container *ngIf="!isFavorite()">
        <ng-container *ngIf="isTournament()">
            <svg [attr.class]="'app-cursor ' + classDefVal()" (click)="toggle($event)">
                <use xlink:href="#icon-star" /></svg
        ></ng-container>
        <ng-container *ngIf="isMatchListMobile()">
            <svg [attr.class]="' icon icon--14 icon--primary ng-star-inserted mb-1'" (click)="toggle($event)">
                <use xlink:href="#icon-star" />
            </svg>
            {{ 'web.favm' | translate }}</ng-container
        >
        <ng-container *ngIf="isMatch()">
            <div (click)="toggle($event)" class="d-flex align-self-center justify-content-center">
                <svg [attr.class]="'app-cursor ' + classMatchDefVal()"><use xlink:href="#icon-star" /></svg>
            </div>
        </ng-container>
        <ng-container *ngIf="isMatchDetail()">
            <div (click)="toggle($event)" class="d-flex align-self-center justify-content-center">
                <svg [attr.class]="'app-cursor ' + classMatchDetailDefVal()">
                    <use xlink:href="#icon-star" />
                </svg>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isFavorite()">
        <ng-container *ngIf="isTournament()"
            ><svg [attr.class]="'app-cursor is-favourite ' + classDefVal()" (click)="toggle($event)">
                <use xlink:href="#icon-star-fill" /></svg
        ></ng-container>
        <ng-container *ngIf="isMatchListMobile()"
            ><svg
                [attr.class]="' is-favourite icon icon--14 icon--primary ng-star-inserted mb-1'"
                (click)="toggle($event)"
            >
                <use xlink:href="#icon-star-fill" />
            </svg>
            {{ 'web.favm' | translate }}
        </ng-container>
        <ng-container *ngIf="isMatch()">
            <div (click)="toggle($event)" class="d-flex align-self-center justify-content-center is-favourite">
                <svg [attr.class]="'app-cursor ' + classMatchDefVal()"><use xlink:href="#icon-star-fill" /></svg>
            </div>
        </ng-container>
        <ng-container *ngIf="isMatchDetail()">
            <div (click)="toggle($event)" class="d-flex align-self-center justify-content-center is-favourite">
                <svg [attr.class]="'app-cursor ' + classMatchDetailDefVal()">
                    <use xlink:href="#icon-star-fill" />
                </svg>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
