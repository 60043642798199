/**
 *  Base form abstract
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import * as _ from 'underscore';
import { FormErrorInterface } from 'src/app/interfaces/form-response.interface';

import { FormInterface } from './interfaces/form.interface';
import ERROR_MESSAGES from './error-messages.config';

@Injectable({
    providedIn: 'root',
})
export class BaseForm implements FormInterface {
    protected controls: { [prop: string]: FormControl };

    public formGroup: FormGroup;

    protected customControlErrorMessage: {
        [prop: string]: { [prop: string]: string };
    } = {};

    public constructor() {}

    /**
     * Initialize form Controls
     * @return {void}
     */
    protected init(): void {
        this.controls = {};
    }

    /**
     * Get error message for control
     * !!YOU MUST DEFIEN NEW ERRORS TYPE HERE!!
     * @param  control [description]
     * @param  {string} name
     * @return     {string}
     */
    public getErrorMessage(
        control: AbstractControl | null | FormControl | FormGroup,
        name?: string,
    ): string {
        let msg = '';

        if (control instanceof FormControl) {
            const keys = Object.keys(control.errors!);
            for (let x = 0; x < keys.length; x += 1) {
                if (control.hasError(keys[x])) {
                    // @ts-ignore
                    msg = `${ERROR_MESSAGES[keys[x]]}${this.extraError(
                        control,
                        keys[x],
                        name,
                    )}`;
                }
            }
        } else if (control instanceof FormGroup) {
            const keys = Object.keys(control.errors!);
            for (let x = 0; x < keys.length; x += 1) {
                if (control.hasError(keys[x])) {
                    // @ts-ignore
                    msg = ERROR_MESSAGES[keys[x]];
                }
            }
        }

        return msg;
    }

    /**
     * Handling extra error informations
     * @param  {FormControl | FormGroup} control
     * @param  {string} er
     * @param  {string} name
     * @return  {string}
     */
    public extraError(control: FormControl, er: string, name?: string): string {
        let extra = '';
        const error = control.getError(er);
        if (er === 'minlength' || er === 'maxlength') {
            extra = `${error.requiredLength}`;
        } else if (er === 'size') {
            extra = `${error.requiredSize}`;
        } else if (
            er === 'pattern' &&
            !_.isUndefined(this.customControlErrorMessage[name as string].pattern)
        ) {
            extra = this.customControlErrorMessage[name as string].pattern;
        }
        return extra;
    }

    public setErrors(er: FormErrorInterface): void {
        if (!_.isUndefined(er.global)) {
            let globalError = {};
            _.each(er.global!, (val): void => {
                globalError = { ...globalError, [val]: true };
            });
            this.getGroup().setErrors(globalError);
        }
        if (!_.isUndefined(er.control)) {
            _.each(er.control!, (val, key): void => {
                let controlError = {};
                _.each(val, (e): void => {
                    const [k, ...rules] = e.split('|');
                    let rule = {};
                    if (rules.length === 0) {
                        rule = true;
                    } else {
                        _.each(rules, (r): void => {
                            const [k2, v2] = r.split(':');
                            rule = { ...rule, [k2]: v2 };
                        });
                    }
                    controlError = { ...controlError, [k]: rule };
                });
                // @ts-ignore
                this[key].setErrors(controlError);
            });
        }
    }

    /**
     * Get all form controls
     * @return {{ [prop: string]: FormControl }}
     */
    public getControls(): { [prop: string]: FormControl } {
        return this.controls;
    }

    /**
     * Get fprm group
     * @return {FormGroup}
     */
    public getGroup(): FormGroup {
        return this.formGroup;
    }

    /**
     * Patch form group value
     * @param  {object} values
     * @return  {void}
     */
    public patchValue(values: Record<string, any>): void {
        this.formGroup.patchValue(values);
    }

    // /////////////////
    // CUSTOM VALIDATORS
    // /////////////////

    /**
     * Validator test two controls for same value
     * @param  controlA
     * @param  controlB
     * @param  errorMsg - error index
     * @return          [description]
     */
    protected mustHaveSameValueValidator(
        controlA: string,
        controlB: string,
        errorMsg: string,
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const c1 = control.get(controlA);
            const c2 = control.get(controlB);
            return c1 && c2 && c1.value !== c2.value
                ? { [errorMsg]: true }
                : null;
        };
    }
}
