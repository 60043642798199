<app-light-header [class.d-none]="mode"></app-light-header>
<div *ngIf="loadedError; else noError" class="pb-6">
    <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
        <div class="text-center pt-7">
            <svg class="icon icon--64 mb-4"><use [attr.xlink:href]="'#icon-' + 'soccer'" /></svg>
            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
            <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
        </div>
    </div>
</div>
<ng-template #noError>
    <div class="container bg-light position-relative">
        <app-page-loader></app-page-loader>
    </div>
</ng-template>
