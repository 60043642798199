/**
 *  App states
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { Version } from '../actions/app.action';

interface AppModel {
    version: string;
}
@State<AppModel>({
    name: 'branchapp',
    defaults: {
        version: '1.0',
    },
})
@Injectable()
export class BranchAppState {
    /**
     * Get nav state
     * @return {boolean}
     */
    @Selector()
    public static appVersion(state: AppModel): string {
        return state.version;
    }

    /**
     * Set/update user ddata
     */
    @Action(Version)
    public setVersion(ctx: StateContext<AppModel>, { version }: Version): void {
        ctx.patchState({
            version,
        });
    }
}
