/* eslint-disable @typescript-eslint/naming-convention */

/**
 *  Alternative en translations if normal trans not loaded
 */
export default {
    'web.competitions': 'Competitions',
    'web.about_us': 'About us',
    'web.live_betting': 'Live betting',
    'web.lotery': 'Lottery',
    'web.casino': 'Casino',
    'web.contact_us': 'Contact us',
    'web.page_conditions': 'Conditions',
    'web.advertisement': 'Advertisement',
    'web.privacy_cookies': 'Privacy and Cookies',
    'web.venues': 'Venues',
    // eslint-disable-next-line max-len
    'web.footer_text': 'Football live scores on 24live livescore has live coverage from more than 800 world allround soccer leagues, tournaments and cups with live matches, match trackers, league tables, statistics and fixtures. Live matches from all football leagues have fast and precise updates for scores, halftime and full time football results, match trackers, goal scorers and assistants, squads, statistics, substitutions and cards. Match trackers are available for most popular football leagues: England Premier League, Spain La Liga, Italy Serie A, German Bundesliga, France Ligue 1, club competitions like UEFA Champions League, Europa league and International tournaments like World Championship, European Championship, Olympics. 24live livescore app has many details for each team where you can view last soccer matches, results, tables, statistics, squads and much more. In match details you can also find prematch odds. Following your livescores, stats and results is much easier with adding football matches or teams to your Favorites.',

    'web.matches': 'Matches',
    'web.menu': 'Menu',
    'web.races': 'Races',
    'web.livescore': 'Livescore',
    'web.search': 'Search',
    'web.my_matches_num': 'Favorites',
    'web.all_matches': 'All',
    'web.live_matches': 'Live',
    'web.finished_matches': 'Finished',
    'web.future_matches': 'Upcoming',
    'web.overview': 'Overview',
    'web.league_table': 'Table',
    'web.cup_tree': 'Draw',
    'web.today': 'Today',
    'web.tomorrow': 'Tomorrow',
    'web.detail': 'Detail',
    'web.not_started_matches': 'Not started',
    'web.latest_matches': 'Finished',
    'web.player-statistics': 'Players',
    'web.players': 'Squad',
    'web.tab_odds': 'Odds',
    'web.info': 'Match info',
    'web.tracker': 'Tracker',
    'web.sign_in': 'Login',
    'web.registration': 'Registration',
    'web.user_account': 'Account',
    'web.choose_sport': 'Choose sport',
    'sport.soccer': 'Football',
    'sport.ice-hockey': 'ICE Hockey',
    'sport.tennis': 'Tennis',
    'sport.basketball': 'Basketball',
    'sport.handball': 'Handball',
    'sport.volleyball': 'Volleyball',
    'sport.mma': 'MMA',
    'sport.esport': 'Esport',
    'sport.baseball': 'Baseball',
    'sport.rugby': 'Rugby',
    'sport.american-football': 'American Football',
    'sport.snooker': 'Snooker',
    'sport.darts': 'Darts',
    'sport.cricket': 'Cricket',
    'sport.futsal': 'Futsal',
    'sport.floorball': 'Floorball',
    'sport.golf': 'Golf',
    'sport.motor-sport': 'Motorsport',
    'sport.speedway': 'Speedway',
    'sport.waterpolo': 'Waterpolo',
    'sport.boxing': 'Boxing',
    'sport.badminton': 'Badminton',
    'sport.field-hockey': 'Field hockey',
    'sport.squash': 'Squash',
    'sport.bandy': 'Bandy',
    'sport.table-tennis': 'Table tennis',
    'sport.beach-soccer': 'Beach soccer',
    'sport.beach-volley': 'Beach volleyball',
    'web.empty_all_short': 'Delete all',
    'web.empty_all': 'Delete all Matches',
    'web.match_statistics': 'Statistics',
    'web.match_players': 'Lineups',
    'web.match_h2h': 'H2H',
    'web.funfacts': 'Attractions',
    'web.match_actions': 'Match actions',
    'web.match_info': 'Informations',
    'web.detail_score': 'Score',
    'web.tennis_rankings': 'Rankings',
    'web.hp_sport_results': 'Livescore',
    'web.hp_main_text': ' - ',
    'web.sticky_match_detail': 'Stick',
    'web.sound_switch': 'Sound',
    'web.intro_next': 'Next',
    'web.intro_next_end': 'Continue',
    'web.intro_step1': 'Menu',
    'web.intro_step2': 'Navigation',
    'web.sticky_dark_mode': 'Dark mode',
    'web.possible_chance': 'Possible chance',
    'web.close': 'close',
    'web.microsite': 'Company',
};
