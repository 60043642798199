<div class="dropdown app-lang">
    <button
        class="nav-link text-center btn btn-link"
        [class.text-white]="isMobile"
        [class.border-0]="isMobile"
        type="button"
        id="dropdown-langs"
        data-cy="btn-langs"
        [attr.data-toggle]="client.hasOneLang() ? 'none' : 'dropdown'"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <img
            *ngIf="flags && activeImg"
            [src]="'/img/flags/1x1/' + activeImg"
            alt="{{ 'web.lang_' + activeIso | translate }}"
            [attr.width]="isMobile ? 20 : 24"
            [attr.height]="isMobile ? 20 : 24"
            loading="lazy"
            [class.mb-1]="!isMobile"
            class="rounded-circle app-participant-logo"
        />
        <ng-container *ngIf="!isMobile; else notMobile">
            <br />
            <span *ngIf="loaded">{{ 'web.lang_' + activeIso | translate }}</span>
            <span *ngIf="!loaded">{{ 'web.loading' | translate }}</span>
        </ng-container>
        <ng-template #notMobile>
            <span class="text-uppercase"> {{ activeIso }}</span>
        </ng-template>
    </button>

    <div
        class="dropdown-menu dropdown-menu-right dropdown-menu--langs pt-1 mt-1"
        data-cy="nav-langs"
        aria-labelledby="dropdown-langs"
    >
        <h6 class="text-white">{{ 'web.choose_lang' | translate }}</h6>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="row no-gutters">
            <div class="col-6" *ngFor="let lang of filter(langObj)">
                <a
                    (click)="activeLang(lang.iso, lang.image, false)"
                    [href]="pathname + '?lang=' + lang.iso"
                    class="app-cursor"
                    [attr.hreflang]="lang.iso"
                    [attr.lang]="lang.iso"
                    data-cy="nav-lang-item"
                >
                    <img
                        *ngIf="flags"
                        [src]="'/img/flags/1x1/' + lang.image"
                        alt="{{ 'web.lang_' + activeIso | translate }}"
                        width="20"
                        height="20"
                        loading="lazy"
                        class="mr-1 rounded-circle app-participant-logo"
                    />
                    {{ 'web.lang_' + lang.iso | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
