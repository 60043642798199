/**
 *  Component for sign in and off states of user
 *  Should be on every page
 *  @author Livescore <@score-stats.com>
 *  @copyright 2019 livescore
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/store/states/user.state';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ClientService } from '@services/client.service';

import { AuthetificationDialogComponent } from '../authetification/authetification.component';
import { BaseComponent } from '../../base.component';

import { SnackBarService } from '@/services/snack-bar.service';

@Component({
    selector: 'app-info-widget',
    templateUrl: './info-widget.component.html',
})
export class InfoWidgetComponent extends BaseComponent implements OnInit {
    @Select(UserState.isLoggedIn) public isLoggedIn$: Observable<boolean>;

    @Select(UserState.data) public userData$: Observable<UserInterface>;

    @Input()
    public signOffBtn: boolean = false;

    @Input()
    public mobile: boolean = false;

    @Output()
    public action: EventEmitter<string> = new EventEmitter();

    public signOffProgress: boolean = false;

    public constructor(
        public user: UserService,
        public dialog: MatDialog,
        private router: Router,

        private translate: TranslateService,
        public deviceService: DeviceDetectorService,
        public client: ClientService,
        private snackbar: SnackBarService,
    ) {
        super();
    }

    public ngOnInit(): void {
        try {
            if (!this.mobile) {
                // const resp = await this.user.getStatus();
            }
        } catch (e) {
            //
        }
    }

    public openSnackBar(message: string, action: 'info' | 'alert'): void {
        this.snackbar.openSnackBar(message, 'top', action);
    }

    public goToAccount(): void {
        this.action.emit('goToPage');
    }

    /**
     * Sign off
     * @return {void]}
     */
    public signOff(): void {
        this.signOffProgress = true;
        this.user.signOff().then(
            (): void => {
                this.signOffProgress = false;
                this.router.navigate(['/hp'], { queryParams: { signoff: 1 } });
            },
            (): void => {
                this.signOffProgress = false;
                this.openSnackBar(this.translate.instant('sign_off_failed'), 'alert');
            },
        );
    }

    /**
     * Open sign in dialog
     * @return {void]}
     */
    public signIn(): void {
        this.action.emit('signIn');
        this.openModal('signin');
    }

    /**
     * Open sign in dialog
     * @return {void}
     */
    public registration(): void {
        this.openModal('registration');
    }

    public openModal(type: string = 'signin', redirectUrl: string | null = null): void {
        redirectUrl = redirectUrl !== null ? redirectUrl : this.router.url;
        const DialogRef = this.dialog.open(AuthetificationDialogComponent, {
            maxWidth: '900px',
            data: { type, redirectUrl },
        });

        DialogRef.afterClosed().subscribe((): void => {
            // console.log('The dialog was closed');
        });
    }
}
