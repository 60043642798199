export default {
    en: [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ],
    cs: [
        'a',
        'b',
        'c',
        'č',
        'd',
        'e',
        'f',
        'g',
        'h',
        'ch',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'ó',
        'p',
        'q',
        'r',
        'ř',
        's',
        'š',
        't',
        'ť',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'ž',
    ],
    tr: [
        'a',
        'â',
        'b',
        'c',
        'ç',
        'd',
        'e',
        'f',
        'g',
        'h',
        'ch',
        'i',
        'ı',
        'î',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'ó',
        'ö',
        'p',
        'q',
        'r',
        's',
        'ş',
        't',
        'u',
        'û',
        'ü',
        'v',
        'w',
        'x',
        'y',
        'z',
    ],
    ru: [
        'a',
        'б',
        'ц',
        'ч',
        'д',
        'е',
        'э',
        'ф',
        'г',
        'x',
        'и',
        'й',
        'ю',
        'я',
        'к',
        'л',
        'м',
        'н',
        'o',
        'п',
        'р',
        'с',
        'ш',
        'щ',
        'т',
        'у',
        'в',
        'ы',
        'з',
        'ж',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
    ],
};
