import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import APP_CONFIG from 'src/app/config/app.config';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ClientService } from '@services/client.service';
import { Select, Store } from '@ngxs/store';
import { DarkMode, MatchNotificationMode } from '@store/actions/page.action';
import $ from '@shared/jquery';

import { propertyMetada } from '@services/decorators/propety.decorator';
import { Observable } from 'rxjs';
import { PageState } from '@store/states/page.state';

const SECRET = APP_CONFIG.secret;

@Component({
    selector: 'app-switchers',
    templateUrl: './switchers.component.html',
})
export class SwitchersComponent implements OnInit {
    @ViewChild('stickyVal2', { static: true })
    private openElement: HTMLInputElement;

    @Select(PageState.matchNtfMode) matchNtfMode$: Observable<boolean>;

    @Input()
    public mobile: boolean = false;

    @Input()
    public options: { showOpenAll: boolean } = { showOpenAll: false };

    @Output()
    public onStickyChange: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public onOpenChange: EventEmitter<boolean> = new EventEmitter();

    public envDev: boolean = false;

    public matchNotification = false;


    public constructor(protected page: PageService, private storage: StorageService, private store: Store,
        private router: ActivatedRoute, public client: ClientService) {}

    public ngOnInit(): void {
        this.onStickyChange.emit(this.sticky);
        this.envDev = environment.developement;

        this.router.queryParamMap.subscribe((param: ParamMap): void => {
            if (param.has(SECRET)) {
                this.storage.set(SECRET, true);
            }
        });
        const mql = window.matchMedia('(prefers-color-scheme: dark)');
        if (mql.matches && !this.storage.isset(APP_CONFIG.switchStickyKeyPageMode) &&
        this.client.use('pageDarkMode')) {
            this.store.dispatch(new DarkMode(true));
        }

        this.matchNtfMode$.subscribe((mode: boolean) => {
            this.matchNotification = mode;
        });
    }

    /**
     * Special case to open
     * @return {boolean}
     */
    public get shouldOpen(): boolean {
        return !!(this.storage.isset(SECRET) && this.storage.get(SECRET));
    }

    /**
     * Fires on sticky switch change
     * @event SwitchersComponent#stickyEvent
     * @param {boolean} $event
     */
    public stickyEvent($event: boolean): void {
        this.onStickyChange.emit($event);
    }

    public onModeChange(mode: boolean): void {
        setTimeout(() => {
            $('.dropdown-toggle-switcher').dropdown('show');
        });
        this.store.dispatch(new DarkMode(mode));
    }

    /**
     * Get sticky switch
     * @return {boolean}
     */
    public get isWakeLock(): boolean {
        return this.storage.isset(APP_CONFIG.wakeLockkey);
    }

    /**
     * Fires on open all
     * @event SwitchersComponent#onOpenEvent
     * @param {boolean} $event
     */
    public onOpenEvent($event: boolean): void {
        setTimeout(() => {
            $('.dropdown-toggle-switcher').dropdown('show');
        });
        this.onOpenChange.emit($event);
    }

    @propertyMetada({
        description: 'Fires change event',
        event: 'SwitchersComponent#onMatchNotificationChange',
    })
    public onMatchNotificationChange(change: boolean): void {
        setTimeout(() => {
            $('.dropdown-toggle-switcher').dropdown('show');
        });
        this.store.dispatch(new MatchNotificationMode(change));
    }

    /**
     * Get sticky switch
     * @return {boolean}
     */
    public get stickyPageMode(): boolean {
        return this.store.selectSnapshot<boolean>(
            state => state.page.darkMode || false,
        );
    }

    /**
     * Get sticky switch
     * @return {boolean}
     */
    public get wakeLockMode(): boolean {
        return !!this.storage.get(APP_CONFIG.wakeLockkey);
    }

    /**
     * Get sticky switch
     * @return {boolean}
     */
    public get sticky(): boolean {
        if (this.storage.isset<boolean>(APP_CONFIG.switchStickyKey)) {
            return this.storage.get<boolean>(APP_CONFIG.switchStickyKey) as boolean;
        }
        return true;
    }

    public async onWakeLockChange(mode: boolean): Promise<void> {
        setTimeout(() => {
            $('.dropdown-toggle-switcher').dropdown('show');
        });
        if (!mode) {
            await this.page.releaseWakeLock();
        } else if (mode) {
            await this.page.setwakeLock();
        }
    }
}
