/**
 *  Configuration for urls and hosts
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

export default {
    api: {
        getSports: '/api/get-sports',
        getLangs: '/api/get-langs',
        getNavigationLevel1: '/api/navigation/{sportId}',
        getNavigationLevel2: '/api/navigation/{sportId}/{categoryId}',
        getTournament: '/api/tournament/{tournamentId}',
        getCategory: '/api/match-list-category/{sportId}',
        getMatchList: '/api/match-list-data/{sportId}',
        getLiveMatchList: '/generated/match-list/live/data-{sportId}-{lang}.json',
        getMatch: '/api/match/{id}',
        getMatchWidget: '/api/widgets/match/{id}',
        getMatchCache: '/match.php?id={id}',
        getStageListCategories: '/api/stage-list-categories/{sportId}',
        getGolfTournamentsByDate: '/api/golf-tournaments-by-date',
        getGolfRounds: '/api/golf-rounds',
        getGolfParticipant: '/api/golf-participant/{participantId}',
        getGolfTournamentDetail: '/api/golf-tournament/{id}',
        getStageStandings: '/api/stage-standings/{stageId}',
        getStageParticipant: '/api/stage-participant/{participantId}',
        getStageEvent: '/api/stage-event/{stageId}',
        getStageEventParticipant: '/api/stage-event/{stageId}/{participantId}',
        getCupTree: '/api/cuptree/{seasonId}',
        getLeagueTable: '/api/table/{seasonId}',
        getPlayer: '/api/player/{id}',
        getPlayerStats: '/api/player-stats/{seasonId}',
        getParticipant: '/api/participant/{participantId}',
        getParticipantCompare: '/api/participant-compare/{first}/{second}',
        getSearch: '/api/search',
        getMyMatches: '/api/my-matches',
        getMyMatchesBr: '/api/my-matches-br',
        getMyMatchesSource: '/api/my-matches-source',
        getInfo: '/api/get-info',
        ads: '/api/ads',
        uniqueURL: '/api/unique-url',
        getCountries: '/api/get-countries',
        getStatus: '/api/account/status',
        signOff: '/api/account/sign-off',
        signIn: '/api/account/sign-in',
        signInSocial: '/api/account/sign-in-social',
        registration: '/api/account/registration',
        userVerify: '/api/account/active/{code}',
        forgottenPassword: '/api/account/forgotten-password',
        resetPassword: '/api/account/reset-password',
        tennisRanking: '/api/tennis-rankings/{type}',
        advertiseForm: '/api/advert',
        staticPage: '/api/static-page/{key}',
        betMarkets: '/api/markets',
        betOdds: '/api/odds',
        vapid: '/api/vapid',
        participantForm: 'api/widgets/get-form/{pid}',
        missingTranslations: '/api/missing-trans',
        updMyMatches: '/api/update-my-matches',
        banners: '/api/banners',
        venues: '/api/venues',
        venue: '/api/venue/{venueId}',
        aiQA: 'api/v1/openai/qa',
        participantStandings(seasonId: number | undefined, subtournamentId: number, participantId: number): string {
            return `api/table/participant-history/${seasonId}/${subtournamentId}/${participantId}`;
        },
        stats: {
            sportMatches: '/api/stats/sport-matches',
        },
        rankings: {
            badminton: '/api/rankings/badminton',
            golf: '/api/rankings/golf',
            darts: '/api/rankings/darts',
            snooker: '/api/rankings/snooker',
            'table-tennis': '/api/rankings/table-tennis',
        },
    },
    video: {
        youtubeScreenshots: '/assets/matches/youtube-preview',
        stageYoutubeScreenshots: '/assets/stages/youtube-preview',
    },
};
