/**
 *  General App config
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

export default {
    userStorageKey: 'branch_user',
    defaultClient: '24live',
    defaultLang: 'en',
    scrollOffset: 10,
    liveBarHeight: 255,
    scrollSpeed: [[0.2, 800], [0.4, 800], [0.5, 500], [0.8, 400], [1, 200]],
    navTopOpenPos: 200, // number of pixel dom top of viewport to open nav
};
