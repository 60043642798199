<footer class="app-footer-parent">
    <!-- <div class="container small p-0 app-footer">
        <div
            *ngIf="client.has('active', 'partners')"
            class="app-footer__partners d-flex flex-nowrap align-items-stretch"
        >
            <div class="app-footer__partners__headline align-self-center">{{ 'web.partners' | translate }}</div>
            <div class="d-flex justify-content-around flex-fill py-1 py-lg-3">
                <a href="https://www.fkjablonec.cz/" target="_blank" class="d-flex">
                    <img
                        src="/img/partners/fcjablonec.png"
                        loading="lazy"
                        alt="FC Jablonec"
                        class="img-fluid img-fluid--wauto"
                    />
                </a>
                <a href="https://rugbyunion.cz/" target="_blank" class="d-flex">
                    <img
                        src="/img/partners/czrugby.png"
                        loading="lazy"
                        alt="Česká rugbyová unie"
                        class="img-fluid img-fluid--wauto"
                    />
                </a>
                <a href="https://www.apollogames.cz/" target="_blank" class="d-none d-sm-flex">
                    <img
                        style="max-height: 70%"
                        src="/img/partners/Apollo-Games.svg"
                        loading="lazy"
                        alt="Apollo games"
                        class="img-fluid img-fluid--wauto"
                    />
                </a>
                <a href="https://betboys.cz/" target="_blank" class="d-none d-md-flex">
                    <img
                        style="max-height: 45%"
                        src="/img/partners/betboys.png"
                        loading="lazy"
                        alt="Betboys"
                        class="img-fluid img-fluid--wauto align-self-center"
                    />
                </a>
            </div>
        </div>
    </div> -->
    <div class="container p-4 small app-footer">
        <div class="row d-sm-none" *ngIf="isMobile">
            <div class="col">
                <app-switchers [mobile]="isMobile"></app-switchers>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 order-md-3 mt-3 text-right" [class.text-right]="!isMobile">
                <i class="fas fa-clock"></i> &nbsp; <strong>{{ actTime | livDate : 'localDateTime' }}</strong>
                <div
                    *ngIf="client.has('active', 'partners')"
                    class="d-flex flex-column align-items-center justify-content-center"
                    style="height: 100%"
                >
                    <div class="align-self-top text-right pb-1">{{ 'web.partners' | translate }}</div>
                    <a href="https://www.fkjablonec.cz/" target="_blank" class="d-flex align-self-right">
                        <img
                            style="height: 6rem"
                            src="/img/partners/fcjablonec.png"
                            loading="lazy"
                            alt="FC Jablonec"
                            class="img-fluid img-fluid--wauto"
                        />
                    </a>
                </div>
            </div>
            <div class="col-md-7 order-md-2">
                <nav>
                    <ul class="nav mb-3">
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                data-cy="page-about-us"
                                [routerLink]="['/page/see/about-us']"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.about_us' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.about_us' | transinit }}</ng-template>
                            </a>
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'about_us')"
                                [href]="client.has('pages', 'about_us')"
                                >{{ 'web.about_us' | translate }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/page/see/livescore']"
                                data-cy="page-livescore"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.livescore' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.livescore' | transinit }}</ng-template>
                            </a>
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'about_us')"
                                [href]="client.has('pages', 'about_us')"
                                >{{ 'web.about_us' | translate }}</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'livebet')"
                                [href]="client.has('pages', 'livebet')"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.live_betting' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.live_betting' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'lotery')"
                                [href]="client.has('pages', 'lotery')"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.lotery' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.lotery' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'casino')"
                                [href]="client.has('pages', 'casino')"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.casino' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.casino' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" *ngIf="client.use('pagesInternal')" [routerLink]="['/page/see/advert']">
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.advertisement' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.advertisement' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/page/see/contact']"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.contact_us' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.contact_us' | transinit }}</ng-template>
                            </a>

                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'contact')"
                                [href]="client.has('pages', 'contact')"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.contact_us' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.contact_us' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/page/see/application']"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.mobile_application' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>Application</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/page/sport/venues']">
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.venues' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.venues' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/page/see/condition']"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.page_conditions' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.page_conditions' | transinit }}</ng-template>
                            </a>
                            <a
                                class="nav-link"
                                *ngIf="!client.use('pagesInternal') && client.has('pages', 'condition')"
                                [href]="client.has('pages', 'condition')"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.page_conditions' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.page_conditions' | transinit }}</ng-template>
                            </a>
                        </li>

                        <li class="nav-item">
                            <a
                                class="nav-link"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/page/see/privacy']"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.privacy_cookies' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.privacy_cookies' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                rel="external"
                                *ngIf="client.has('social', 'microsite')"
                                [href]="client.has('social', 'microsite')"
                                target="_blank"
                            >
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.livescore_solution' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.livescore_solution' | transinit }}</ng-template>
                            </a>
                        </li>
                        <li *ngIf="client.has('social', 'blog')" class="nav-item">
                            <a class="nav-link" [href]="client.has('social', 'blog')">
                                <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                                    {{ 'web.blog' | translate | transinit }}
                                </ng-container>
                                <ng-template #notLoaded>{{ 'web.blog' | transinit }}</ng-template>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a
                                class="nav-link app-cursor"
                                *ngIf="client.use('pagesInternal')"
                                [routerLink]="['/hp']"
                                [queryParams]="{ registration: 1 }"
                                >{{ 'web.registration' | translate }}</a
                            >
                        </li> -->
                        <!-- <li class="nav-item" *ngIf="isMobile">
                            <a class="nav-link" routerLink="/page/see/add-to-home">{{
                                'web.add_to_homescreen' | translate
                            }}</a>
                        </li> -->
                        <li class="nav-item" *ngIf="ntfDecline">
                            <a class="nav-link app-cursor" (click)="activeNtf($event)">{{
                                'web.active_notification' | translate
                            }}</a>
                        </li>
                    </ul>
                </nav>

                <ng-container *ngIf="!client.has('pages', 'footerText')">
                    <ng-container *ngIf="isTranslateLoaded; else notLoaded">
                        <p
                            *ngIf="sportName"
                            class="text-justify"
                            [innerHTML]="'web.footer_text_' + sportName | translate | markdown"
                        ></p>
                        <p
                            *ngIf="!sportName"
                            class="text-justify"
                            [innerHTML]="'web.footer_text' | translate | markdown"
                        ></p>
                    </ng-container>
                    <ng-template #notLoaded>
                        <p class="text-justify">{{ 'web.footer_text' | transinit }}</p>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="client.has('pages', 'footerText')">
                    {{ client.has('pages', 'footerText') }}
                </ng-container>
            </div>
            <div class="col-md-3 order-md-1 footer__social">
                <img
                    [src]="'img/' + client.getName() + '.png?v2'"
                    alt="24 Live"
                    height="50"
                    widt="100"
                    class="mb-3 mt-2 footer__logo app-participant-logo"
                    [alt]="client.getName()"
                />
                <p>
                    Copyright © {{ time.format('Y') }} {{ 'web.client_footer_' + client.getName() | translate }}<br />{{
                        'web.allrights_reserved' | translate
                    }}
                    <br />
                    version {{ package.version }}
                </p>
                <ng-container *ngIf="client.use('social')">
                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'facebook')"
                        [href]="client.has('social', 'facebook')"
                        aria-label="Facebook"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-facebook" /></svg
                    ></a>
                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'instagram')"
                        [href]="client.has('social', 'instagram')"
                        aria-label="Instagram"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-instagram" /></svg
                    ></a>

                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'youtube')"
                        [href]="client.has('social', 'youtube')"
                        aria-label="Youtube"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-youtube" /></svg
                    ></a>
                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'linkedin')"
                        [href]="client.has('social', 'linkedin')"
                        aria-label="Linkedin"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-linkedin" /></svg
                    ></a>

                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'twitter')"
                        [href]="client.has('social', 'twitter')"
                        aria-label="Twitter"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-twitter" /></svg
                    ></a>

                    <a target="_blank" *ngIf="client.has('social', 'tiktok')" [href]="client.has('social', 'tiktok')"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-tiktok" /></svg
                    ></a>
                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'telegram')"
                        [href]="client.has('social', 'telegram')"
                        aria-label="Telegram"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-telegram" /></svg
                    ></a>

                    <a
                        target="_blank"
                        *ngIf="client.has('social', 'pinterest')"
                        [href]="client.has('social', 'pinterest')"
                        aria-label="Pinterest"
                        ><svg class="icon icon--24 mr-2"><use xlink:href="#icon-pinterest" /></svg
                    ></a>
                </ng-container>
                <ng-container *ngIf="client.use('pagesInternal')">
                    <div class="mt-3">
                        <a href="https://play.google.com/store/apps/details?id=com.app24live.mobile" class="mr-1">
                            <img
                                src="/img/google-play-icon.png"
                                alt="Thousands of matches"
                                class="img-fluid"
                                style="width: 45%"
                        /></a>
                        <a href="https://apps.apple.com/us/app/24live/id6504604967">
                            <img
                                src="/img/app-store-icon.png"
                                alt="Thousands of matches"
                                class="img-fluid"
                                style="width: 45%"
                        /></a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</footer>
