<div>
    <div [hidden]="!formLoaded || registrationSuccess">
        <form [formGroup]="regForm.getGroup()">
            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="regForm.getGroup().errors && (regForm.getGroup().touched || regForm.getGroup().dirty)"
            >
                {{ regForm.getErrorMessage(regForm.getGroup()) | translate }}
            </div>

            <div class="row">
                <div class="col-12 pb-4">
                    <!-- <ul
                        class="nav nav-tabs nav-tabs--border nav-justified text-uppercase font-weight-bold border-0"
                        id="tabs-login"
                        role="tablist"
                    >
                        <li class="nav-item" (click)="toggleUsePhoneNumber()">
                            <a
                                class="nav-link active border-left-0"
                                id="login-phone-tab"
                                data-toggle="tab"
                                href="#login-phone"
                                role="tab"
                                aria-controls="login-phone"
                                aria-selected="true"
                                >{{ 'web.auth_by_email' | translate }}</a
                            >
                        </li>
                        <li class="nav-item" (click)="toggleUsePhoneNumber()">
                            <a
                                class="nav-link border-right-0"
                                id="login-email-tab"
                                data-toggle="tab"
                                href="#login-email"
                                role="tab"
                                aria-controls="login-email"
                                aria-selected="false"
                                >{{ 'web.auth_by_phone' | translate }}
                            </a>
                        </li>
                    </ul> -->
                </div>
                <div *ngIf="!regForm.usePhoneNumber?.value" class="col-12">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.email' | translate }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'web.enter_email' | translate }}"
                            formControlName="email"
                            type="email"
                            autocomplete="email"
                            class="form-control form-control-lg"
                        />

                        <mat-error *ngIf="regForm.email?.invalid">{{
                            regForm.getErrorMessage(regForm.email) | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="regForm.usePhoneNumber?.value" class="col-12">
                    <div class="form-row">
                        <div class="col-md-4">
                            <select
                                class="form-control form-control-lg"
                                formControlName="phone_number_code"
                                id="phoneNumberCode"
                            >
                                <option *ngFor="let country of countries" value="{{ country.phoneCode }}">
                                    (+{{ country.phoneCode }}) {{ country.name }}
                                </option>
                            </select>

                            <mat-error *ngIf="regForm.phoneNumberCode?.invalid">{{
                                regForm.getErrorMessage(regForm.phoneNumberCode) | translate
                            }}</mat-error>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="legacy" class="d-block">
                                <mat-label>{{ 'web.phone_number' | translate }}</mat-label>
                                <input
                                    matInput
                                    class="form-control form-control-lg"
                                    placeholder="{{ 'web.enter_phone_number' | translate }}"
                                    formControlName="phoneNumber"
                                />

                                <mat-error *ngIf="regForm.phoneNumber?.invalid">{{
                                    regForm.getErrorMessage(regForm.phoneNumber) | translate
                                }}</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-3">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.password' | translate }}</mat-label>
                        <input
                            matInput
                            type="password"
                            placeholder="{{ 'web.enter_password' | translate }}"
                            formControlName="password"
                            class="form-control form-control-lg"
                        />

                        <mat-error *ngIf="regForm.password?.invalid">{{
                            regForm.getErrorMessage(regForm.password) | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 pt-3">
                    <mat-form-field appearance="legacy" class="d-block">
                        <mat-label>{{ 'web.password_repeat' | translate }}</mat-label>
                        <input
                            matInput
                            type="password"
                            class="form-control form-control-lg"
                            placeholder="{{ 'web.enter_password_again' | translate }}"
                            formControlName="password_confirmation"
                        />

                        <mat-error *ngIf="regForm.passwordConfirmation?.invalid">{{
                            regForm.getErrorMessage(regForm.passwordConfirmation) | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 mb-5 pt-2">
                    <ngx-recaptcha2
                        #captchaElem
                        [siteKey]="siteKey"
                        (load)="handleLoadRC()"
                        (success)="handleSuccessRC($event)"
                        [useGlobalDomain]="false"
                        [hl]="lang.getLangSnapshot()"
                        [theme]="recaptchaTheme"
                        [type]="'Checkbox'"
                        formControlName="recaptcha"
                    >
                    </ngx-recaptcha2>
                    <span class="text-danger" *ngIf="regForm.recaptcha?.invalid">{{
                        regForm.getErrorMessage(regForm.recaptcha) | translate
                    }}</span>
                </div>
                <div class="col-md-12 align-self-end text-right mb-4">
                    <button
                        class="btn btn-primary btn-lg btn-block-xs-only"
                        [disabled]="regForm.getGroup().invalid"
                        (click)="save()"
                    >
                        {{ 'web.' + submitText | translate }}
                    </button>
                </div>

                <!-- <div class="col-md-6">
                    <app-social-sign-in type="facebook" (onChange)="change($event)"></app-social-sign-in>
                </div>
                <div class="col-md-6">
                    <app-social-sign-in type="google" (onChange)="change($event)"></app-social-sign-in>
                </div> -->
                <!-- <div class="col-md-12">
                    <button class="btn btn-danger btn-block mt-4 mt-sm-0" type="reset" (click)="resetForm()">
                        {{ "web.reset_form" | translate }}
                    </button>
                </div> -->
            </div>
        </form>
    </div>
    <div *ngIf="!formLoaded">
        <app-page-loader></app-page-loader>
        <span>Loading registration form</span>
    </div>
    <div *ngIf="registrationSuccess">
        <div *ngIf="!smsRegistration" class="row mt-4">
            <div class="col align-self-center text-center">
                <p class="badge-text-draw">
                    {{ 'web.registration_success' | translate }}
                    <br />
                    <i class="material-icons"> check_circle_outline </i>
                </p>
                <p class="text-secondary">{{ 'web.registration_success_text' | translate }}</p>

                <p>
                    <button class="btn btn-primary rounded-0" (click)="action('signin')">
                        {{ 'web.sign_in' | translate }}</button
                    >&nbsp;&nbsp;
                    <button class="btn btn-outline-secondary" (click)="action('close')" color="warn">
                        {{ 'web.close_modal' | translate }}
                    </button>
                </p>
            </div>
        </div>
    </div>
</div>
