/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Service for Translations
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { Store } from '@ngxs/store';
import { switchMap, catchError } from 'rxjs/operators';
import * as _ from 'underscore';


import { CommonService } from '../shared/common.service';

import URL_CONFIG from '../config/url.config';
import APP_CONFIG from '../config/app.config';
import { ClearAllMissingTrans } from '../store/actions/translation.action';

import { StorageService } from './storage.service';

import { FormErrorInterface } from '@/interfaces/form-response.interface';


@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    public static isLoaded: boolean = false;
    public static isLoading: boolean = false;

    private httpOptions: Record<string, any>;

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private trans: TranslateService,
        private storage: StorageService,
        private store: Store,
    ) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
    }

    /**
     * Sending missing translations to server
     * @return {void}
     */
    public sendMissing(): void {
        timer(15000, 180000)
            .pipe(
                switchMap((): Observable<boolean | FormErrorInterface> => this.sendMissingToServer()),
            )
            .subscribe((): void => {
                this.store.dispatch(new ClearAllMissingTrans());
            });

        this.store
            .select((state): any => state.translation.missing)
            .subscribe((v): void => {
                const keys = v.indexes.map((v2: any): string => v2.key);
                let old: string[] = [];
                if (this.storage.isset(APP_CONFIG.missingTranskey)) {
                    old = this.storage.get<string[]>(
                        APP_CONFIG.missingTranskey,
                    )!;
                }

                const union = _.union(keys, old);
                this.storage.set(APP_CONFIG.missingTranskey, union);
            });
    }

    private sendMissingToServer(): Observable<boolean | FormErrorInterface> {
        const keys = this.store.selectSnapshot<
        { key: string; path: string; send: boolean }[]
        >((state): { key: string; path: string; send: boolean }[] => state.translation.missing.indexes.filter(
            (val: any): boolean => val.send === false,
        ),
        );
        if (keys.length === 0) {
            return of(false);
        }

        const url = URL_CONFIG.api.missingTranslations;
        return this.http.post<any>(url, keys, {
            ...this.httpOptions,
            observe: 'body',
        })
            .pipe(catchError(this.common.errorFormCallback));
    }
}
