import { EventEmitter } from 'events';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './icons';

// if (environment.local) {
//     // MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 11 close listeners added to [Server].
//     // Use emitter.setMaxListeners() to increase limit
//     const server = new EventEmitter();
//     server.setMaxListeners(20);
//     console.log('Setting max listeners to ', server.getMaxListeners());
// }

if (environment.production) {
    enableProdMode();
    if (window) {
        // window.console.log = () => {};
        window.console.error = ():void => {};
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

// document.addEventListener("DOMContentLoaded", (): void => {
//     platformBrowserDynamic()
//         .bootstrapModule(AppModule)
//         .catch((err): void => console.error(err));
// });
