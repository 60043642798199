<nav
    class="nav flex-column text-center nav--nomenclature bg-light"
    id="alpha-filter"
    *ngIf="show && client.use('matchListAlpha')"
>
    <a
        class="nav-link"
        [class.d-none]="!showStar"
        [class.active]="starActive"
        (click)="starActive = !starActive; reset(true); change()"
        ><svg class="icon icon--12"><use href="#icon-star-fill" /></svg
    ></a>
    <a
        *ngFor="let code of codes"
        class="nav-link"
        [class.disabled]="!isAlphaAvail(code.text)"
        [class.active]="code.active"
        (click)="activeCode(code)"
    >
        {{ code.text }}
    </a>
</nav>
