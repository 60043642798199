/**
 *  Service for Pages
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { DeviceDetectorService } from 'ngx-device-detector';

import { PlayerExtend } from '@interfaces/match-data.interface';

import { HttpClient, HttpParams } from '@angular/common/http';

import URL_CONFIG from '@config/url.config';

import { catchError } from 'rxjs/operators';


import { Calendar } from 'vanilla-calendar-pro';

import { FormControl } from '@angular/forms';

import { CommonService } from '../shared/common.service';
import APP_CONFIG from '../config/app.config';

import { StorageService } from './storage.service';

import { UtilsService } from './utils.service';
import { LangService } from './lang.service';

import { TimeService } from './time.service';

import { WindowRefService } from '@/shared/window-ref';


@Injectable({
    providedIn: 'root',
})
export class PageService {
    public pageLoadEvent: EventEmitter<boolean> = new EventEmitter();

    public onTransLoad: EventEmitter<boolean> = new EventEmitter();

    public onSnackBarMyMatchesClose: EventEmitter<boolean> = new EventEmitter();

    public onMatchActive = new BehaviorSubject<true | number>(true);

    public onPlayerMatch = new Subject<PlayerExtend>();
    public $onPlayerMatch = this.onPlayerMatch as Observable<PlayerExtend>;

    private isDebug: boolean = false;

    private pageTitle = 'web.livescore';

    #calendar: Calendar;

    public constructor(
        private common: CommonService,
        private trans: TranslateService,
        private storage: StorageService,
        private windowRefService: WindowRefService,
        private deviceService: DeviceDetectorService,
        private lang: LangService,
        private http: HttpClient,
    ) {}

    public setPageTitle(title: string): void {
        this.pageTitle = title;
    }

    public getPageTitle(): string {
        return this.pageTitle;
    }

    /**
     * Fire event when page is loaded
     * @fire PageService#pageLoadEvent
     * @return {void}
     */
    public pageLoaded(): void {
        this.pageLoadEvent.emit(true);
    }

    /**
     * Fire event when page is start transition
     * @fire PageService#pageLoadEvent
     * @return {void}
     */
    public pageReset(): void {
        this.pageLoadEvent.emit(false);
    }

    get debug(): boolean {
        return this.isDebug;
    }

    set debug(mode: boolean) {
        this.isDebug = mode;
    }

    get backofficeHost(): string {
        return environment.backofficeHost || '/';
    }

    /**
     * Translate string
     * @param {string}  key
     * @return {string}
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public _t(key: string): string {
        return this.trans.instant(key);
    }

    /** */
    public get window(): Window {
        return this.windowRefService.nativeWindow;
    }

    /**
     * Set sticky match detail
     * @param {boolean} event
     */
    public setSticky(event: boolean): void {
        this.storage.set(APP_CONFIG.switchStickyKey, event);
    }

    public canShare(): boolean {
        return 'share' in navigator && this.deviceService.isMobile();
    }

    public async shareText(shareData: {title: string, text: string, url: string}): Promise<void> {
        try {
            await navigator.share(shareData);
        } catch (err: any | undefined) {
            throw new Error(err);
        }
    }

    public setPlayerMatchDetail(player: PlayerExtend): void {
        this.onPlayerMatch.next(player);
    }


    public destroyCalendar(): void {
        this.#calendar.destroy();
    }

    public initCalendar(actualDate: any, date: FormControl): void {
        setTimeout(() => {
            if (actualDate) {
                if (this.#calendar) {

                    // this.#calendar.destroy();
                    // return;
                }

                const elm = document.getElementById('livescore-calendar') as HTMLElement;
                this.#calendar = new Calendar(elm, {
                    inputMode: true,
                    locale: this.lang.getLangSnapshot(),
                    selectedDates: [actualDate.format(TimeService.dateFormat)],
                    styles: {
                        calendar: 'vc livescore-vc' },
                    onClickDate: (self):void => {
                        date.setValue(self.context.selectedDates[0]);
                        this.#calendar.hide();
                    },
                });
                this.#calendar.init();
            }
        }, 1000);
    }

    get calendar(): Calendar {
        return this.#calendar;
    }

    /**
     * Get static page trans
     * @param  {string} key
     * @return  {Observable<string>}
     */
    public getStaticPageTranslate(key: string): Observable<string> {
        const iso = this.lang.getLangSnapshot();
        const url = UtilsService.replace('key', key as string, URL_CONFIG.api.staticPage);
        const params = new HttpParams().set('lang', iso);


        return this.http.request('GET', url, { params, responseType: 'text' }).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * get Unique page URL
     * @param  {number} days
     * @return  {Observable<string>}
     */
    public uniqueUrl(urlVal: string): Observable<{ url: string }> {
        const url = URL_CONFIG.api.uniqueURL;
        const params = new HttpParams().set('url', urlVal);
        const options = params !== null ? { params } : {};
        return this.http.get<{ url: string }>(url, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    public async setwakeLock(): Promise<boolean> {
        /*eslint-disable */
        try {
            if ('wakeLock' in navigator) {
                // create an async function to request a wake lock
                try {
                    const wakeLock = await (navigator as any).wakeLock.request('screen');
                    const event = () => {
                        // the wake lock has been released
                        console.log('Wake Lock has been released');
                    };
                    wakeLock.removeEventListener('release', event);
                    wakeLock.addEventListener('release', event);
                    // eslint-disable-next-line no-console
                    console.log('Wake lock accuired');
                    this.storage.set(APP_CONFIG.wakeLockkey, true);
                    return Promise.resolve(true);
                } catch (err: any) {
                    // The Wake Lock request has failed - usually system related, such as battery.
                    // eslint-disable-next-line no-console
                    console.log(`Wake lock failed ${err.name}, ${err.message}`);
                    this.storage.set<boolean>(APP_CONFIG.wakeLockkey, false);
                    return Promise.resolve(false);
                }
            }
        } catch (e) {
             return Promise.resolve(false);
        }
        /* eslint-enable */

        return Promise.resolve(false);
    }

    public async releaseWakeLock(): Promise<boolean> {
        try {
            if ('wakeLock' in navigator) {
                const wakeLock = await (navigator as any).wakeLock.request('screen');
                if (wakeLock) {
                    await wakeLock.release();
                    this.storage.set(APP_CONFIG.wakeLockkey, false);
                    // eslint-disable-next-line no-console
                    console.log('Wake lock released');
                }
            }
            return Promise.resolve(true);
        } catch (e) {
            return Promise.resolve(false);
        }
    }

    public async checkWakeLock(): Promise<void> {
        /*eslint-disable */
        try {

            if (!this.storage.isset(APP_CONFIG.wakeLockkey) || this.storage.get(APP_CONFIG.wakeLockkey) !== false) {
                await this.setwakeLock();
            }
        } catch (e: unknown) {
        }
        /* eslint-enable */
    }

    private defaultIntersectionOptions = {
        root: null,
        rootMargin: '-50px 0px',
        threshold: 0,
    };

    public intersectionObserver(elm: Element,
        options: Record<string, any> = this.defaultIntersectionOptions):
        Observable<{isIntersecting: boolean, elm: Element}> {
        return new Observable((observe): void => {
            options = {
                root: document.querySelector('#main') as HTMLElement,
                rootMargin: '-50px 0px',
                threshold: 0.5,
            };
            const observer = new IntersectionObserver((act): void => {
                if (act[0]) {
                    observe.next({ isIntersecting: act[0].isIntersecting, elm });
                }
            }, options);

            observer.observe(elm);
        });
    }

    /**
     * Detect page reload
     */
    public detectReload(): boolean {
        const pageAccessedByReload =
            (this.windowRefService.nativeWindow.performance.navigation &&
                this.windowRefService.nativeWindow.performance.navigation.type === 1) ||
            this.windowRefService.nativeWindow.performance
                .getEntriesByType('navigation')
                .map((nav: Record<string, any>) => nav.type)
                .includes('reload');

        return pageAccessedByReload;
    }
}
