import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';
import { from, Subject } from 'rxjs';

import { LangInterface } from '../interfaces/lang.interface';

import { LangService } from './lang.service';
import { ClientService } from './client.service';

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    public jsonLDSetter$ = new Subject<{[key: string]: unknown}>();

    public constructor(@Inject(DOCUMENT) private dom: any,
        private lang: LangService, private client: ClientService) {
    }

    public createAlternateURL(langs: LangInterface[]): void {
        $('head>link[rel=alternate]').remove();
        $('head>link[rel=canonical]').remove();
        from(langs).subscribe((lang): void => {
            let glue = '&';
            let url = `${this.dom.URL}`;
            if (url.search(';date=') >= 0) {
                const firstIndex = url.search(';date=');
                const lastIndex = url.search(';date=') + 16;
                url = url.substring(0, firstIndex) + url.substring(lastIndex);
            }

            if (this.dom.URL.indexOf('?') === -1) {
                glue = '?';
            }
            url = `${url}${glue}lang=${lang.iso}`;
            if (this.dom.URL.indexOf('lang=') !== -1) {
                url = this.dom.URL.substr(0, this.dom.URL.indexOf('lang='));
                url += `lang=${lang.iso}`;
            }

            if (this.lang.getLangSnapshot() === lang.iso) { // active language
                $('head').append($(`<link rel="canonical" href="${url}"/>`));
            } else {
                $('head').append($(`<link rel="alternate" hreflang="${lang.iso}" href="${url}"/>`));
            }
        });
    }
}
