/**
 *  Class decorator
 *  @see https://www.typescriptlang.org/docs/handbook/decorators.html
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */

 type Constructor = new (...args: any[]) => Record<string, any>;

/**
* Banners decorator
*/
export function banner({ position }: {position: number}) {
    return <T extends Constructor>(constructor: T): any => class extends constructor {
        bannerPositionId: number = position;
    };
}
