import { EventEmitter, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';


import URL_CONFIG from '../config/url.config';
import { CommonService } from '../shared/common.service';

import { UtilsService } from './utils.service';

import { UserService } from './user.service';

import { AdsInterface, AdsPlatforms, AdsPositions } from '@/interfaces/ads.interface';


@Injectable({
    providedIn: 'root',
})
export class AdsService {
    public static platformIds: number[] = [];

    public static mobileLayerClosed = false;

    public onLoad = new EventEmitter<boolean>();

    public isLoaded = false;


    public ads: AdsInterface[] | null = null;

    protected static cacheResult: Record<string, any> = {};

    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private storeNgx: Store,


    ) {}

    public load(reload: boolean = false, lang: string, position: AdsPositions,
        platforms: AdsPlatforms): Observable<AdsInterface[]> {
        if (!reload) {
            return of([]);
        }

        return this.get(lang, position, platforms).pipe(tap((val) => {
            if (val && val.length > 1) {
                val = [val[UtilsService.getRandomInt(0, val.length)]]! as any;
            }
            this.ads = val;

            this.isLoaded = true;
            this.onLoad.emit();
        }));
    }

    public get(lang: string, position: AdsPositions, platforms: AdsPlatforms): Observable<AdsInterface[]> {
        const Url = URL_CONFIG.api.ads;

        const key = `${lang}_${position}_${platforms.join(',')}`;
        let params = new HttpParams();
        params = params.append('lang', lang);
        params = params.append('position', position);

        if (UserService.getIso()) {
            params = params.append('country', UserService.getIso()!);
        }

        if (Array.isArray(platforms)) {
            platforms.forEach((param) => { params = params.append('platforms[]', param.toString()); });
        }

        const Options = { params };

        let observable = this.http.get<AdsInterface[]>(Url, Options);
        if (key in AdsService.cacheResult) {
            observable = of(AdsService.cacheResult[key]);
        }
        return observable.pipe(
            distinctUntilChanged(),
            catchError(this.common.errorCallback), // then handle the error
            tap((result: AdsInterface[]) => {
                AdsService.cacheResult = { ...AdsService.cacheResult, ...{ [key]: result } };
            }),
        );
    }
}
