/**
 *  Championbet Ghana configuration
 *
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */

import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: true,
        cookie: true,
        pagesInternal: true,
        oddsTab: true,
        share: false,
        closeIcon: false,
        winProbability: true,
        matchListAlpha: false,
        sportQuickNav: false,
        desktopLogo: false,
        betsStats: false,
        pageDarkMode: false,
        prematchH2HDefault: true,
        liveIcon: false,
        news: false,
        channels: false,
        video: false,
        map: false,
        canonicalPartner: false,
        partners: false,
        opta: false,
        useDefaultLogo: false,
        notification: true,
        tracker: true,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        seoCard: false,
    },
    social: {
        facebook: 'https://www.facebook.com/24liveresults/',
        instagram: 'https://www.instagram.com/24liveresults/?hl=en',
        youtube: 'https://www.youtube.com/channel/UCQQklw0bb9ndGCYJc2pJ5Ng?view_as=subscriber',
        twitter: 'https://twitter.com/24liveresults',
        tiktok: 'https://vm.tiktok.com/J2MhdnC/',
        telegram: 'https://t.me/bestlookingscore',
        linkedin: 'https://www.linkedin.com/company/24liveresults/',
        pinterest: 'https://cz.pinterest.com/24liveresults/',
    },
    onelang: 'en',
    bet: {
        winApi: true,
        displayConfirm: true,
        isOddsOnlyBeforeAndAfter: true,
    },
};

export default config;
