import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Observable, Subscription, of, timer } from 'rxjs';
import * as _ from 'underscore';
import $ from 'src/app/shared/jquery';
import $$ from 'jquery';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';

import { PageState } from '@store/states/page.state';

import { Select } from '@ngxs/store';

import { SportService as Sport } from '../../services/sport.service';
import { SportInterface } from '../../interfaces/sport.interface';
import SPORT_CONFIG from '../../config/sport.config';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-sport-navigation',
    templateUrl: './sport-navigation.component.html',
})
export class SportNavigationComponent extends BaseComponent
    implements OnInit, OnChanges {
    @Select(PageState.isDebug) public isDebug$: Observable<boolean>;
    public isDebug = false;


    @Input()
    public date: string;

    @Input()
    public type: string = 'main';

    @Input()
    public limit: number = 5;

    @Input()
    public sportId: number;

    @Input()
    public disabled: boolean = false;

    @Output()
    public action: EventEmitter<string> = new EventEmitter();

    public data: SportInterface[][];

    public dataRaw: SportInterface[] | undefined;

    private subscriber: Subscription;
    private subscriberTimer: Subscription;

    public constructor(private sport: Sport, private storage: StorageService) {
        super();
    }

    public ngOnInit(): void {
        const delayN: number = this.type === 'main' ? 0 : 600;

        this.isDebug$.subscribe((debug: boolean): void => {
            this.isDebug = debug;
        });

        if (this.type === 'main' || this.type === 'mobile') {
            this.subscriberTimer = timer(0, environment.sportNaviReload).subscribe((n): void => {
                of(true)
                    .pipe(delay(delayN))
                    .subscribe((): void => {
                        this.subscriber = this.sport
                            .getSports('today', n !== 0 && this.type === 'main')
                            .subscribe((val): void => {
                                this.dataRaw = val;
                                if (this.dataRaw) {
                                    this.data = _.chunk(
                                        this.dataRaw,
                                        Math.floor(this.dataRaw.length / 4),
                                    ) as any;
                                }
                            });
                    });
            });
        } else {
            this.dataRaw = this.sport.dataValRaw;
            if (this.dataRaw) {
                this.data = _.chunk(
                    this.dataRaw,
                    Math.floor(this.dataRaw.length / 2),
                ) as any;
            }
            this.sport.onLoadRaw.subscribe((val) => {
                this.dataRaw = val;
                if (this.dataRaw) {
                    this.data = _.chunk(
                        this.dataRaw,
                        Math.floor(this.dataRaw.length / 2),
                    ) as any;
                }
            });
        }
    }

    public ngOnDestroy(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriberTimer instanceof Subscription) {
            this.subscriberTimer.unsubscribe();
        }
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (this.type === 'aside') {
            $('[data-toggle="tooltip"]').tooltip();
        }

        if (
            !_.isUndefined(change.sportId) &&
            change.sportId.currentValue !== change.sportId.previousValue &&
            !_.isUndefined(change.sportId.currentValue) &&
            !_.isUndefined(change.sportId.previousValue)
        ) {
            this.storage.set(SPORT_CONFIG.categoryIndex, []);
        }
    }

    public trackByFn(index: number, item: Record<string, any>): number {
        return item.id;
    }

    public goTo(): void {
        this.action.emit('goToSport');
    }

    /**
     * Reset last scroll
     */
    public resetScroll(): void {
        this.storage.set('APP_LAST_SCROLL', 0);
        this.storage.set('APP_GROUP_MATCHES', []);
        $$('html, body').animate({ scrollTop: 0 }, 0);
    }

    public resetCategoryOpen(): void {
        this.storage.set(SPORT_CONFIG.categoryIndex, []);
    }

    public isNew(sportName: string): boolean {
        return SPORT_CONFIG.new.includes(sportName);
    }

    /**
     * Hangel query params for url
     * @return {object}
     */
    public queryParams(): Record<string, unknown> {
        if (this.date !== null && !_.isUndefined(this.date)) {
            return { date: this.date };
        }

        return {};
    }

    public getRouterArr(sport: SportInterface): (string|Record<string, unknown>)[] {
        if (SPORT_CONFIG.isStage(sport.code_name)) {
            return ['/page', 'stage', 'stage-list', `${sport.code_name}-${sport.id}`,
                this.queryParams() as Record<string, unknown>];
        }

        if (sport.code_name === 'golf') {
            return ['/page', 'golf', 'golf-list', `${sport.code_name}-${sport.id}`,
                this.queryParams() as Record<string, unknown>];
        }

        return ['/page', 'sport', 'match-list', `${sport.code_name}-${sport.id}`,
            this.queryParams() as Record<string, unknown>];
    }
}
