/**
 *  Service for langs
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as $ from 'jquery';

import { CommonService } from '../shared/common.service';
import { LangService } from '../services/lang.service';

import { NaviLevel1Interface, NaviLevel2Interface } from '../interfaces/navigation.interface';
import URL_CONFIG from '../config/url.config';

import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public constructor(private http: HttpClient, private common: CommonService, private lang: LangService) {}

    /**
     * Get navigation level 1
     * @return {Promise<Observable<SportInterface[]>>}
     */
    public async getNavLevel1(
        sportId: any,
        type: string = 'all',
        limit: any = null,
        favorites: number[] | null = null,
        isStage: boolean = false,
        secondLevel: boolean = true,
    ): Promise<Observable<NaviLevel1Interface[]> | Error> {
        // if (!sportId) {
        //     return Promise.resolve(of([]));
        // }
        const url = UtilsService.replace('sportId', sportId as string, URL_CONFIG.api.getNavigationLevel1);

        return this.lang.getLang().then(
            (iso): Observable<NaviLevel1Interface[]> => {
                const params: any = {};
                params.lang = iso;
                params.section = type;
                params.level2 = secondLevel ? '1' : '0';
                params.isStage = isStage ? '1' : '0';
                if ($('#stageYear  :selected').val()) {
                    params.stageYear = $('#stageYear  :selected').val();
                }
                if (limit !== null) {
                    params.limit = limit;
                }
                if (favorites!.length !== null && favorites!.length > 0) {
                    params.tournamentIds = favorites!.join(',');
                }
                const options = params ? { params } : {};
                return this.http.get<NaviLevel1Interface[]>(url, options).pipe(
                    switchMap(this.map),
                    catchError(this.common.errorCallback), // then handle the error
                );
            },
            (): Promise<Error> => Promise.reject(new Error()),
        );
    }

    /**
     * Get navigation level 2s
     * @param {number} sportId
     * @param {number} categoryId
     * @param {string} type
     * @param {number[]} favorites
     * @return {Promise<Observable<SportInterface[]>>}
     */
    public async getNavLevel2(
        sportId: any,
        categoryId: any,
        type: string = 'all',
        favorites: number[] | null = null,
        isStage: boolean = false,
    ): Promise<Observable<NaviLevel2Interface[]> | Error> {
        if (!sportId) {
            return Promise.resolve(of([]));
        }

        return this.lang.getLang().then(
            // eslint-disable-next-line max-len
            (iso): Observable<NaviLevel2Interface[]> => this.nav2Call(sportId, categoryId, type, favorites, isStage, iso),
            (): Promise<Error> => Promise.reject(new Error()),
        );
    }

    public nav2Call(sportId: any, categoryId: any, type: string = 'all',
        favorites: number[] | null = null,
        isStage: boolean = false, iso: string): Observable<NaviLevel2Interface[]> {
        const url = UtilsService.replace(
            ['sportId', 'categoryId'],
            [sportId as string, (categoryId == null ? 0 : categoryId) as string],
            URL_CONFIG.api.getNavigationLevel2,
        );
        let params = new HttpParams()
            .set('lang', iso)
            .set('section', type)
            .set('isStage', isStage ? '1' : '0');
        if (favorites && favorites!.length !== null && favorites!.length > 0) {
            params = params.append('tournamentIds', favorites!.join(','));
        }
        const options = params ? { params } : {};
        return this.http.get<NaviLevel2Interface[]>(url, options).pipe(
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    private map(data: NaviLevel1Interface[]): Observable<NaviLevel1Interface[]> {
        data.forEach((val, k): void => {
            data[k].active = false;
        });
        return of(data);
    }
}
