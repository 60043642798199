import { Component, OnInit, NgZone } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-add-to-screen',
    templateUrl: './add-to-screen.component.html',
})
export class AddToScreenComponent implements OnInit {
    private isMobile: boolean = false;

    public constructor(private ngZone: NgZone, public deviceService: DeviceDetectorService) {}

    public ngOnInit(): void {
        this.init();
    }

    private init(): void {
        this.ngZone.runOutsideAngular((): void => {
            if ('serviceWorker' in navigator) {
                // window.addEventListener('load', (): void => {

                navigator.serviceWorker.register('/service-worker.js').then(
                    (registration): void => {
                        // Registration was successful
                        console.info('ServiceWorker registration successful with scope: ', registration.scope);

                        // const { host } = window.location;
                        // const data = {
                        //     type: "CACHE_URLS",
                        //     payload: [
                        //         document.location.href,
                        //         ...performance
                        //             .getEntriesByType("resource")
                        //             .filter((r): boolean =>
                        //                 r.name.includes(host)
                        //             )
                        //             .map((r): string => r.name),
                        //     ],
                        // };
                        //
                        // if (
                        //     !_.isUndefined(registration.installing) &&
                        //     !_.isNull(registration.installing)
                        // ) {
                        //     registration.installing.postMessage(data);
                        // }
                    },
                    (err): void => {
                        // registration failed :(
                        console.info('ServiceWorker registration failed: ', err);
                    },
                );
                // });

                if (this.deviceService.isMobile()) {
                    window.addEventListener('beforeinstallprompt', (e): void => {
                        console.info('Show promt');
                        // Prevent Chrome 67 and earlier from automatically showing the prompt
                        // e.preventDefault();
                        // Stash the event so it can be triggered later.
                        let deferredPrompt: any = e;
                        deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        deferredPrompt.userChoice.then((choiceResult: Record<string, any>): void => {
                            console.info('Actions');
                            console.info(choiceResult);
                            if (choiceResult.outcome === 'accepted') {
                                console.info('User accepted the A2HS prompt');
                            } else {
                                console.info('User dismissed the A2HS prompt');
                            }
                            deferredPrompt = null;
                        });
                    });
                }
            }
        });
    }
}
