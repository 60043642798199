/**
 *  Service for soccer time
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import * as _ from 'underscore';


@Injectable({
    providedIn: 'root',
})
export class SoccerTime {
    public handle(
        minute: number,
        state: string,
        addTime?: number,
        _origTime?: string,
    ): [number, any] {
        let extra = null;


        if (state === 'period_1') {
            if (minute >= 45) {
                extra = minute - 45 + 1;
                minute = 44;
            }
        } else if (state === 'period_2') {
            if (minute >= 90) {
                extra = minute - 90 + 1;
                minute = 89;
            }
        } else if (state === 'extra_time_1') {
            if (minute >= 105) {
                extra = minute - 105 + 1;
                minute = 104;
            }
        } else if (state === 'extra_time_2') {
            if (minute >= 120) {
                extra = minute - 120 + 1;
                minute = 119;
            }
        }

        if (extra !== null) {
            extra = `+${extra}`;
        }

        if (addTime && !_.isUndefined(addTime) && addTime !== null) {
            extra = `+${addTime}`;
        }
        return [minute + 1, extra];
    }
}
