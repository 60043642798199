<ng-container *ngIf="banner && deviceService.isMobile()">
    <ngx-parallax-scroll [config]="ngParallaxConf">
        <a
            *ngIf="banner?.url; else elseBlock"
            target="_blank"
            href="{{ banner?.url }}?{{ utm() }}"
            [id]="bannerPositionId + ' | ' + banner?.url"
            (click)="click()"
            class="app-banner-mobile-header app-banner-main text-center"
        >
            <img
                src="{{ banner.path }}"
                alt="{{ banner?.altText }}"
                srcset="{{ banner?.small_image }} 600w,
                    {{ banner?.medium_image }} 1024w,
                    {{ banner?.large_image }} 1600w"
                class="banner-parallax-img"
            />
        </a>

        <ng-template #elseBlock>
            <img
                *ngIf="!banner?.url"
                src="{{ banner.path }}"
                alt="{{ banner?.altText }}"
                class="app-banner-mobile-header banner-parallax-img"
                srcset="{{ banner?.small_image }} 600w,
                        {{ banner?.medium_image }} 1024w,
                        {{ banner?.large_image }} 1600w"
            />
        </ng-template>
    </ngx-parallax-scroll>
</ng-container>
