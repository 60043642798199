import { UserInterface } from '../../interfaces/user.interface';

export class SignIn {
    public static readonly type = '[Branch User API] Sign in';
}

export class SignOff {
    public static readonly type = '[Branch User API] Sign off';
}

export class User {
    public static readonly type = '[Branch User API] User Data';

    public constructor(public user: UserInterface) {}
}
