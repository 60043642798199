/* eslint-disable @typescript-eslint/ban-types */
/**
 *  Property decorator
 *  @see https://www.typescriptlang.org/docs/handbook/decorators.html
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2021 livescore
 */

import 'reflect-metadata';

export const PROPERTY_METADATA_KEY = Symbol('propMeta');

interface IPropertyMetadata {
    name?: string;
    type?: 'string' | 'number' | 'boolean' | 'object' | 'array' | 'mixed' | 'function' | null;
    event?: string;
    nullable?: boolean;
    description?: string;
}

export interface IAllPropertyMetadata {
    [key: string]: IPropertyMetadata;
}


export function minLength(limit: number): PropertyDecorator {
    return function (target: Object,
        propertyKey: string | symbol): void {
        let value: string;

        const getter = (): string => value;
        const setter = (newVal: string): void => {
            if (newVal.length < limit) {
                throw new Error(`Length of string is higher than ${limit}`);
            } else {
                value = newVal;
            }
        };
        Object.defineProperty(target, propertyKey, {
            get: getter,
            set: setter,
        });
    };
}
/**
 * Add metadata to property
 */
export function propertyMetada(metadata: IPropertyMetadata): PropertyDecorator {
    return function (target: Object,
        propertyKey: string | symbol): void {
        const allMetadata =
            Reflect.getMetadata(PROPERTY_METADATA_KEY, target) ||
            {};


        allMetadata[propertyKey] = allMetadata[propertyKey] || {};

        const ownKeys = Reflect.ownKeys(metadata);

        ownKeys.forEach((key: any) => {
            // @ts-ignore
            const val = <IAllPropertyMetadata>metadata[key];
            allMetadata[propertyKey][key] = val;
        });

        Reflect.defineMetadata(
            PROPERTY_METADATA_KEY,
            allMetadata,
            target,
        );
    };
}

export function getMetaAll(target: any): any {
    return Reflect.getMetadata(PROPERTY_METADATA_KEY, target);
}

export function getMeta(target: any, property: string): any {
    return Reflect.getMetadata(PROPERTY_METADATA_KEY, target)[property];
}
