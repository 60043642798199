export const environment = {
    production: true,
    hmr: false,
    developement: false,
    local: false,
    matchListReloadTime: 9000,
    widgetMatchFlashReloadTime: 7000,
    matchReloadTime: 5000,
    matchReloadTimeNotLive: 60000,
    matchListLiveReloadTime: 4000,
    matchTournamentReloadTime: 20000,
    stageListReloadTime: 86400000,
    golfListReloadTime: 10000,
    leagueTableReloadTime: 30000,
    participantReloadTime: 10000,
    myMatchesReloadTime: 5000,
    matchListMobileLimit: 500,
    sportNaviReload: 120000,
    matchListBranchReloadTime: 60000,
    liveBranchReloadTime: 10000,
    gameSetAnimation: 12000,
    myMatchesNotification: 12000,
    myMatchesNtfDuration: 12000,
    matchDetailCache: false,
    cdn: '',
    domain: 'https://www.24liveresults.com',
    web: 'https://www.24live.com',
    backofficeHost: 'https://admin.24live-score.com',
    aiServer: 'https://ai.24liveresults.com/',
    aiActive: true,
};
