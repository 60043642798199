import { Component } from '@angular/core';
import { ClientService } from '@/services/client.service';

@Component({
    selector: 'app-light-header',
    templateUrl: './light-header.component.html',
})
export class LightHeaderComponent {
    public constructor(public client: ClientService) { }

    public closeModal(): void {
        window.top?.postMessage({ closeModal: true }, '*');
    }
}
