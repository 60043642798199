export default {
    required: 'web.validators_required',
    email: 'web.validators_email',
    minlength: 'web.validators_min_length',
    maxlength: 'web.validators_max_length',
    passwordMatch: 'web.validators_passwords_must_match',
    httpError: 'web.form_http_error',
    captcha: 'web.form_captcha_error',
    string: 'web.form_string_error',
    unique: 'web.form_unique_error',
    userNotFound: 'web.form_user_not_found_error',
    userNotActive: 'web.form_user_not_active_error',
    invalidCode: 'web.form_invalid_code',
    invalidData: 'web.form_invalid_data',
    pattern: 'web.form_invalid_pattern',
    localStorageSupport: 'web.local_storage_support',
    screenNotAvail: 'web.screen_not_avail',
};
