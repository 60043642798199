/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Sport App config
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { InjectionToken } from '@angular/core';


export const SportConfigToken = new InjectionToken<Record<string, any>>('Sport config token');

const CONFIG = {
    imagePath: 'img/sport',
    imageRetinaSuffix: '-2x',
    mobileMinOpenCategories: 10,
    // number of sport aside menu  match list page
    asideSportDefaultNum: 6,
    leagueTableWidthBreakpoint: 600, // in px
    possibelGoalTimeout: 25, // number of seconds where possible goal is valid for frontend
    categoryIndex: 'CATEGORY_INDEX',
    categoryMatchListCache: 'CATEGORY_MATCHLIST_CACHE',
    matchMatchListCache: 'MATCH_MATCHLIST_CACHE',
    matchMatchListCacheTimeout: 120, // time of cache validity in seconds
    matchDetailResetTimeout: 700, // timeout to not show match detail loading
    matchListDefaultOpenCategories: 4,
    trackerInfoKey: 'TRACKER_INFO_KEY', // tracker notification first visit
    venues: {
        defaultLimit: 50,
    },
    navigation: {
        main: {
            // if navigation for some sport
            // does not have main navi called all
            tennis: 'active-seasons',
            squash: 'active-seasons',
        },
        child: {
            // if navigation for some sport
            // does not have main navi called all
            tennis: 'active-seasons',
            squash: 'active-seasons',
        },
        default: 'all',
    },
    tournament: {
        roundLimit: 4, // numbe rof rounds to display
        roundLimitNoRounds: 20, // tournaments with no rounds
    },
    h2h: {
        baseLimit: 5,
        extendLimit: 10,
    },
    time: {
        running: [], // ["soccer", "rugby"], sport names with running time
        timeContinuesEachPeriod: ['handball', 'rugby', 'futsal'],
        topLimits: {
            // periods limit
            handball: [30, '30:00', '60:00'],
            basketball: 10,
            rugby: [40, '40:00', '80:00'],
            floorball: 20,
            'american-football': 15,
            'field-hockey': 15,
            bandy: 45,
            waterpolo: 8,
        },
    },
    live: {
        json: ['tennis', 'soccer'], // sports where in live taking json data
    },
    new: [ // new sports released
        'kabaddi', 'netball',
    ],
    tracker: {
        // Active tracker sports
        availSports: ['tennis', 'soccer', 'basketball', 'ice-hockey'],
        availStatsSports: ['soccer', 'basketball', 'tennis', 'ice-hockey'],
        excludeTournaments: [0],
    },
    // tournament link avail sports
    tournamentLink: ['handball', 'soccer', 'basketball', 'ice-hockey', 'futsal', 'volleyball',
        'cricket', 'waterpolo', 'american-football', 'floorball', 'mma'],
    playerStats: {
        // Player tournament stats
        availSports: ['soccer', 'ice-hockey'],
        redYellowCard: ['soccer', 'futsal', 'handball'],
        exclude: ['goals_allowed'],
    },
    states: {
        ended: 'ended',
        notStarted: 'not_started',
        warmUp: 'about_to_start',
    },
    statistics: {
        underOver: [0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5],
        underOverSport: {
            soccer: [0.5, 1.5, 2.5, 3.5, 4.5],
            'ice-hockey': [2.5, 3.5, 4.5, 5.5, 6.5, 7.5],
            futsal: [4.5, 5.5, 6.5],
            handball: [57.5, 58.5, 59.5, 61.5, 62.5],
        },
        // stats which vlaue is percentage
        percentage: ['average_ball_possession', 'save_percentage', 'shooting_percentage', 'ShotAccuracy'],
        measureWeight: ['weight'],
        measureHeight: ['height'],
        yesNo: ['Starter'],
        participant: {
            meta: {
                recordWinLose: 'pro-record-w-l-d',
                ranking: 'ranking',
            },
        },
        participantMatchSport: ['ice-hockey', 'handball', 'futsal', 'rugby', 'volleyball', 'baseball', 'beach-volley',
            'american-football', 'squash', 'badminton', 'cricket', 'tennis', 'snooker', 'darts', 'soccer'],
        // available statistics match->participant  sports
        participantSeasonStandings: [
            // sport where to display standings graph
            'soccer',
            'futsal',
            'american-football',

            'handball',
            'field-hockey',
            'rugby',
            'ice-hockey',
        ],
        totals: [
            // sport with half and full time
            'soccer',
            'futsal',
            'american-football',
            'beach-soccer',
            'handball',
            'rugby',
            'ice-hockey',
        ],
        uo: [
            // sport with half and full time
            'soccer',
            'futsal',
            'handball',
            'ice-hockey',
        ],
        timeline: [
            // sport with timeline facts
            'soccer',
            'futsal',
            'handball',
            'ice-hockey',
        ],
        goals: [
            // sport with half and full time
            'soccer',
            'futsal',
            'american-football',
            'beach-soccer',
            'handball',
            'rugby',
            'ice-hockey',
            'field-hockey',
        ],
        scoredGoals: [
            'soccer',
        ],
        matchScore: [
            'soccer',
            'futsal',
            'beach-soccer',
            'ice-hockey',
            'field-hockey',
        ],
    },
    rankings: {
        table: ['badminton', 'darts', 'snooker', 'golf'],
    },
    score: {
        penalties: {
            sumAll: ['handball'], // add penailties score and normal time score
            normalTime: ['field-hockey'], // use only normail time score event in penalties
        },
        hidePeriodsMatchList: [ // hide periods in match list
            'curling',
            'darts',
            'aussie-rules',
        ],
        decimalPeriods: ['aussie-rules'], // add decimal points to number
        ommitScoreMatch: ['snooker', 'curling', 'darts'],
        seriesScore: ['soccer', 'ice-hockey', 'handball', 'futsal', 'rugby', 'basketball', 'volleyball',
            'bandy', 'baseball', 'floorball', 'field-hockey', 'american-football', 'beach-volley', 'waterpolo'],
    },
    // differ display of sport in match list .. One row or two
    displayType: {
        oneline: ['soccer', 'rugby', 'ice-hockey', 'field-hockey', 'beach-soccer', 'futsal', 'handball'],
        halfTime: [
            // sport with half and full time
            'soccer',
            'futsal',
            'american-football',
            'beach-soccer',
        ],
        uo: [
            // sport with half and full time
            'soccer',
            'futsal',
            'beach-soccer',
        ],
        sets: [
            'tennis',
            'badminton',
            'table-tennis',
            'beach-volley',
            'volleyball',
            'baseball',
            'basketball',
            'squash',
            'snooker',
            'beach-soccer',
            'rugby',
            'boxing',
            'mma',
            'cricket',

        ],
        participantPerson: [
            // display photo of person not team logo
            'tennis',
            'badminton',
            'table-tennis',
            'squash',
            'snooker',
            'darts',
            'boxing',
            'mma',
            'bowls',
        ],
        // sports witch should be grouped
        // in match list to categories
        groupCategories: [],
        // sports which has more than 5 periods
        lotOfPeriods: ['baseball', 'table-tennis'],
        maxDisplayPeriods: 11,
        // sport code names whitch in detail
        // of tournament could be updatad on first
        // load to specific sub tournament
        subTournamentUpdate: ['tennis', 'badminton', 'table-tennis', 'beach-volley', 'squash', 'snooker', 'darts'],
        noLogo: ['tennis', 'badminton', 'table-tennis', 'mma', 'squash', 'bowls',
            'table-tennis', 'darts', 'boxing', 'snooker'],

    },
    sounds: {
        // sport where play change score sound
        score: ['soccer', 'bandy', 'field-hockey', 'floorball', 'beach-soccer', 'futsal', 'ice-hockey'],
        set: ['table-tennis', 'beach-volley', 'squash', 'badminton', 'volleyball', 'tennis'],
    },
    codeNames: {
        basketball: 'basketball',
        tennis: 'tennis',
        soccer: 'soccer',
        handball: 'handball',
        baseball: 'baseball',
        hockey: 'ice-hockey',
        squash: 'squash',
        badminton: 'badminton',
        tableTennis: 'table-tennis',
        rugby: 'rugby',
        volleyball: 'volleyball',
        floorball: 'floorball',
        mma: 'mma',
        futsal: 'futsal',
        esport: 'esport',
        golf: 'golf',
        box: 'boxing',
        cricket: 'cricket',
        darts: 'darts',
        snooker: 'snooker',
        fieldHockey: 'field-hockey',
        waterpolo: 'waterpolo',
        americanSoccer: 'american-football',
        beachVolleyball: 'beach-volley',
        motosport: 'motor-sport',
        wintersport: 'winter-sports',
        hackeyball: 'hackeyball',
        horseRacing: 'horse-racing',
        greyhound: 'greyhound',

    },
    golf: {
        maxInitPlayers: 10,
    },
    mainNav: {
        trans: {
            finished: {
                boxing: 'web.finished_matches_fight',
                mma: 'web.finished_matches_fight',
            },
            future: {
                boxing: 'web.future_matches_fight',
                mma: 'web.future_matches_fight',
            },

        },
    },
    stageSports: [
        'alpine-skiing',
        'athletics',
        'biathlon',
        'bobsleigh',
        'cross-country',
        'cycling',
        'formula-1',
        'greyhound',
        'horse-racing',
        'indycar',
        'luge',
        'motor-sport',
        'motorcycle-racing',
        'nordic-combined',
        'olympics',
        'politicians',
        'ski-jumping',
        'snowboard',
        'social',
        'speed-skating',
        'winter-sports',
    ],
    myMatches: {
        notifications: ['soccer', 'futsal', 'ice-hockey', 'bandy',
            'field-hockey', 'table-tennis', 'volleyball', 'rugby', 'american-football'],
        favoriteDayLimit: 30, // match will be removed from favorites after this number of days
    },
    timeline: {
        eventTypes: {
            matchEnded: 'match_ended',
            matchStarted: 'match_started',
            point: 'point',
            periodScore: 'period_score',
            periodStart: 'period_start',
            matchResumed: 'match_resumed',
            suspension: 'suspension',
            scoreChange: 'score_change',
            firstThrow: 'first_throw',
            legScoreChange: 'leg_score_change',
            dart: 'dart',
            timeout: 'timeout',
            substitution: 'substitution',
            technicalRuleFault: 'technical_rule_fault',
            technicalBallFault: 'technical_ball_fault',
            sevenMAwarded: 'seven_m_awarded',
            yellowCard: 'yellow_card',
            redCard: 'red_card',
            ballPot: 'ball_pot',
            cornerKick: 'corner_kick',
            goalKick: 'goal_kick',
            penaltyShootout: 'penalty_shootout',
            unknown: 'unknown',
        },
        playerTypes: {
            scorer: 'scorer',
            assist: 'assist',
            secondaryAssist: 'secondary_assist',
            primaryAssist: 'assist primary',
        },
        image: {
            'ice-hockey': {
                score_change: '/generated/img/match-data/ice-hockey/goal.svg',
                suspension: '/generated/img/match-data/ice-hockey/whistle.svg',
                substitution: '/img/substitution.png',
            },
            futsal: {
                score_change: '/generated/img/match-data/futsal/goal.svg',
                yellow_card: '/generated/img/match-data/soccer/yellow_card.svg',
                red_card: '/generated/img/match-data/soccer/red_card.svg',

            },
            floorball: {
                score_change: '/generated/img/match-data/floorball/goal.svg',
            },
            squash: {
                score_change: '/generated/img/match-data/squash/goal.svg',
            },
            waterpolo: {
                score_change: '/generated/img/match-data/waterpolo/goal.svg',
            },
            'table-tennis': {
                score_change: '/generated/img/match-data/table-tennis/goal.svg',
            },
            snooker: {
                score_change: '/generated/img/match-data/snooker/goal.svg',
            },
            darts: {
                score_change: '/generated/img/match-data/darts/goal.svg',
            },
            baseball: {
                score_change: '/generated/img/match-data/baseball/goal.svg',
            },
            'field-hockey': {
                score_change: '/generated/img/match-data/field-hockey/goal.svg',
            },
            hockeyball: {
                score_change: '/generated/img/match-data/field-hockey/goal.svg',
            },
            soccer: {
                score_change: '/generated/img/match-data/soccer/goal.svg',
                goal_kick: '/generated/img/match-data/soccer/goal.svg',
                corner_kick: '/generated/img/match-data/soccer/corner.svg',
                yellow_card: '/generated/img/match-data/soccer/yellow_card.svg',
                red_card: '/generated/img/match-data/soccer/red_card.svg',
                substitution: '/img/substitution.png',
                unknown: '/generated/img/match-data/soccer/empty.svg',
            },
            rugby: {
                score_change: '/generated/img/match-data/rugby/goal.svg',
                yellow_card: '/generated/img/match-data/rugby/yellow_card.svg',
                red_card: '/generated/img/match-data/rugby/red_card.svg',
                substitution: '/img/substitution.png',
            },
            volleyball: {
                period_score: '/generated/img/match-data/volleyball/goal.svg',
            },
            'american-football': {
                score_change: '/generated/img/match-data/american-football/goal.svg',
            },
            handball: {
                score_change: '/generated/img/match-data/handball/goal.svg',
                suspension: '/generated/img/match-data/handball/whistle.svg',
                technical_rule_fault: '/generated/img/match-data/handball/whistle.svg',
                technical_ball_fault: '/generated/img/match-data/handball/whistle.svg',
                timeout: '/generated/img/match-data/handball/stopwatch.svg',
                substitution: '/img/substitution.png',
                seven_m_awarded: '/generated/img/match-data/handball/seven.svg',
                yellow_card: '/generated/img/match-data/handball/yellow_card.svg',
                red_card: '/generated/img/match-data/handball/red_card.svg',
            },
        },
    },
    matchActions: {
        icons: {
            type1: 'period',
            type2: 'goal',
            type3: 'goal',
            type4: 'goal',
            type5: 'goal',
            type6: 'period',
            type7: 'substitution2',
            type8: 'yellow_card',
            type9: 'period',
            type11: 'yellow_card',
            type12: 'red_card',
            type13: 'period',
            type15: 'period',
            type16: 'period',
            type17: 'period',
            type19: 'period',
            type21: 'period',
            type22: 'period',
            type23: 'period',
            type24: 'period',
            type25: 'goal',
            type26: 'goal',
            type27: 'goal',
            type28: 'goal',
            type29: 'goal',
            type30: 'goal',
            type31: 'period',
            type32: 'period',
            type34: 'goal',
            type35: 'goal',
            type36: 'goal',
            type37: 'period',
            type38: 'goal',
            type39: 'goal',
            type40: 'goal',
            type41: 'goal',
            type42: 'goal',
            type43: 'substitution2',
            type44: 'yellow_card',
            type45: 'yellow_card',
            type46: 'red_card',
            type53: 'injury',
            type55: 'period',
            type67: 'period',
            type71: 'substitution2',
            type72: 'substitution2',
            type80: 'corner',
            type104: 'yellow_card',
            type105: 'yellow_card',
            type137: 'red_card',
            type138: 'yellow_card',
            type139: 'red_card',
            type140: 'yellow_card',
            type141: 'red_card',

        },
    },
    isComparitionForm(name: string): boolean {
        return this.isHockey(name) || this.isTennis(name) || this.isSoccer(name) || this.isBasketball(name) ||
        this.isSnooker(name) || this.isBaseball(name) || this.isSquash(name) || this.isDarts(name) ||
        this.isFutsal(name) || this.isTableTennis(name) ||
        this.isHandball(name) || this.isBadminton(name) || this.isVolleyball(name) || this.isAmericanSoccer(name) ||
        this.isRugby(name) || this.isFloorball(name) || this.isMma(name) || this.isBox(name);
    },
    isParticipantPerson(name: string): boolean {
        return this.displayType.participantPerson.includes(name);
    },
    isTennis(name: string): boolean {
        return name === this.codeNames.tennis;
    },
    isDarts(name: string): boolean {
        return name === this.codeNames.darts;
    },
    isBadminton(name: string): boolean {
        return name === this.codeNames.badminton;
    },
    isBaseball(name: string): boolean {
        return name === this.codeNames.baseball;
    },
    isMma(name: string): boolean {
        return name === this.codeNames.mma;
    },
    isMmaById(id: number): boolean {
        return id === 38;
    },
    isBox(name: string): boolean {
        return name === this.codeNames.box;
    },
    isSquash(name: string): boolean {
        return name === this.codeNames.squash;
    },
    isHorseRacing(name: string): boolean {
        return name === this.codeNames.horseRacing;
    },
    isGreyhound(name: string): boolean {
        return name === this.codeNames.greyhound;
    },

    isSoccer(name: string | undefined): boolean {
        return name === this.codeNames.soccer;
    },
    isHandball(name: string): boolean {
        return name === this.codeNames.handball;
    },
    isHockeyBall(name: string): boolean {
        return name === this.codeNames.hackeyball;
    },
    isAmericanSoccer(name: string): boolean {
        return name === this.codeNames.americanSoccer;
    },
    isHockey(name: string | undefined): boolean {
        return name === this.codeNames.hockey;
    },
    isFieldHockey(name: string): boolean {
        return name === this.codeNames.fieldHockey;
    },
    isRugby(name: string): boolean {
        return name === this.codeNames.rugby;
    },
    isVolleyball(name: string): boolean {
        return name === this.codeNames.volleyball;
    },
    isFloorball(name: string): boolean {
        return name === this.codeNames.floorball;
    },
    isSnooker(name: string): boolean {
        return name === this.codeNames.snooker;
    },
    isCricket(name: string): boolean {
        return name === this.codeNames.cricket;
    },
    isFutsal(name: string): boolean {
        return name === this.codeNames.futsal;
    },
    isEsport(name: string): boolean {
        return name === this.codeNames.esport;
    },
    isGolf(name: string): boolean {
        return name === this.codeNames.golf;
    },
    isMotosport(name: string): boolean {
        return name === this.codeNames.motosport;
    },
    isWintersport(name: string): boolean {
        return name === this.codeNames.wintersport;
    },
    isBasketball(name: string | undefined): boolean {
        return name === this.codeNames.basketball;
    },
    isTableTennis(name: string): boolean {
        return name === this.codeNames.tableTennis;
    },
    isWaterPolo(name: string): boolean {
        return name === this.codeNames.waterpolo;
    },
    isBeachVolleyball(name: string): boolean {
        return name === this.codeNames.beachVolleyball;
    },
    isSet(name: string): boolean {
        return this.displayType.sets.includes(name);
    },
    isStage(sport: string): boolean {
        return this.stageSports.includes(sport);
    },
    hasTracker(sport: string, tournamentId?: number): boolean {
        if (tournamentId && this.tracker.excludeTournaments.includes(tournamentId)) {
            return false;
        }

        return this.tracker.availSports.includes(sport);
    },
    hash2h(sport: string): boolean {
        return this.isSoccer(sport) || this.isHockey(sport) || this.isBasketball(sport) || this.isHandball(sport) ||
            this.isTennis(sport) || this.isVolleyball(sport) || this.isMma(sport) ||
            this.isEsport(sport) || this.isRugby(sport) || this.isBaseball(sport) || this.isSquash(sport) ||
            this.isAmericanSoccer(sport) || this.isSnooker(sport) || this.isDarts(sport) || this.isFutsal(sport) ||
            this.isFloorball(sport) || this.isTableTennis(sport) || this.isWaterPolo(sport) || this.isBadminton(sport);
    },
    hasStatistics(sport: string): boolean {
        return this.tracker.availStatsSports.includes(sport) || this.statistics.participantMatchSport.includes(sport);
    },
    hasPlayerStats(sport: string): boolean {
        return this.playerStats.availSports.includes(sport);
    },
};

export default CONFIG;
