<ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'facebook'" class="d-inline">
        <button (click)="signInWithFB()" class="btn btn-block btn-facebook text-uppercase" [disabled]="progress">
            <i *ngIf="!progress" class="fab fa-facebook"></i> Facebook
            <span *ngIf="progress"><i class="fas fa-asterisk fa-spin"></i></span>
        </button>
    </div>
    <div *ngSwitchCase="'google'" class="d-inline">
        <div [attr.id]="'buttonDiv' + idName" style="position: relative; top: -3px; width: min-content"></div>

        <!-- <button
            (click)="signInWithGoogle()"
            class="btn btn-block btn-light text-uppercase"
            [class.btn-white]="!isMobile"
            [disabled]="progress"
        >
            <i *ngIf="!progress" class="fab fa-google"></i> Google
            <span *ngIf="progress"><i class="fas fa-asterisk fa-spin"></i></span>
        </button> -->
    </div>
</ng-container>
<p class="text-danger" *ngIf="signError">{{ 'web.social_signin_error' | translate }}</p>
