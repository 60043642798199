import { Component, OnInit, Inject } from '@angular/core';
import { trigger } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import $ from 'src/app/shared/jquery';
import * as _ from 'underscore';
import { SIGNIN_ANIMATION } from 'src/app/shared/animations/sign-in.animation';

@Component({
    selector: 'app-authetification',
    templateUrl: './authetification.component.html',
    animations: [trigger('signState', SIGNIN_ANIMATION)],
})
export class AuthetificationDialogComponent implements OnInit {
    public signState: string = 'signInBlock';

    public redirect: string | null = null;

    public constructor(
        public dialogRef: MatDialogRef<AuthetificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { type: string; redirectUrl?: string },
    ) {}

    public ngOnInit(): void {
        this.redirect = !_.isUndefined(this.data.redirectUrl)
            ? (this.data.redirectUrl as string)
            : null;
        this.change(this.data.type);
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }


    /**
     * Go to forgotten password
     * @return {void}
     */
    public goToForgottenPassword(): void {
        this.signState = 'forgottenPassword';
    }

    public change(type: string): void {
        if (type === 'signin') {
            $('#authTab li:first-child a').tab('show');
        } else if (type === 'forgottenPassword') {
            this.data.type = 'signin';
            this.signState = type;
        } else if (type === 'signInBlock') {
            this.signState = type;
        } else if (type === 'close') {
            this.onNoClick();
        }
    }
}
