import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { FavoriteService } from '../../../services/favorite.service';

@Component({
    selector: 'app-my-matches-navigation-widget',
    templateUrl: './navigation-widget.component.html',
})
export class NavigationWidgetComponent implements OnInit {
    @Output()
    private onClick = new EventEmitter<any>();

    public constructor(public favorite: FavoriteService) {}

    public ngOnInit(): void {}

    public onClickEvent(): void {
        this.onClick.emit();
    }
}
