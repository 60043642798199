import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SportService as Sport } from 'src/app/services/sport.service';
import { SearchService } from 'src/app/services/search.service';
import { StorageService } from 'src/app/services/storage.service';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SportConfigToken } from '@config/sport.config';

import { SearchData, SearchInterface } from '../../interfaces/search.interface';
import { BaseComponent } from '../base.component';
import { SearchForm } from '../../shared/forms/search.form';

const TOURNAMENT_SEARCH_INDEX = 'appSearchTournament';
const PARTICIPANT_SEARCH_INDEX = 'appSearchParticipant';
const PLAYERS_SEARCH_INDEX = 'appSearchPlayers';

declare const dataLayer: any;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
})
export class SearchComponent extends BaseComponent implements OnInit, OnDestroy {
    public data: any;

    private subscribe: Subscription;

    public pristine: boolean = true;

    public constructor(
        public sport: Sport,
        public search: SearchService,
        private route: Router,
        private storage: StorageService,
        public searchForm: SearchForm,
        private deviceService: DeviceDetectorService,
        public dialogRef: MatDialogRef<SearchComponent>,
        @Inject(SportConfigToken) public sportConfig: Record<string, any>,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        @Inject(MAT_DIALOG_DATA) public dialogData: {q: string | null},
    ) {
        super();
    }

    public ngOnInit(): void {
        const search = this.searchForm.searchFormControl!.valueChanges.pipe(debounceTime(500));
        const sport = this.searchForm.sportFormControl!.valueChanges;
        merge(search, sport).subscribe((): void => {
            if (this.searchForm.searchFormControl!.valid) {
                this.pristine = false;
                this.doSearch();
            }
        });

        this.searchForm.searchFormControl!.patchValue('');
        this.reset();

        if (this.dialogData.q) {
            this.searchForm.searchFormControl!.patchValue(this.dialogData.q);
        }

        $('.cdk-overlay-pane').css('overflow', 'scroll');
    }

    public ngOnDestroy(): void {
        if (this.subscribe instanceof Subscription) {
            this.subscribe.unsubscribe();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public trackById(index: number, item: Record<string, any>): number {
        return item.id;
    }

    public transformate(val: string): string {
        return val.replace(/^\d+-/, '');
    }

    public get isMobile(): boolean {
        return this.deviceService.isMobile();
    }

    public isSupportedSport(sportName: string): boolean {
        return this.sportConfig.isTennis(sportName) ||
        this.sportConfig.isSquash(sportName) ||
        this.sportConfig.isBadminton(sportName) ||
        this.sportConfig.isTableTennis(sportName);
    }

    public ommitSport(sportName: string): boolean {
        return this.sportConfig.isHorseRacing(sportName) ||
        this.sportConfig.isGreyhound(sportName);
    }

    /**
     * Search for data
     * @return {void}
     */
    public doSearch(): void {
        // this.reset();
        const sportId = this.searchForm.sportFormControl!.value;
        this.subscribe = this.search.get(this.searchForm.searchFormControl!.value, sportId).subscribe(
            (val): void => {
                if (typeof dataLayer !== 'undefined' && dataLayer) {
                    dataLayer.push({
                        event: 'search',
                        searchTerm: this.searchForm.searchFormControl!.value,
                    });
                }

                const participants: Record<string, any> = {};
                const players: Record<string, any> = {};
                const tournaments: Record<string, any> = {};
                let x = 0;
                _.each(val.participants, (p): void => {
                    if (this.ommitSport(p.sport_code_name)) {
                        return;
                    }
                    let index: string | undefined = _.findKey(participants, (value, key): boolean =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        key.includes(p.sport_code_name),
                    );

                    if (!index) {
                        x += 1;
                        index = `${x}-${p.sport_code_name}`;
                        participants[index] = [];
                    }

                    participants[index].push(p);
                });
                x = 0;
                _.each(val.players, (p): void => {
                    let index: string | undefined = _.findKey(players, (value, key): boolean =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        key.includes(p.sport_code_name),
                    );

                    p.image = '/img/default-avatar.svg';

                    if (!index) {
                        x += 1;
                        index = `${x}-${p.sport_code_name}`;
                        players[index] = [];
                    }

                    players[index].push(p);
                });
                x = 0;
                _.each(val.tournaments, (p): void => {
                    let index: string | undefined = _.findKey(tournaments, (value, key): boolean =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        key.includes(p.sport_code_name),
                    );

                    if (!index) {
                        x += 1;
                        index = `${x}-${p.sport_code_name}`;
                        tournaments[index] = [];
                    }

                    tournaments[index].push(p);
                });
                _.each(val.subtournaments, (p): void => {
                    let index: string | undefined = _.findKey(tournaments, (value, key): boolean =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        key.includes(p.sport_code_name),
                    );

                    if (!index) {
                        x += 1;
                        index = `${x}-${p.sport_code_name}`;
                        tournaments[index] = [];
                    }

                    const findExisting = tournaments[index].find((t: SearchData): boolean => p.tournament_id === t.id);

                    if (!findExisting) {
                        p.id = p.tournament_id!;
                        p.name = p.tournament_name!;

                        tournaments[index].push(p);
                    }
                });

                this.data = { participants, tournaments, players };

                this.finished();
            },
            (): void => {
                this.error();
            },
        );
    }

    /**
     * Get latest search data
     * @param  {string} type
     * @return  {SearchInterface[]}
     */
    public getLatest(type: string): SearchInterface[] {
        let key = TOURNAMENT_SEARCH_INDEX;
        if (type === 'participants') {
            key = PARTICIPANT_SEARCH_INDEX;
        } else if (type === 'players') {
            key = PLAYERS_SEARCH_INDEX;
        }

        return this.storage.get<SearchInterface[]>(key) || [];
    }

    /**
     * Go to detail
     * @param  {string} type
     * @param  {number} id
     * @return {void}
     */
    public goToDetail(type: string, item: SearchData): void {
        let url = ['page/sport/event', `${item.sport_code_name}-${item.sport_id}`, item.id];
        let key = TOURNAMENT_SEARCH_INDEX;
        let favorite: SearchData[] | null = this.storage.get<SearchData[]>(key);
        if (type === 'participants') {
            if (this.sportConfig.isGolf(item.sport_code_name)) {
                url = ['page/golf/participant', `${item.sport_code_name}-${item.sport_id}`, item.id];
            } else if (this.sportConfig.isMotosport(item.sport_code_name) ||
            this.sportConfig.isWintersport(item.sport_code_name)) {
                url = ['page/stage/participant', `${item.sport_code_name}-${item.sport_id}`, item.id];
            } else {
                url = ['page/sport/participant', `${item.sport_code_name}-${item.sport_id}`, item.id];
            }
            key = PARTICIPANT_SEARCH_INDEX;
            favorite = this.storage.get<SearchData[]>(key);
        } else if (type === 'players') {
            url = ['page/sport/player', `${item.sport_code_name}-${item.sport_id}`, item.id];
            key = PLAYERS_SEARCH_INDEX;
            favorite = this.storage.get<SearchData[]>(key);
        }

        if (favorite == null) {
            favorite = [];
        }

        const index = _.findIndex(favorite, (i): boolean => i.id === item.id);

        if (index === -1) {
            favorite.unshift(item);
        }

        favorite = favorite.slice(0, 10); // slice to only 10 elements
        this.storage.set(key, favorite);

        this.route.navigate(url);
        this.onNoClick();
    }

    /**
     * Check if result has no data
     * @return [description]
     */
    public noData(): boolean {
        return (
            this.searchForm.searchFormControl!.valid &&
            this.data &&
            !this.pristine &&
            _.isEmpty(this.data.participants) &&
            _.isEmpty(this.data.tournaments) &&
            _.isEmpty(this.data.players)
        );
    }

    /**
     * Check if object is not empty
     * @param  {object} val
     * @return  {boolean}
     */
    public notEmpty(val: Record<string, any>): boolean {
        return !_.isEmpty(val);
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }
}
