<ng-container *ngIf="isLoggedIn$ | async as isLoggedIn; else block">
    <ng-container *ngIf="userData$ | async as user$">
        <div *ngIf="!signOffBtn && !mobile" class="media position-relative mt-auto mb-auto">
            <!-- <img src="img/avatar.jpg" class="mr-2 rounded-circle nav-item--user__avatar" alt="mrtipper1988"> -->
            <div class="media-body">
                <a routerLink="/page/account/dashboard" class="btn btn-link navbar-mobile__user px-2 ng-star-inserted">
                    <svg class="icon icon--28 icon--white">
                        <use class="navbar-mobile__user---signin" xlink:href="#icon-user"></use>
                    </svg>
                    <small
                        >&nbsp;
                        <!-- {{ user$.email | truncate:13 }} -->
                        {{ 'web.user_account' | translate | transinit }}
                    </small>
                </a>

                <!-- <span class="text-primary small">25574 PTS <span class="px-1 text-muted">|</span> 98%</span> -->
            </div>
        </div>

        <button
            *ngIf="signOffBtn && !mobile"
            type="button"
            (click)="signOff()"
            class="btn btn-link nav-link text-white"
            data-toggle="modal"
            data-target="#modal-login"
            data-cy="modal-login"
        >
            <svg class="icon icon--24 icon--white d-block mx-auto mb-1"><use xlink:href="#icon-logout" /></svg>
            <span *ngIf="!signOffProgress">{{ 'web.sign_out' | translate }}</span>
            <span *ngIf="signOffProgress"> <app-page-loader></app-page-loader></span>
        </button>

        <ng-container *ngIf="mobile">
            <button class="btn btn-link navbar-mobile__user px-2" data-toggle="modal" data-target="#modal-login">
                <svg class="icon icon--28 icon--white">
                    <use xlink:href="#icon-user" class="navbar-mobile__user---signin" />
                </svg>
            </button>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #block>
    <li class="nav-item" *ngIf="!mobile && client.use('signin')">
        <div>
            <button
                type="button"
                (click)="signIn()"
                class="btn btn-link nav-link"
                data-toggle="modal"
                data-cy="modal-login"
                data-target="#modal-login"
            >
                <svg class="icon icon--24 icon--white d-block mx-auto mb-1"><use xlink:href="#icon-user" /></svg
                >{{ 'web.sign_in' | translate | transinit }}
            </button>
        </div>
    </li>
</ng-template>

<div class="nav-item" *ngIf="!deviceService.isMobile()">
    <app-lang></app-lang>
</div>
