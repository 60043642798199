/**
 *  User states
 *  @module Branch
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { SignIn, SignOff, User } from '../actions/user.action';
import { UserInterface } from '../../interfaces/user.interface';
import { UserStore } from '../../services/user-store.class';

interface UserStatusModel {
    user: UserStore;
}

@State<UserStatusModel>({
    name: 'branchUser',
    defaults: {
        user: new UserStore(),
    },
})
@Injectable()
export class BranchUserState {
    /**
     * Get is logged in info
     * @return {boolean}
     */
    @Selector()
    public static isLoggedIn(state: UserStatusModel): boolean {
        return state.user.isLoggedIn;
    }

    /**
     * Get user profile data
     * @return {UserInterface}
     */
    @Selector()
    public static profile(state: UserStatusModel): UserInterface | null {
        return state.user.profile;
    }


    /**
     * Set/update user ddata
     */
    @Action(User)
    public setUserData(
        ctx: StateContext<UserStatusModel>,
        { user }: User,
    ): void {
        const state = ctx.getState();
        state.user.setProfile(user);
        ctx.patchState({
            user: state.user,
        });
    }

    @Action(SignIn)
    public signIn(ctx: StateContext<UserStatusModel>): void {
        const state = ctx.getState();
        state.user.signIn();
        ctx.patchState({
            user: state.user,
        });
    }

    @Action(SignOff)
    public signOff(ctx: StateContext<UserStatusModel>): void {
        const state = ctx.getState();
        state.user.signOff();
        ctx.patchState({
            user: state.user,
        });
    }
}
