import { Component, OnInit } from '@angular/core';
import { SoundService } from 'src/app/services/sound.service';
import { TranslateService } from '@ngx-translate/core';

import { TranslateMixin } from '../../../services/mixins/translate.mixin';
import BaseClass from '../../../shared/empty.class';

@Component({
    selector: 'app-sound-switch',
    templateUrl: './switch.component.html',
    styles: [],
})
export class SwitchComponent extends TranslateMixin(BaseClass)
    implements OnInit {
    public constructor(
        public sound: SoundService,
        private translate: TranslateService,
    ) {
        super();
    }

    public ngOnInit(): void {}
}
