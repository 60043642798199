import { EventEmitter, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import URL_CONFIG from '../config/url.config';
import { CommonService } from '../shared/common.service';
import { CountryInterface } from '../interfaces/country.interface';

import { LangService } from './lang.service';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    public onLoad = new EventEmitter<boolean>();

    public isLoaded = false;

    public countries: CountryInterface[] | null = null;


    public constructor(
        private http: HttpClient,
        private common: CommonService,
        private lang: LangService,
    ) {
    }

    public load(): void {
        this.get().subscribe((val): void => {
            this.countries = val;
            this.isLoaded = true;
            this.onLoad.emit();
        });
    }

    public get(): Observable<CountryInterface[]> {
        const url = URL_CONFIG.api.getCountries;

        const options = { params: { lang: this.lang.getLangSnapshot() as string } };
        return this.http.get<CountryInterface[]>(url, options).pipe(
            distinctUntilChanged(),
            switchMap(this.map),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    private map(data: CountryInterface[]): Observable<any> {
        return of(data);
    }
}
