import { DisplayModeInterface } from '@/interfaces/mode.interface';

/**
 * Set network status
 */
export class NetworkStatus {
    public static readonly type = '[Page API] Network status';

    /**
     * @param {number} status
     *    1 = online
     *    0 = offline
     */
    public constructor(public status: number) {}
}

export class Media {
    public static readonly type = '[Page Media] Media Mode';

    /**
    * @param {DisplayModeInterface} media

    */
    public constructor(public media: DisplayModeInterface) {}
}
export class Partner {
    public static readonly type = '[Page Media] Partner';

    /**
    * @param {string} name - Partner name

    */
    public constructor(public name: string) {}
}

export class PageReload {
    public static readonly type = '[Page Window] Reaload page manually';

    /**
    * @param {DisplayModeInterface} media

    */
    public constructor(public isManualReload: boolean) {}
}

export class DebugMode {
    public static readonly type = '[Page Debug] Debug mode';

    /**
    * @param {boolean} mode - Set mode on/off

    */
    public constructor(public mode: boolean) {}
}

export class DarkMode {
    public static readonly type = '[Dark mode] switch page dark mode';

    public constructor(public mode: boolean) {}
}

export class MatchNotificationMode {
    public static readonly type = '[Match notfication mode] switch page match notification';

    public constructor(public mode: boolean) {}
}
