/**
 *  Service for page and client info (lang)
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged, tap, delay } from 'rxjs/operators';
import * as $ from 'jquery';
import { environment } from '@environments/environment';
import URL_CONFIG from '@config/url.config';

import { CommonService } from '../shared/common.service';

import { InfoInterface } from '../interfaces/info.interface';

import { ClientService } from './client.service';
import { PartnerService } from './partner.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class InfoService {
    public onLoad = new EventEmitter<boolean>();
    public onLoad2 = new ReplaySubject<boolean>();

    private data: InfoInterface | null = null;

    public constructor(
        private http: HttpClient,
        private client: ClientService,
        private partner: PartnerService,
        private common: CommonService,

    ) {}

    /**
     * Load server data
     * @return {void}
     */
    public load(): void {
        this.get()
            .pipe(
                tap((val): void => {
                    if (environment.local) {
                        $('body').addClass(`body-${val.client.name}`);
                    }
                }),
                delay(100),
            )
            .subscribe((val): void => {
                this.data = val;
                this.client.init(this.data.client);
                this.partner.init(this.data.partners);

                if (this.data.geolocation) {
                    UserService.setIso(this.data.geolocation.iso);
                }
                this.onLoad.emit();
                this.onLoad2.next(true);
            });
    }

    /**
     * Get data
     * @return {Observable<InfoInterface>}
     */
    public get(): Observable<InfoInterface> {
        const url = URL_CONFIG.api.getInfo;

        return this.http.get<InfoInterface>(url).pipe(
            distinctUntilChanged(),
            switchMap(this.map),
            catchError(this.common.errorCallback), // then handle the error
        );
    }

    /**
     * Check if data are loaded
     * @return [description]
     */
    public isLoaded(): Promise<InfoInterface | null> {
        return new Promise((resolve, reject): void => {
            if (this.data !== null) {
                resolve(this.data);
                return;
            }

            this.onLoad.subscribe((): void => resolve(this.data), (): void => reject());
        });
    }

    public get clientName(): string {
        if (this.data !== null) {
            return this.client.getNameView();
        }

        return 'N/A';
    }

    public get socialImage(): string {
        if (this.data !== null) {
            return this.client.getSocialImage();
        }

        return '';
    }

    private map(data: any): Observable<any> {
        return of(data);
    }
}
