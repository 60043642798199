/**
 *  Fortuna Czech republic configuration
 * fortuna_cs
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2023 livescore
 */

import { ClientGuard } from '@interfaces/client.interface';

const config: ClientGuard = {
    active: {
        signin: false,
        social: true,
        cookie: true,
        pagesInternal: true,
        oddsTab: true,
        share: true,
        closeIcon: false,
        winProbability: true,
        matchListAlpha: true,
        sportQuickNav: true,
        desktopLogo: true,
        pageDarkMode: true,
        betsStats: true,
        prematchH2HDefault: false,
        liveIcon: true,
        channels: true,
        news: true,
        video: true,
        map: true,
        canonicalPartner: true,
        partners: true,
        opta: false,
        useDefaultLogo: true,
        matchDetailStatistics: true,
        matchDetailPeriodStats: true,
        matchDetailLeagueTable: true,
        matchDetailPlayers: true,
        matchDetailH2H: true,
        matchDetailCuptree: true,
        matchDetailTopPlayers: true,
        matchDetailMatchActions: true,
        matchDetailCommentary: true,
        matchDetailForm: true,
        matchDetailProbability: true,
        matchDetailChannels: true,
        matchDetailEvents: true,
        matchDetailBetting: true,
        matchDetailFunFacts: true,
        seoCard: false,
        notification: true,
        tracker: true,
    },
    pages: {
        about_us: 'https://www.premierbet.ci/about-us',
        contact: 'https://www.premierbet.ci/contact-us',
        livebet: 'https://www.premierbet.ci/live',
        condition: 'https://www.premierbet.ci/terms-and-conditions',
    },
    bet: {
        winApi: true,
        displayConfirm: true,
        isOddsLiveAndAfter: false,
    },
    social: {
        facebook: 'https://www.facebook.com/premierbetci',
        youtube: 'https://www.youtube.com/@premierbetci',
        twitter: 'https://twitter.com/premierbetci',
    },

};

export default config;
