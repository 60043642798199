import {
    State,
    Action,
    StateContext,
    Selector,
} from '@ngxs/store';
import { StorageService } from 'src/app/services/storage.service';
import { Injectable } from '@angular/core';

import APP_CONFIG from '../../config/app.config';
import {
    AddMissingTrans,
    ClearAllMissingTrans,
} from '../actions/translation.action';

interface TranslationStoreInterface {
    missing: { indexes: { key: string; path: string; send: boolean }[] };
}

@State<TranslationStoreInterface>({
    name: 'translation',
    defaults: { missing: { indexes: [] } },
})
@Injectable()
export class TranslationsState {
    public constructor(private storage: StorageService) {}

    @Selector()
    public static missing(
        state: TranslationStoreInterface,
    ): { key: string; path: string }[] {
        return state.missing.indexes;
    }

    @Selector()
    public static missingNotSend(
        state: TranslationStoreInterface,
    ): { key: string; path: string }[] {
        return state.missing.indexes.filter(
            (val): boolean => val.send === false,
        );
    }

    @Action(AddMissingTrans)
    public addMissingTrans(
        ctx: StateContext<TranslationStoreInterface>,
        action: AddMissingTrans,
    ): void {
        const state = ctx.getState();
        const keys = state.missing.indexes.map((val): string => val.key);
        const saved = this.storage.get<string[]>(APP_CONFIG.missingTranskey);
        if (keys.includes(action.trans) || saved!.includes(action.trans)) {
            return;
        }
        ctx.patchState({
            missing: {
                ...state.missing,
                indexes: [
                    ...state.missing.indexes,
                    { key: action.trans, path: action.path, send: false },
                ],
            },
        });
    }

    @Action(ClearAllMissingTrans)
    public clearMissongTrans(
        ctx: StateContext<TranslationStoreInterface>,
    ): void {
        const state = ctx.getState();
        const indexes = state.missing.indexes.map((val): {
            key: string;
            path: string;
            send: boolean;
        } => {
            val.send = true;
            return val;
        });

        ctx.patchState({
            missing: {
                ...state.missing,
                indexes,
            },
        });
    }
}
