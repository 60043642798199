import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-page-loader',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
})
export class PageLoaderComponent implements OnInit {
    @Input()
    public simple: boolean = false;

    public ngOnInit(): void {}
}
