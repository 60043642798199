import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { MyMatchesService } from '@/services/my-matches.service';

@Injectable({
    providedIn: 'root',
})
export class MatchWidgetResolverService implements Resolve<number[] | null> {
    public constructor(public matches: MyMatchesService) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<number[] | null> {
        const id = route.paramMap.get('id') || null;
        const ids: string | null = route.paramMap.get('ids') || null;
        const source = route.queryParamMap.get('source') || null;
        let obs: Observable<any>;

        const idValFinal = ids === null ? id : ids;

        if (idValFinal === null) {
            obs = of([]);
        } else if (source === null) {
            obs = this.matches.brToId(idValFinal.toString());
        } else {
            obs = this.matches.sourceIdToId(idValFinal.toString(), source);
        }
        return obs.pipe(
            mergeMap(
                (ids: number[]): Observable<number[] | null> => {
                    if (ids.length > 0) {
                        return of(ids);
                    }
                    return of(null);
                },
            ),
        );
    }
}
