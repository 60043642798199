/**
 *  List All Sports [GET /api/get-langs]
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

export enum LANG {
    EN = 'en',
    CS = 'cs',
}
export interface LangInterface {
    id: number;
    image: string;
    iso: string;
}
