/**
 *  Registration form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    FormControl,
    Validators,
    FormGroup,
    AbstractControl,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class RegistrationForm extends BaseForm {
    public constructor() {
        super();
        this.init();
    }

    /**
     * Initialize form Controls
     * @return {void}
     */
    protected init(): void {
        super.init();
        this.formGroup = new FormGroup(
            {
                usePhoneNumber: new FormControl(false),
                email: new FormControl('', [
                    Validators.required,
                    Validators.email,
                ]),
                phone_number_code: new FormControl(''),
                phoneNumber: new FormControl(''),
                password: new FormControl('', [
                    Validators.required,
                    Validators.minLength(6),
                ]),
                password_confirmation: new FormControl('', [
                    Validators.required,
                ]),
                recaptcha: new FormControl('', Validators.required),
            },
            {
                validators: this.mustHaveSameValueValidator(
                    'password',
                    'password_confirmation',
                    'passwordMatch',
                ),
            },
        );
    }

    public get usePhoneNumber(): AbstractControl | null {
        return this.formGroup.get('usePhoneNumber');
    }

    public get email(): AbstractControl | null {
        return this.formGroup.get('email');
    }

    public get phoneNumberCode(): AbstractControl | null {
        return this.formGroup.get('phone_number_code');
    }

    public get phoneNumber(): AbstractControl | null {
        return this.formGroup.get('phoneNumber');
    }

    public get recaptcha(): AbstractControl | null {
        return this.formGroup.get('recaptcha');
    }

    public get password(): AbstractControl | null {
        return this.formGroup.get('password');
    }

    public get passwordConfirmation(): AbstractControl | null {
        return this.formGroup.get('password_confirmation');
    }
}
