/**
 *  Truncate string
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    public transform(
        value: any,
        limit = 25,
        completeWords = false,
        ellipsis = '..',
    ): string {
        let newLimit = limit;

        if (value === null || !value) {
            return value;
        }
        if (value.length < limit) {
            return `${value.substr(0, limit)}`;
        }

        if (completeWords) {
            newLimit = value.substr(0, limit).lastIndexOf(' ');
        }
        return `${value.substr(0, newLimit)}${ellipsis}`;
    }
}
