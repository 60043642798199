import { NgModule } from '@angular/core';


import { BannerMobileHeaderBodyComponent } from '@components/banner/mobile-header/mobile-header.component';
import { MatchBettingStatsComponent } from '@components/bets/match-betting-stats/match-betting-stats.component';
import { MatchActionsScoreComponent } from '@components/match/match-actions-score/match-actions-score.component';

import { MapGoogleModalComponent } from '@components/map/google-modal/google-modal.component';

import { NavigationComponent } from '@components/navigation/navigation.component';
import { FavoriteComponent } from '@components/favorite/favorite.component';


import { SportNavigationComponent } from '@components/sport-navigation/sport-navigation.component';
import { CalendarComponent } from '@components/calendar/calendar.component';
import { ScoreComponent } from '@components/score/score.component';
import { DefaultScoreComponent } from '@components/score/default-score/default-score.component';
import { MatchDetailScoreComponent } from '@components/score/match-detail-score/match-detail-score.component';
import { DefaultScoreShortComponent } from '@components/score/default-score-short/default-score-short.component';
import { NavigationWidgetComponent } from '@components/my-matches/navigation-widget/navigation-widget.component';
import { CategoryComponent } from '@components/match/category/category.component';
import { MatchComponent } from '@components/match/match/match.component';
import { MatchDetailComponent } from '@components/match-detail/match-detail.component';
import { TimeComponent } from '@components/match/time/time.component';
import { MatchActionsComponent } from '@components/match/match-actions/match-actions.component';
import { PlayersComponent } from '@components/match/players/players.component';
import { H2hComponent } from '@components/match/h2h/h2h.component';
import { LastMatchesComponent } from '@components/last-matches/last-matches.component';
import { SoccerTime } from '@components/match/time/services/soccer-time.service';
import { LeagueTableComponent } from '@components/league-table/league-table.component';
import { SearchComponent } from '@components/search/search.component';

import { WeatherComponent } from '@components/match/weather/weather.component';

import { GolfComponent } from '@components/golf/golf/golf.component';

import { GolfTableComponent } from '@components/golf/golf/golf-table/golf-table.component';

import { GolfParticipantTournamentComponent }
    from '@components/golf/golf-right-col/golf-participant/golf-participant-tournament.component';

import { GolfStandingsComponent } from '@components/golf/golf-right-col/golf-standings/golf-standings.component';

import { SharedModule } from '../shared/shared.module';

import { GolfTeeTimesComponent } from './golf/golf/golf-tee-times/golf-tee-times';


import { InfoWidgetComponent } from './user/info-widget/info-widget.component';
import { AuthetificationDialogComponent } from './user/authetification/authetification.component';
import { SigninComponent } from './user/signin/signin.component';
import { RegistrationComponent } from './user/registration/registration.component';
import { ForgottenPasswordComponent } from './user/forgotten-password/forgotten-password.component';
import { SwitchComponent } from './sound/switch/switch.component';
import { TennisRankingComponent } from './sport/tennis-ranking/tennis-ranking.component';
import { RankingComponent } from './sport/ranking/ranking.component';
import { FooterComponent } from './footer/footer.component';
import { SocialSignInComponent } from './user/social-sign-in/social-sign-in.component';

import { AddToScreenComponent } from './app/add-to-screen/add-to-screen.component';
import { LoaderComponent } from './loader/loader.component';
import { HeaderComponent } from './header/header.component';
import { HeaderNavigationComponent } from './header/navigation/navigation.component';
import { AlphaFilterComponent } from './alpha-filter/alpha-filter.component';
import { PlaceholderDetailComponent } from './loader/placeholder-detail/placeholder-detail.component';
import { SwitchersComponent } from './switchers/switchers.component';
import { PlaceholderTableComponent } from './loader/placeholder-table/placeholder-table.component';
import { PlaceholderTournamentComponent } from './loader/placeholder-tournament/placeholder-tournament.component';
import { PlaceholderMatchComponent } from './loader/placeholder-match/placeholder-match.component';
import { CupTreeComponent } from './cup-tree/cup-tree.component';
import { DetailComponent } from './bets/detail/detail.component';
// import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ActualSportChartComponent } from './user/statistics/actual-sport-chart/actual-sport-chart.component';
import { MyMatchesComponent } from './user/my-matches/my-matches.component';
import { PushNotificationComponent } from './app/push-notification/push-notification.component';
import { TennisTournamentInfoComponent } from './tennis-tournament-info/tennis-tournament-info.component';
import { BannerComponent } from './banner/banner.component';
import { CategoryGroupComponent } from './match/category-group/category-group.component';
import { PlayerStatsComponent } from './player-stats/player-stats.component';
import { BannerBlockComponent } from './banner/block/block.component';
import { BannerBottomFixedComponent } from './banner/bottom-fixed/bottom-fixed.component';
// import { VerifyPhoneUserComponent } from './user/verify-phone-user/verify-phone-user.component';
import { SharedButtonsComponent } from './shared-buttons/shared-buttons.component';
import { StageComponent } from './stage/stage/stage.component';
import { StageRightColComponent } from './stage/stage-right-col/stage-right-col.component';
import { LeagusTableTableComponent } from './league-table/table/table.component';
import { ScrollDirective } from './league-table/table/scroll.directive';
import { StageStandingsComponent } from './stage/stage-right-col/stage-standings/stage-standings.component';
import { StageParticipantComponent } from './stage/stage-right-col/stage-participant/stage-participant.component';
import { StageSeasonsStatsComponent } from './stage/stage-right-col/stage-seasons-stats/stage-seasons-stats.component';
import { BannerMobileLayerComponent } from './banner/mobile-layer/mobile-layer.component';
import { ParticipantFormComponent } from './participant/form/form.component';
import { MatrixComponent } from './league-table/matrix/matrix.component';
import { LightHeaderComponent } from './header/light-header/light-header.component';
import { PlayerTransfersComponent } from './player-transfers/player-transfers.component';
import { IntroComponent } from './intro/intro.component';
import { GoalsScoredReceivedComponent } from './participant/goals-scored-received/goals-scored-received.component';
import { MatchActionIconsComponent } from './match/match-action-icons/match-action-icons.component';
import { TwitchComponent } from './stream/twitch/twitch.component';
import { YoutubeComponent } from './stream/youtube/youtube.component';
import { BannerMobileBodyComponent } from './banner/mobile-body/mobile-body.component';
import { AlertComponent } from './alert/alert.component';
import { LeagueTableContainerComponent } from './league-table/table-container/table-container.component';
import { ISportComponent } from './stream/isport/isport.component';
import { VimeoComponent } from './stream/vimeo/vimeo.component';
import { StageTableComponent } from './stage/stage/stage-table/stage-table.component';
import { NhlComponent } from './stream/nhl/nhl.component';
import { LineupsComponent } from './match/lineups/lineups.component';
import { NewsComponent } from './match/news/news.component';

import { ParticipantComparitionComponent } from './participant/comparition/participant-comparition.component';
import { TennisTimelineComponent } from './match/timeline/tennis/tennis-timeline.component';
import { BaseTimelineComponent } from './match/timeline/base.component';
import { AdTimelineDirective } from './match/timeline/timeline.directive';
import { DefaultTimelineComponent } from './match/timeline/default/default-timeline.component';
import { DartsTimelineComponent } from './match/timeline/darts/darts-timeline.component';
import { TotalMatchStatsComponent } from './match/statistics/total/total-statistics.component';
import { LineupHockeyFieldComponent } from './match/lineups/ice-hockey/field.component';
import { TournamentLinksComponent } from './tournament-links/tournament-links.component';
import { ParticipanseasonstatsComponent } from './participant/season-stats/season-stats.component';
import { TennisBilanceComponent } from './participant/tennis-bilance/tennis-bilance.component';
import { ParticipantStatsComponent } from './participant/statistics/participant-stats.component';
import { ParticipantStatsTotalsComponent } from './participant/statistics/totals/participant-stats-total.component';
import { ParticipantStatsGoalsComponent } from './participant/statistics/goals/participant-stats-goals.component';
import { ParticipantStatsUOComponent } from './participant/statistics/uo/participant-stats-uo.component';
import { ParticipantStatsScoredGoalsComponent }
    from './participant/statistics/scored-goals/participant-stats-scored-goals.component';
import { ParticipantStatsMatchResultComponent }
    from './participant/statistics/match-result/participant-match-result.component';
import { ParticipantStatsTimelineComponent }
    from './participant/statistics/timeline/participant-stats-timeline.component';
import { ChannelComponent } from './match/channel/channel.component';
import { PagingComponent } from './datatable/paging/paging.component';
import { AppModalComponent } from './modal/modal.component';
import { SideMenuNavigationComponent } from './side-menu-navigation/side-menu-navigation.component';
import { PlayersV2Component } from './match/players/players-v2.component';

import { MicroModalComponent } from './my-matches/micro-modal/micro-modal.component';
import { LogoComponent } from './header/logo/logo.component';
import { PlayerMatchStatsComponent } from './match/players/match-stats/player-match-stats.component';
// import { QAComponent } from './tips-and-knowledge/qa/qa.componennt';
import { GolfBalanceComponent } from './golf/golf/golf-balance/golf-balance.component';
import { GolfHoleStatisticsComponent } from './golf/golf/golf-hole-statistics/golf-hole-statistics';
import { PeriodStatsComponent } from './match/statistics/period/period-statistics.component';
import { AdsComponent } from './ads/ads.component';
import { AdsMatchDetailComponent } from './ads/match-detail/match-detail.component';
import { AdsMobileBodyComponent } from './ads/mobile-body/mobile-body.component';
import { AdsMobileLayerComponent } from './ads/mobile-layer/mobile-layer.component';
import { AdsUniversalComponent } from './ads/universal/universal.component';
import { AdsDesktopHeaderComponent } from './ads/desktop_header/desktop-header.component';
import { AdsMobileheaderComponent } from './ads/mobile_header/mobile-header.component';
import { ParticipantInfoComponent } from './participant/info/info.component';
import { OptaWidgetActionAreasComponent } from './opta/widgets/action-areas/action-areas.component';
import { OptaWidgetAttackingThirdsComponent } from './opta/widgets/attacking-thirds/attacking-thirds.component';
import { OptaWidgetAveragePositionsComponent } from './opta/widgets/average-positions/average-positions.component';
import { OptaWidgetChalkboardComponent } from './opta/widgets/chalkboard/chalkboard.component';
import { OptaWidgetCommentaryComponent } from './opta/widgets/commentary/commentary.component';
import { OptaWidgetDefensiveZonesComponent } from './opta/widgets/defensive-zones/defensive-zones.component';
import { OptaWidgetGoalReplayComponent } from './opta/widgets/goal-replay/goal-replay.component';
import { OptaWidgetHeatmapComponent } from './opta/widgets/heatmap/heatmap.component';
import { OptaWidgetMatchStatsTeamGraphComponent }
    from './opta/widgets/match-stats-team-graph/match-stats-team-graph.component';
import { OptaWidgetMatchStatsTeamProGraphComponent }
    from './opta/widgets/match-stats-team-graph-pro/match-stats-team-graph-pro.component';
import { OptaWidgetMatchdayLiveComponent } from './opta/widgets/matchday-live/matchday-live.component';
import { OptaWidgetMatchPlayerCompareComponent }
    from './opta/widgets/match-player-compare/match-player-compare.component';
import { OptaWidgetCompetitionStatsComponent } from './opta/widgets/competition-stats/competition-stats.component';
import { OptaWidgetTeamRankingComponent } from './opta/widgets/team-ranking/team-ranking.component';
import { OptaWidgetSeasonTeamstatsComponent } from './opta/widgets/season-team-stats/season-team-stats.component';
import { OptaWidgetSeasonPlayerStatsComponent } from './opta/widgets/season-player-stats/season-player-stats.component';
import { OptaWidgetPlayerRatingsComponent } from './opta/widgets/player-ratings/player-ratings.component';
import { OptaWidgetPlayerCompareComponent } from './opta/widgets/player-compare/player-compare.component';
import { TvComComponent } from './stream/tvcom/tvcom.component';
import { CommentariesComponent } from './commentaries/commentaries.component';
import { SignInContainerComponent } from './user/signin-container/signin-container.component';
import { AuthContainerComponent } from './user/auth-container/auth-container.component';
import { CricketPlayerStatsComponent } from './cricket/player-stats/cricket-player-stats.component';
import { CricketTimelineComponent } from './match/timeline/cricket/cricket-timeline.component';
import { BannerDesktopHeaderComponent } from './banner/desktop-header/desktop-header.component';
import { CricketWicketsComponent } from './cricket/wickets/cricket-wickets.component';
import { OptaWidgetExpectedGoalsComponent } from './opta/widgets/expected-goals/expected-goals.component';
import { OptaWidgetExpectedGoalsSeasonComponent }
    from './opta/widgets/expected-goals-season/expected-goals-season.component';
import { OptaWidgetPenaltyTouchMapSeasonComponent } from './opta/widgets/penalty-touch-map/penalty-touch-map.component';
import { OptaWidgetMatchTeamsheetComponent } from './opta/widgets/match-teamsheet/match-teamsheet.component';
import { OptaWidgetPassMatrixComponent } from './opta/widgets/pass-matrix/pass-matrix.component';
import { OptaWidgetPenaltyHistoryComponent } from './opta/widgets/penalty-history/penalty-history.component';

@NgModule({
    declarations: [
        AdsComponent,
        AdsMatchDetailComponent,
        AdsMobileBodyComponent,
        AdsMobileLayerComponent,
        AdsUniversalComponent,
        AdsDesktopHeaderComponent,
        AdsMobileheaderComponent,
        NavigationComponent,

        FavoriteComponent,
        SportNavigationComponent,
        CalendarComponent,
        ScoreComponent,
        DefaultScoreComponent,
        DefaultScoreShortComponent,
        NavigationWidgetComponent,
        CategoryComponent,
        MatchComponent,
        MatchDetailComponent,
        TimeComponent,
        MatchActionsComponent,
        PlayersComponent,
        H2hComponent,
        // QAComponent,
        // TrackerComponent,
        LeagueTableComponent,
        LastMatchesComponent,
        SearchComponent,
        InfoWidgetComponent,
        AuthetificationDialogComponent,
        SigninComponent,
        RegistrationComponent,
        ForgottenPasswordComponent,
        SwitchComponent,
        TennisRankingComponent,
        FooterComponent,
        MapGoogleModalComponent,
        AddToScreenComponent,
        LoaderComponent,
        HeaderComponent,
        HeaderNavigationComponent,
        AlphaFilterComponent,
        PlaceholderDetailComponent,
        MatchDetailScoreComponent,
        SwitchersComponent,
        PlaceholderTableComponent,
        PlaceholderTournamentComponent,
        PlaceholderMatchComponent,
        CupTreeComponent,
        DetailComponent,
        ActualSportChartComponent,
        MyMatchesComponent,
        PushNotificationComponent,
        TennisTournamentInfoComponent,
        BannerComponent,
        CategoryGroupComponent,
        PlayerStatsComponent,
        BannerBlockComponent,
        BannerBottomFixedComponent,
        BannerMobileBodyComponent,
        SharedButtonsComponent,
        StageComponent,
        GolfComponent,
        GolfTableComponent,
        GolfHoleStatisticsComponent,
        GolfTeeTimesComponent,
        GolfParticipantTournamentComponent,
        GolfStandingsComponent,
        StageRightColComponent,
        LeagusTableTableComponent,
        ScrollDirective,
        StageStandingsComponent,
        StageSeasonsStatsComponent,
        StageParticipantComponent,
        BannerMobileLayerComponent,
        BannerMobileHeaderBodyComponent,
        ParticipantFormComponent,
        MatrixComponent,
        LightHeaderComponent,
        PlayerTransfersComponent,
        IntroComponent,
        GoalsScoredReceivedComponent,
        MatchActionIconsComponent,
        TwitchComponent,
        YoutubeComponent,
        NhlComponent,
        AlertComponent,
        LeagueTableContainerComponent,
        ISportComponent,
        VimeoComponent,
        TvComComponent,
        StageTableComponent,
        LineupsComponent,
        MatchBettingStatsComponent,
        MatchActionsScoreComponent,
        NewsComponent,

        ParticipantComparitionComponent,
        TennisTimelineComponent,
        BaseTimelineComponent,
        AdTimelineDirective,
        DefaultTimelineComponent,
        DartsTimelineComponent,
        TotalMatchStatsComponent,
        LineupHockeyFieldComponent,
        TournamentLinksComponent,
        ParticipanseasonstatsComponent,
        TennisBilanceComponent,
        GolfBalanceComponent,
        ParticipantStatsComponent,
        ParticipantStatsTotalsComponent,
        ParticipantStatsGoalsComponent,
        ParticipantStatsUOComponent,
        ParticipantStatsMatchResultComponent,
        ParticipantStatsScoredGoalsComponent,
        ParticipantStatsTimelineComponent,
        WeatherComponent,
        RankingComponent,
        ChannelComponent,
        SideMenuNavigationComponent,
        PagingComponent,
        AppModalComponent,
        PlayersV2Component,
        MicroModalComponent,
        LogoComponent,
        PlayerMatchStatsComponent,
        PeriodStatsComponent,
        ParticipantInfoComponent,
        OptaWidgetActionAreasComponent,
        OptaWidgetAttackingThirdsComponent,
        OptaWidgetAveragePositionsComponent,
        OptaWidgetChalkboardComponent,
        OptaWidgetCommentaryComponent,
        OptaWidgetDefensiveZonesComponent,
        OptaWidgetGoalReplayComponent,
        OptaWidgetHeatmapComponent,
        OptaWidgetMatchStatsTeamGraphComponent,
        OptaWidgetMatchStatsTeamProGraphComponent,
        OptaWidgetMatchdayLiveComponent,
        OptaWidgetMatchPlayerCompareComponent,
        OptaWidgetCompetitionStatsComponent,
        OptaWidgetTeamRankingComponent,
        OptaWidgetSeasonTeamstatsComponent,
        OptaWidgetSeasonPlayerStatsComponent,
        OptaWidgetPlayerRatingsComponent,
        OptaWidgetPlayerCompareComponent,
        CommentariesComponent,
        SocialSignInComponent,
        SignInContainerComponent,
        AuthContainerComponent,
        CricketPlayerStatsComponent,
        CricketTimelineComponent,
        BannerDesktopHeaderComponent,
        CricketWicketsComponent,
        OptaWidgetExpectedGoalsComponent,
        OptaWidgetExpectedGoalsSeasonComponent,
        OptaWidgetPenaltyTouchMapSeasonComponent,
        OptaWidgetMatchTeamsheetComponent,
        OptaWidgetPassMatrixComponent,
        OptaWidgetPenaltyHistoryComponent,
    ],
    imports: [SharedModule],
    exports: [
        AdsComponent,
        AdsMatchDetailComponent,
        AdsMobileBodyComponent,
        AdsMobileLayerComponent,
        AdsUniversalComponent,
        AdsDesktopHeaderComponent,
        AdsMobileheaderComponent,
        NavigationComponent,
        PlayerMatchStatsComponent,
        SigninComponent,
        RegistrationComponent,
        FavoriteComponent,
        SportNavigationComponent,
        CalendarComponent,
        ScoreComponent,
        H2hComponent,
        PeriodStatsComponent,
        DefaultScoreComponent,
        DefaultScoreShortComponent,
        NavigationWidgetComponent,
        CategoryComponent,
        MatchComponent,
        MatchDetailComponent,
        TimeComponent,
        MatchActionsComponent,
        PlayersComponent,
        LeagueTableComponent,
        LastMatchesComponent,
        SearchComponent,
        InfoWidgetComponent,
        ForgottenPasswordComponent,
        SwitchComponent,
        TennisRankingComponent,
        FooterComponent,
        AddToScreenComponent,
        LoaderComponent,
        HeaderComponent,
        HeaderNavigationComponent,
        AlphaFilterComponent,
        MatchDetailScoreComponent,
        BannerMobileHeaderBodyComponent,
        SwitchersComponent,
        PlaceholderTableComponent,
        PlaceholderTournamentComponent,
        PlaceholderMatchComponent,
        CupTreeComponent,

        ActualSportChartComponent,
        MyMatchesComponent,
        PushNotificationComponent,
        TennisTournamentInfoComponent,
        CategoryGroupComponent,
        BannerComponent,
        PlayerStatsComponent,
        BannerBlockComponent,
        BannerMobileBodyComponent,
        BannerBottomFixedComponent,
        SharedButtonsComponent,
        StageComponent,
        GolfComponent,
        GolfTableComponent,
        GolfHoleStatisticsComponent,
        GolfTeeTimesComponent,
        GolfParticipantTournamentComponent,
        GolfStandingsComponent,
        StageRightColComponent,
        StageParticipantComponent,
        StageSeasonsStatsComponent,
        StageStandingsComponent,
        BannerMobileLayerComponent,
        ParticipantFormComponent,
        LightHeaderComponent,
        PlayerTransfersComponent,
        IntroComponent,
        GoalsScoredReceivedComponent,
        AlertComponent,
        LeagueTableContainerComponent,
        ISportComponent,
        VimeoComponent,
        TvComComponent,
        NhlComponent,
        MatchBettingStatsComponent,
        MatchActionsScoreComponent,
        NewsComponent,

        ParticipantComparitionComponent,
        MapGoogleModalComponent,
        TennisTimelineComponent,
        BaseTimelineComponent,
        AdTimelineDirective,
        DefaultTimelineComponent,
        DartsTimelineComponent,
        TotalMatchStatsComponent,
        LineupHockeyFieldComponent,
        TournamentLinksComponent,
        ParticipanseasonstatsComponent,
        TennisBilanceComponent,
        GolfBalanceComponent,
        ParticipantStatsComponent,
        ParticipantStatsTotalsComponent,
        ParticipantStatsGoalsComponent,
        ParticipantStatsUOComponent,
        ParticipantStatsMatchResultComponent,
        ParticipantStatsScoredGoalsComponent,
        ParticipantStatsTimelineComponent,
        WeatherComponent,
        RankingComponent,
        ChannelComponent,
        SideMenuNavigationComponent,
        PagingComponent,
        AppModalComponent,
        PlayersV2Component,
        MicroModalComponent,
        LogoComponent,
        // QAComponent,
        YoutubeComponent,
        TwitchComponent,
        ParticipantInfoComponent,
        OptaWidgetActionAreasComponent,
        OptaWidgetAttackingThirdsComponent,
        OptaWidgetAveragePositionsComponent,
        OptaWidgetChalkboardComponent,
        OptaWidgetCommentaryComponent,
        OptaWidgetDefensiveZonesComponent,
        OptaWidgetGoalReplayComponent,
        OptaWidgetHeatmapComponent,
        OptaWidgetMatchStatsTeamGraphComponent,
        OptaWidgetMatchStatsTeamProGraphComponent,
        OptaWidgetMatchdayLiveComponent,
        OptaWidgetMatchPlayerCompareComponent,
        OptaWidgetCompetitionStatsComponent,
        OptaWidgetTeamRankingComponent,
        OptaWidgetSeasonTeamstatsComponent,
        OptaWidgetSeasonPlayerStatsComponent,
        OptaWidgetPlayerRatingsComponent,
        OptaWidgetPlayerCompareComponent,
        CommentariesComponent,
        SocialSignInComponent,
        SignInContainerComponent,
        AuthContainerComponent,
        CricketPlayerStatsComponent,
        CricketTimelineComponent,
        BannerDesktopHeaderComponent,
        CricketWicketsComponent,
        OptaWidgetExpectedGoalsComponent,
        OptaWidgetExpectedGoalsSeasonComponent,
        OptaWidgetPenaltyTouchMapSeasonComponent,
        OptaWidgetMatchTeamsheetComponent,
        OptaWidgetPassMatrixComponent,
        OptaWidgetPenaltyHistoryComponent,
    ],
    providers: [SoccerTime],
})
export class ComponentModule {}
